import "./evaluate.scss";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import $https from "../../../httpdata";
import { Image, Toast, Tabs, ImageViewer } from "antd-mobile";
class Evaluate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backIcon: require("../../common/img/back-active.png"),
      solidStar: require("../../common/img/solid-star.png"),
      hollowStar: require("../../common/img/hollow-star.png"),
      eye: require("./img/eye.png"),
      banner: require("./img/banner.png"),
      navList: [
        {
          type: 0,
          title: "商城订单",
        },
        {
          type: 1,
          title: "到店购订单",
        },
        {
          type: 2,
          title: "借阅订单",
        },
      ],
      activeType: 0,
      page: 1,
      page_size: 6,
      dataRes: [],
      loadingFlag: true,
      last_page: null,
      tabKey: "remain",
      alreadyArr: [],
      imgVisible: "",
      imgUrl: "",
    };
  }
  componentDidMount() {
    this.getRaminEvaluate(0);

    let that = this;
    window.onscroll = function () {
      var pageNum = that.state.page;
      const { last_page } = that.state;
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollTop + windowHeight >= scrollHeight) {
        if (!last_page) {
          that.setState({
            loadingFlag: true,
          });
          return;
        }
        if (pageNum < last_page) {
          console.log(pageNum, last_page);
          pageNum = pageNum + 1;

          that.setState(
            {
              page: pageNum,
              loadingFlag: true,
            },
            () => {
              const { activeType, tabKey } = that.state;

              if (tabKey == "remain") {
                that.getRaminEvaluate(activeType);
              } else {
                that.getAlready();
              }
            }
          );
        } else {
          that.setState({
            loadingFlag: false,
          });
        }

        //考虑到滚动的位置一般可能会大于一点可滚动的高度，所以这里不能用等于
      }
    };
    window.onReVisible = () => {
      that.setState(
        {
          dataRes: [],
          loadingFlag: true,
        },
        () => {
          that.getRaminEvaluate(0);
        }
      );

      // that.getProductDetail();
      // that.getStoreDetail();
      // that.getComments();
      // // that.getDefaultAddress();
      // that.getProductExtend();
      // that.getCart();
      // that.getDefaultAdd();
    };
  }
  componentWillUnmount() {}
  stars = (num) => {
    var newNum = num / 10;
    var intNum = Math.trunc(newNum);
    var littleNum = newNum - intNum;

    const { solidStar, hollowStar, halfStar } = this.state;
    var str = [];
    for (let i = 0; i < 5; i++) {
      if (i < intNum) {
        str.push(<img src={solidStar} alt="" />);
      } else if (i == intNum && littleNum > 0) {
        str.push(<img src={halfStar} alt="" />);
      } else {
        str.push(<img src={hollowStar} alt="" />);
      }
    }
    return <div className="stars"> {str} </div>;
  };

  goBack = () => {
    window.JXNative.postMessage(
      JSON.stringify({
        action: "closeWeb",
      })
    );
  };
  changeType = (type) => {
    const { loadingFlag } = this.state;
    if (!loadingFlag) {
      this.setState(
        {
          activeType: type,
          page: 1,
          loadingFlag: true,
          dataRes: [],
        },
        () => {
          this.getRaminEvaluate(type);
        }
      );
    } else {
      Toast.show("正在加载中，请稍后～");
    }
  };
  getShop = (type) => {
    const { page_size, page, loadingFlag } = this.state;
    // if (!loadingFlag) {
    $https
      .get("/api/v1/order/shop", {
        order_status: 4,
        express_type: type == 0 ? 2 : 1,
        page_size,
        page,
      })
      .then((res) => {
        const { dataRes } = this.state;
        var arr = dataRes;
        this.setState({
          last_page: res.data.last_page,
          dataRes: [...arr, ...res.data.data],
          loadingFlag: false,
        });
      });
    // }
  };
  getBorrow = () => {
    const { page_size, page, loadingFlag } = this.state;
    // if (!loadingFlag) {
    $https
      .get("/api/v1/order/borrow/search", {
        type: 3,
        page_size,
        page,
      })
      .then((res) => {
        const { dataRes } = this.state;
        var arr = dataRes;
        this.setState({
          last_page: res.data.last_page,
          dataRes: [...arr, ...res.data.data],
          loadingFlag: false,
        });
      });
    // }
  };
  getRaminEvaluate = (type) => {
    if (type == 0 || type == 1) {
      this.getShop(type);
    } else {
      this.getBorrow();
    }
    // this.setState({
    //   loadingFlag: true,
    // });
  };
  toComment = (item) => {
    const { activeType } = this.state;

    window.JXNative &&
      window.JXNative.postMessage &&
      window.JXNative.postMessage(
        JSON.stringify({
          action: "pushPage",
          page: "/orderEvaluatePage",
          orderSN: item.order_sn || item.summary.order_sn,
          productString: {
            titlePageUrl: item.products[0].product.title_page_url,
            name: item.products[0].product.name,
            productId: item.product_id || item.products[0].product.id,
            productType:
              activeType == 2 ? 0 : item.product_type ? item.product_type : 0,
            station_id:
              item && item.station && item.station.id
                ? item && item.station && item.station.id
                : item.borrow_station && item.borrow_station.id,
          },
        })
      );
  };
  getAlready = () => {
    const { page_size, page } = this.state;
    $https
      .get("/api/v1/user/comment/history", {
        page_size,
        page,
      })
      .then((res) => {
        const { alreadyArr } = this.state;
        var arr = alreadyArr;
        this.setState({
          last_page: res.data.last_page,
          alreadyArr: [...arr, ...res.data.data],
          loadingFlag: false,
        });
      });
  };
  getTabKey = (val) => {
    this.setState(
      {
        activeType: 0,
        page: 1,
        tabKey: val,
        loadingFlag: true,
        dataRes: [],
        alreadyArr: [],
      },
      () => {
        if (val === "remain") {
          this.getRaminEvaluate(0);
        } else {
          this.getAlready();
        }
      }
    );
  };
  setImgVisible = (val) => {
    this.setState({
      imgVisible: val,
    });
  };
  getImgVal = (val) => {
    this.setState(
      {
        imgUrl: val,
      },
      () => {
        this.setState({
          imgVisible: true,
        });
      }
    );
  };
  render() {
    const {
      backIcon,
      navList,
      activeType,
      loadingFlag,
      dataRes,
      alreadyArr,
      imgVisible,
      imgUrl,
      banner,
    } = this.state;
    return (
      <div className="evaluatePage">
        <header>
          <div className="head">
            <img src={backIcon} alt="" onClick={() => this.goBack()} />
            <span>我的评价</span>
            <em></em>
          </div>
        </header>
        <div className="banner">
          <Image fit="cover" src={banner} />
        </div>
        <Tabs className="classify" onChange={(key) => this.getTabKey(key)}>
          <Tabs.Tab title="待评价" key="remain">
            <div className="list">
              <div className="collect-box">
                <ul className="nav">
                  {navList.map((item, idx) => {
                    return (
                      <li
                        key={idx}
                        className={activeType == item.type ? "active" : null}
                        onClick={() => this.changeType(item.type)}
                      >
                        {item.title}
                      </li>
                    );
                  })}
                </ul>
                <ul className="cont">
                  {dataRes && dataRes.length
                    ? dataRes.map((item, index) => {
                        return (
                          <li className="good-item" key={index}>
                            <Image
                              src={item.products[0].product.title_page_url}
                            />
                            <div className="des">
                              <h1>{item.products[0].product.name}</h1>
                              <p>
                                <em>
                                  {(item.summary && item.summary.create_time) ||
                                    item.create_time}
                                </em>
                                <span onClick={() => this.toComment(item)}>
                                  评价
                                </span>
                              </p>
                            </div>
                          </li>
                        );
                      })
                    : null}
                  {/* {favoriteList.map((item, idx) => {
                    return (
                      <ProductItem
                        item={item}
                        key={idx}
                        collect={true}
                      ></ProductItem>
                    );
                  })} */}
                  {!loadingFlag ? (
                    <li className="loading">
                      <span>没有更多内容啦～</span>
                    </li>
                  ) : (
                    <li className="loading">
                      <span>正在加载中～</span>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </Tabs.Tab>

          <Tabs.Tab title="已评价" key="already">
            <ImageViewer
              image={imgUrl}
              visible={imgVisible}
              onClose={() => this.setImgVisible(false)}
            />
            <ul className="evaluated">
              {alreadyArr && alreadyArr.length
                ? alreadyArr.map((item, index) => {
                    return (
                      <li className="item" key={index}>
                        <p className="time">{item.create_time}</p>
                        {this.stars(item.stars)}

                        <div className="comment">
                          <div className="content">{item.content}</div>
                          <div
                            className={
                              item.images.length > 2 ? "many" : "images"
                            }
                          >
                            {item.images && item.images.length
                              ? item.images.map((itm, idx) => {
                                  return (
                                    <Image
                                      lazy
                                      src={itm}
                                      alt=""
                                      className="head-photo"
                                      fit="cover"
                                      key={idx}
                                      onClick={() => this.getImgVal(itm)}
                                    />
                                  );
                                })
                              : null}
                          </div>

                          <div className="product">
                            <Image
                              lazy
                              src={item.product.title_page_url}
                              alt=""
                              fit="cover"
                              // key={idx}
                            />
                            <p>{item.product.name}</p>
                          </div>
                          {/* <div className="view">
                            <Image
                              lazy
                              src={eye}
                              alt=""
                              // key={idx}
                            />
                            <span>36</span>
                            <em>评价已帮助了36名想买的用户～</em>
                          </div> */}
                          {/* <div
                    className={item.attachments.length > 2 ? "many" : "images"}
                  >
                    {item.attachments.length
                      ? item.attachments.map((itm, idx) => {
                          return (
                            <Image
                              lazy
                              src={itm.url}
                              alt=""
                              className="head-photo"
                              key={idx}
                            />
                          );
                        })
                      : null}
                  </div> */}
                        </div>
                      </li>
                    );
                  })
                : null}

              {!loadingFlag ? (
                <li className="loading">
                  <span>没有更多内容啦～</span>
                </li>
              ) : (
                <li className="loading">
                  <span>正在加载中～</span>
                </li>
              )}
            </ul>
          </Tabs.Tab>
        </Tabs>
      </div>
    );
  }
}
export default withRouter(Evaluate);
