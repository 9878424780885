import React, { Component } from "react";
import "./returnBook.scss";
import { withRouter } from "react-router-dom";
import { Image, Swiper, Toast, ImageViewer } from "antd-mobile";
import $https from "../../../httpdata";
import getParam from "../../../utils/getParam";
import Dialog from "../../../components/dialog/dialog";
class ReturnBook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backIcon: require("../../common/img/back-active.png"),
      titleIcon: require("./img/titleIcon.png"),
      borrowIcon: require("./img/borrowIcon.png"),
      defaultAvatar: require("./img/defaultAvatar.png"),
      infoRight: require("../../common/img/shop-info-right.png"),
      alreadyReturn: require("./img/alreadyReturn.png"),
      borrowing: require("./img/borrowing.png"),
      verified: null,
      order_sn: "",
      user_id: "",
      crossStore: require("./img/crossStore.png"),
      user: null,
      checkVisible: false,
      stationId: null,
      return_sn: null,
      product: null,
      borrow_card: null,
      imgVisible: false,
      imgUrl: "",
      imgsResult: [],
    };
  }
  componentDidMount() {
    let that = this;

    window.onCommonData = async function (res) {
      that.setState(
        {
          stationId:
            getParam(that.props.location.search, "station_id") || res.stationId,
          order_sn: getParam(that.props.location.search, "order_sn"),
          user_id: getParam(that.props.location.search, "user_id"),
          return_sn: getParam(that.props.location.search, "return_sn"),
        },
        () => {
          that.getDetail();
        }
      );
    };

    window.JXNative &&
      window.JXNative.postMessage &&
      window.JXNative.postMessage(
        JSON.stringify({
          action: "commonData",
        })
      );
  }
  getDetail() {
    const { order_sn, user_id, return_sn } = this.state;
    $https
      .get("/api/staff/v1/order/borrow/product", {
        order_sn,
        user_id,
        return_sn,
      })
      .then((res) => {
        console.log(res);
        this.setState(
          {
            verified:
              res.data.product.status == 2
                ? false
                : res.data.product.status == 1
                ? true
                : null,
            user: res.data.user,
            product: res.data.product,
            borrow_card: res.data.borrow_card,
          },
          () => {
            const { product } = this.state;
            if (
              product &&
              product.borrow_images.length &&
              product.borrow_images
            ) {
              var result = [];
              for (var i = 0; i < product.borrow_images.length; i += 3) {
                result.push(product.borrow_images.slice(i, i + 3));
              }
              this.setState({
                imgsResult: result,
              });
            }
          }
        );
      });
  }
  returnCheck = () => {
    const { order_sn, user_id, return_sn, stationId } = this.state;
    $https
      .post("/api/staff/v1/order/borrow/return", {
        order_sn,
        user_id,
        station_id: stationId,
        return_sn,
      })
      .then((res) => {
        this.getDetail();
        this.setCheckVisible(false);
        if (res.msg == "success") {
          Toast.show({
            content: "核验通过",
          });
        } else {
          Toast.show({
            content: res.msg,
          });
        }
      });
  };
  goBack = () => {
    window.JXNative.postMessage(
      JSON.stringify({
        action: "closeWeb",
      })
    );
  };
  setCheckVisible = (val) => {
    this.setState({
      checkVisible: val,
    });
  };
  toBookDetail = () => {
    const { product } = this.state;
    this.props.history.push({
      pathname: `/productDetail`,
      search: `goods_id=${product.product.id}&from=staff`,
    });
  };
  setImgVisible = (val) => {
    this.setState({
      imgVisible: val,
    });
  };
  getImgVal = (val) => {
    this.setState(
      {
        imgUrl: val,
      },
      () => {
        this.setState({
          imgVisible: true,
        });
      }
    );
  };
  render() {
    const {
      backIcon,
      titleIcon,
      borrowIcon,
      defaultAvatar,
      infoRight,
      alreadyReturn,
      verified,
      borrowing,
      crossStore,
      stationId,
      user,
      checkVisible,
      product,
      borrow_card,
      imgVisible,
      imgUrl,
      imgsResult,
    } = this.state;

    return (
      <div className="returnBook">
        <header>
          <Image src={backIcon} onClick={() => this.goBack()} />
          <span>借书详情</span>
          <span></span>
        </header>
        {verified ? (
          <div className="verified-info">
            <h1>
              <Image src={titleIcon} />
              <span>核验信息</span>
            </h1>
            <div className="cont">
              <Image src={borrowIcon} />
              <div className="info">
                <p>核验时间：{product && product.receive_time} </p>
                <p>借阅门店：{product && product.borrow_station.name}</p>
                <p>核验人员：{product && product.receive_staff}</p>
              </div>
            </div>
            {verified &&
            product &&
            product.borrow_station &&
            product.borrow_station.id !== stationId ? (
              <Image src={crossStore} className="crose" />
            ) : null}
          </div>
        ) : null}

        <div className="borrow-info">
          {!verified &&
          product &&
          product.borrow_station &&
          product.borrow_station.id !== stationId ? (
            <Image src={crossStore} className="crose" />
          ) : null}
          <h1>
            <Image src={titleIcon} />
            <span>借阅信息</span>
          </h1>
          <div className="cont">
            <div className="avatar">
              <Image src={user && user.avatar ? user.avatar : defaultAvatar} />
              {user?.mobile}
              <span>{borrow_card && borrow_card.name}</span>
            </div>
            <div className="card-info">
              <p>订单号：{product && product.order_sn}</p>
              <p>
                {verified ? "归还时间" : "到期时间"}：
                {verified
                  ? product && product.receive_time
                  : product && product.expire_time}
              </p>
              <p>借阅时间：{product && product.create_time}</p>
              <p>
                借阅门店：
                <em className="red">
                  {product && product.borrow_station.name}
                </em>
              </p>
            </div>

            {verified ? (
              <ul className="list">
                <li>
                  <p>
                    超期天数：
                    <span>{product && product.overdue_days}天</span>
                  </p>
                </li>
                <li>
                  <p>
                    超期费用：
                    <span>¥ {product && product.overdue_fee}</span>
                  </p>
                </li>
                <li>
                  <p>
                    已付超期费用：
                    <span className="red">
                      ¥{product && product.has_paid_fee}
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    支付时间：
                    <span>{product && product.pay_time}</span>
                  </p>
                </li>
              </ul>
            ) : (
              <ul className="list">
                <li>
                  <p>
                    超期天数：
                    <span className="red">
                      {product && product.overdue_days}天
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    超期费用：
                    <span>¥ {product && product.overdue_fee}</span>
                  </p>
                </li>
                <li>
                  <p>
                    已付超期费用：
                    <span>¥{product && product.has_paid_fee}</span>
                  </p>
                </li>
                <li>
                  <p>
                    未支付超期费用：
                    <span className="red">
                      ¥{product && product.not_paid_fee}
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    累计借阅：
                    <span>
                      <span>
                        {borrow_card?.used_num + borrow_card?.return_num}
                      </span>
                      本
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    借阅中：
                    <span>{borrow_card && borrow_card.used_num}本</span>
                  </p>
                </li>
                <li>
                  <p>
                    累计超期：
                    <span className="red">
                      {borrow_card && borrow_card.overdue_times}次
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    累计归还：
                    <span>{borrow_card && borrow_card.return_num}本</span>
                  </p>
                </li>
              </ul>
            )}
          </div>
        </div>
        <div className="book-info">
          <h1>
            <Image src={titleIcon} />
            <span>书籍信息</span>
          </h1>
          <div className="cont">
            <div className="pic">
              <Image
                src={
                  product && product.product && product.product.title_page_url
                }
                onClick={() => this.setImgShowVisible(true)}
                fit="cover"
              />
            </div>

            <div className="book" onClick={() => this.toBookDetail()}>
              <h1 className="title">
                <span>
                  {product && product.product && product.product.name}
                </span>

                <Image src={verified ? alreadyReturn : borrowing} />
              </h1>
              <p className="price">
                定价：¥
                {product && product.product && product.product.output_price}
              </p>
              <div className="book-infos">
                <div className="detail">
                  <div className="p">
                    <span>
                      作者：
                      {product && product.product && product.product.writer}
                    </span>
                  </div>
                  <p>
                    出版社：
                    {product && product.product && product.product.pub_name}
                  </p>
                  <p>
                    ISBN：{product && product.product && product.product.isbn}
                  </p>
                  <p>
                    出版时间：
                    {product && product.product && product.product.publish_date}
                  </p>
                </div>
                <Image src={infoRight} className="todetail" />
              </div>
            </div>
          </div>
        </div>
        <ImageViewer
          image={imgUrl}
          visible={imgVisible}
          onClose={() => this.setImgVisible(false)}
        />

        {product &&
        product.borrow_images &&
        product.borrow_images.length >= 1 ? (
          <div className="book-pics">
            <h1>
              <Image src={titleIcon} />
              <span>
                书籍照片（
                {product &&
                  product.borrow_images &&
                  product.borrow_images.length}
                张）
              </span>
            </h1>
            <Swiper
              slideSize={215}
              trackOffset={0}
              stuckAtBoundary={false}
              style={{
                "--track-padding": " 0 0 40px",
              }}
            >
              {imgsResult.length &&
                imgsResult.map((item, index) => {
                  return (
                    <Swiper.Item key={index}>
                      {item.map((itm, idx) => {
                        return (
                          <Image
                            key={idx}
                            src={itm}
                            onClick={() => this.getImgVal(itm)}
                            fit="cover"
                          />
                        );
                      })}
                    </Swiper.Item>
                  );
                })}
            </Swiper>
          </div>
        ) : null}

        <div className="btn">
          {verified ? (
            <span onClick={() => this.goBack()}>下一本</span>
          ) : (
            <div className="unverifiedBtn">
              <span className="cancel" onClick={() => this.goBack()}>
                取消核验
              </span>
              <span onClick={() => this.setCheckVisible()}>还书核验通过</span>
            </div>
          )}
        </div>
        <Dialog
          visible={checkVisible}
          sure={this.returnCheck}
          setCheckVisible={this.setCheckVisible}
        ></Dialog>
      </div>
    );
  }
}

export default withRouter(ReturnBook);
