import "./storeCommentDetail.scss";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import getParam from "../../utils/getParam";
import parseQueryString from "../../utils/parseQueryString";
import $https from "../../httpdata";
import { Image, ImageViewer } from "antd-mobile";
class StoreCommentDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backIcon: require("../common/img/back-active.png"),
      loading: require("./img/loading.png"),
      //   searchIcon: require("../storeDetail/img/search.png"),
      //   trumpet: require("../storeDetail/img/trumpet.png"),
      //   informRight: require("../storeDetail/img/inform-right.png"),
      solidStar: require("../common/img/solid-star.png"),
      hollowStar: require("../common/img/hollow-star.png"),
      halfStar: require("../common/img/half-star.png"),
      comments: {},
      navList: [
        {
          type: 0,
          title: "全部",
          key: "total",
        },
        {
          type: 1,
          title: "好评",
          key: "good",
        },
        {
          type: 2,
          title: "中评",
          key: "middle",
        },
        {
          type: 3,
          title: "差评",
          key: "bad",
        },
      ],
      activeType: 0,
      page_size: 6,
      page: 1,
      stat: {},
      commentList: [],
      last_page: null,
      loadingFlag: true,
      requireUrl: "",
      hiddenBtn: false,
      imgVisible: false,
      imgUrl: "",
      //   collectActive: require("../storeDetail/img/collect-active.png"),
      //   collect: require("../storeDetail/img/collect.png"),
      //   openTime: require("../storeDetail/img/time.png"),
      //   telIcon: require("../storeDetail/img/tel.png"),
      //   shopIcon: require("../storeDetail/img/shop.png"),
      //   location: require("../storeDetail/img/location.png"),
      //   shopInfoRight: require("../storeDetail/img/shop-info-right.png"),
      //   shopInfoDown: require("../storeDetail/img/shop-info-down.png"),
      //   shopInfoUp: require("../storeDetail/img/shop-info-up.png"),
      //   showAll: false, //书店简介展开
      //   activeClass: "search",
    };
  }
  componentDidMount() {
    let that = this;
    this.setState(
      {
        hiddenBtn: getParam(this.props.location.search, "from") ? true : false,
      },
      () => {
        this.getComments();
      }
    );

    window.onscroll = function () {
      var pageNum = that.state.page;
      const { last_page } = that.state;
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollTop + windowHeight >= scrollHeight) {
        if (!last_page) {
          that.setState({
            loadingFlag: true,
          });
          return;
        }
        if (pageNum < last_page) {
          pageNum = pageNum + 1;

          that.setState(
            {
              page: pageNum,
              loadingFlag: true,
            },
            () => {
              that.getComments();
            }
          );
        } else {
          that.setState({
            loadingFlag: false,
          });
        }

        //考虑到滚动的位置一般可能会大于一点可滚动的高度，所以这里不能用等于
      }
    };
  }
  componentWillUnmount() {
    this.setState({
      loadingFlag: true,
    });
    window.onscroll = null;
  }
  // stars = () => {
  //   const { solidStar, hollowStar } = this.state;
  //   return (
  //     <div className="stars">
  //       <img src={solidStar} alt="" />
  //       <img src={solidStar} alt="" />
  //       <img src={solidStar} alt="" />
  //       <img src={solidStar} alt="" />
  //       <img src={hollowStar} alt="" />
  //     </div>
  //   );
  // };
  stars = (num) => {
    var newNum = num / 10;
    var intNum = Math.trunc(newNum);
    var littleNum = newNum - intNum;

    const { solidStar, hollowStar, halfStar } = this.state;
    var str = [];
    for (let i = 0; i < 5; i++) {
      if (i < intNum) {
        str.push(<img src={solidStar} alt="" />);
      } else if (i === intNum && littleNum > 0) {
        str.push(<img src={halfStar} alt="" />);
      } else {
        str.push(<img src={hollowStar} alt="" />);
      }
    }
    return <div className="stars"> {str} </div>;
  };
  getComments = () => {
    const { page_size, page, activeType, hiddenBtn } = this.state;
    var paramObj = parseQueryString(window.location.search);
    var keys = Object.keys(paramObj);
    var param = "",
      url = "";
    var key = keys[0];
    if (key === "station_id") {
      param = getParam(this.props.location.search, "station_id");

      url = `/api/v1/station/comments`;
    } else {
      param = getParam(this.props.location.search, "goods_id");
      url = `/api${hiddenBtn ? "/staff" : ""}/v1/goods/comments`;
    }
    $https
      .get(url, {
        [key]: param,
        page_size: page_size,
        type: activeType,
        page,
      })
      .then((res) => {
        var arr = this.state.commentList ? this.state.commentList : [];
        var newArr = [...arr, ...res.data.list.data];
        this.setState({
          last_page: res.data.list.last_page,
          stat: res.data.stat,
          commentList: newArr,
          loadingFlag: false,
        });
      });
  };
  changeType = (type) => {
    this.setState(
      {
        activeType: type,
        page: 1,
        commentList: [],
        loadingFlag: true,
      },
      () => {
        this.getComments();
      }
    );
  };
  goBack = () => {
    this.props.history.goBack();
  };
  setImgVisible = (val) => {
    this.setState({
      imgVisible: val,
    });
  };
  getImgVal = (val) => {
    this.setState(
      {
        imgUrl: val,
      },
      () => {
        this.setState({
          imgVisible: true,
        });
      }
    );
  };
  render() {
    const {
      backIcon,
      loading,
      commentList,
      stat,
      navList,
      activeType,
      loadingFlag,
      imgVisible,
      imgUrl,
    } = this.state;
    return (
      <div className="store-comment-detail">
        <header>
          <div className="head">
            <img src={backIcon} alt="" onClick={() => this.goBack()} />
            <span>评价</span>
            <em></em>
          </div>
          <div className="choice">
            {navList.map((item, idx) => {
              return (
                <span
                  key={idx}
                  className={activeType == item.type ? "active" : null}
                  onClick={() => this.changeType(item.type)}
                >
                  <b> {item.title}</b>

                  <em>
                    {stat[item.key] >= 10000
                      ? `${parseInt(stat[item.key] / 10000)}万+`
                      : stat[item.key]}
                  </em>
                </span>
              );
            })}
          </div>
        </header>
        <div className="comment-box">
          {/* <div className="title">
            <span className="title-left">
              评价
              <em>(1.4万+评价)</em>
            </span>
            <em className="content-detail">
              好评率93%
              <img src={shopInfoRight} alt="" className="arrow-right" />
            </em>
          </div> */}
          <ImageViewer
            image={imgUrl}
            visible={imgVisible}
            onClose={() => this.setImgVisible(false)}
          />
          {/* <Image
            lazy
            src="https://t7.baidu.com/it/u=1819248061,230866778&fm=193&f=GIF"
            alt=""
            className="head-photo"
            fit="cover"
            onClick={() =>
              this.getImgVal(
                "https://t7.baidu.com/it/u=1819248061,230866778&fm=193&f=GIF"
              )
            }
          /> */}
          <div className="comments-content">
            {commentList && commentList.length
              ? commentList.map((item) => {
                  return (
                    <div key={item.id}>
                      <div className="head">
                        <Image
                          src={
                            item.avatar ||
                            "https://jx-storage.oss-cn-beijing.aliyuncs.com/ae6fffd2e9f4a96bed9c139c12ba5dff.png"
                          }
                          alt=""
                          fit="cover"
                          className="head-photo"
                        />
                        <div className="nickname">
                          <span>{item.nickname}</span>
                          {this.stars(item.score)}
                        </div>
                      </div>
                      <div className="content">{item.content}</div>
                      <div
                        className={
                          item &&
                          item.attachments &&
                          item.attachments.length > 2
                            ? "many"
                            : "images"
                        }
                      >
                        {item.attachments && item.attachments.length
                          ? item.attachments.map((itm, idx) => {
                              return (
                                <Image
                                  lazy
                                  src={itm.url}
                                  alt=""
                                  className="head-photo"
                                  key={idx}
                                  onClick={() => this.getImgVal(itm.url)}
                                  fit="cover"
                                />
                              );
                            })
                          : null}
                      </div>
                      <p>{item.createdAt || item.created_at}</p>
                    </div>
                  );
                })
              : null}
            {/* <div>
              <div className="head">
                <img src={solidStar} alt="" className="head-photo" />
                <div className="nickname">
                  <span>糟糕的妈妈</span>
                  {this.stars()}
                </div>
              </div>
              <div className="content">
                书店离公司比较近，自习室必须赞一个，Wi-Fi、
                咖啡都有，非常适合一个人安静的写字案。闲暇之
                余还可以看看书，切换一下思路，不要太方便！
              </div>
              <div className="images many">
                <img src={solidStar} alt="" className="head-photo" />
                <img src={solidStar} alt="" className="head-photo" />
                <img src={solidStar} alt="" className="head-photo" />
              </div>
              <p>2022/02/04 10:09:08</p>
            </div>
            <div>
              <div className="head">
                <img src={solidStar} alt="" className="head-photo" />
                <div className="nickname">
                  <span>糟糕的妈妈</span>
                  {this.stars()}
                </div>
              </div>
              <div className="content">
                书店离公司比较近，自习室必须赞一个，Wi-Fi、
                咖啡都有，非常适合一个人安静的写字案。闲暇之
                余还可以看看书，切换一下思路，不要太方便！
              </div>
              <p>2022/02/04 10:09:08</p>
            </div> */}

            {loadingFlag ? (
              <div className="loading">
                <img src={loading} alt="" />
                <span>加载中...</span>
              </div>
            ) : (
              <div className="loading">
                <span>没有更多数据...</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(StoreCommentDetail);
