import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Toast } from "antd-mobile";
import $https from "../../../httpdata";
import "./index.scss";

class Wallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backIcon: require("../../common/img/back-active.png"),
      walletInfo: {},
      selectIndex: 1,
    };
  }

  componentDidMount() {
    this.getWallet();
  }

  getWallet = () => {
    $https
      .get("/api/v1/wallet")
      .then((res) => {
        if (res && res.data) {
          this.setState({
            walletInfo: res.data,
          });
        } else {
          Toast.show((res && res.msg) || "数据异常！");
        }
      });
  }

  goToPage = (url) => {
    const { walletInfo } = this.state;
    const { location } = this.props;
    let _search = location.search;
    if (url === "/wallet/password") {
      _search += `${_search ? "&" : "?"}has_pay_password=${walletInfo.has_pay_password ? 1 : 0}`;
    }
    this.props.history.push({
      pathname: url,
      search: _search,
    });
  }

  handleRecharge = () => {
    this.setState({
      showRechargeModal: !this.state.showRechargeModal
    });
  }

  handleRechargeSelect = (selectIndex) => {
    this.setState({
      selectIndex
    });
  }

  goRecharge = () => {
    const { selectIndex } = this.state;
    this.goToPage(`/wallet/recharge/${selectIndex}`);
  }

  goBack = () => {
    window.JXNative.postMessage(
      JSON.stringify({
        action: "closeWeb",
      })
    );
  }

  render() {
    const { backIcon, walletInfo, showRechargeModal, selectIndex } = this.state;

    return (
      <div className="mine-collect mine-wallet">
        <header>
          <div className="head">
            <img src={backIcon} alt="" onClick={() => this.goBack()} />
            <span>我的钱包</span>
            <em></em>
          </div>
        </header>
        <div className="wallet-info">
          <p className="wallet-icon"></p>
          <p className="wallet-name">我的零钱</p>
          <p className="wallet-vender">{walletInfo.vender || ""}&nbsp;</p>
          <p className="wallet-balance">￥<strong>{walletInfo.balance || "0.00"}</strong></p>
          <div className="wallet-info-botton">
            <span onClick={() => this.goToPage("/wallet/code")}>付款</span>
            <span className="green" onClick={() => { this.handleRecharge() }}>充值</span>
          </div>
        </div>
        <ul className="wallet-nav">
          <li onClick={() => this.goToPage("/wallet/details")}><span className="wallet-details">钱包明细</span><span className="arrow"></span></li>
          <li onClick={() => this.goToPage("/wallet/password")}><span className="wallet-password">{walletInfo.has_pay_password ? "修改" : "设置"}支付密码</span><span className="arrow"></span></li>
        </ul>

        {showRechargeModal ?
          <div className="recharge-list">
            <div className="recharge-title"><span className="recharge-close" onClick={() => { this.handleRecharge() }}></span>选择付款方式</div>
            <ul>
              <li className="recharge-pay1" onClick={() => { this.handleRechargeSelect(1) }}>
                <p>在线充值</p>
                <p className={`recharge-item-select ${selectIndex === 1 ? "select-ed" : ""}`}></p>
              </li>
              <li className="recharge-pay2" onClick={() => { this.handleRechargeSelect(2) }}>
                <p>充值卡</p>
                <p className={`recharge-item-select ${selectIndex === 2 ? "select-ed" : ""}`}></p>
              </li>
              <li className="recharge-button" onClick={() => { this.goRecharge() }}>确定</li>
            </ul>
          </div>
          : null}

      </div>
    );
  }
}
export default withRouter(Wallet);
