import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Toast } from "antd-mobile";
import JsBarcode from "jsbarcode";
import QRCode from "qrcode.react";
import $https from "../../../httpdata";
import getParam from "../../../utils/getParam";
import "./index.scss";

class WalletDetails extends Component {
  constructor(props) {
    super(props);
    const { location } = props;
    this.needClose = getParam(location.search, "needClose");
    this.intervalTime = null;
    this.state = {
      backIcon: require("../../common/img/back.png"),
      pay: {},
      showTime: 58,
    };
  }

  componentDidMount() {
    this.getWalletCode();
  }

  componentWillUnmount() {
    clearInterval(this.intervalTime);
  }

  getWalletCode = () => {
    $https
      .get("/api/v1/wallet/pay/code")
      .then((res) => {
        if (res && res.data) {
          if (res.data.pay && res.data.pay.pay_code) {
            this.setState({
              pay: res.data.pay,
              showTime: (res.data.pay.expire_seconds || 60) - 2
            }, () => {
              JsBarcode("#payCode", this.state.pay.pay_code);
              this.intervalTimeFn();
            });
          }
          if (res.data.mobile) {
            JsBarcode("#mobileCode", res.data.mobile);
          }
        } else {
          Toast.show((res && res.msg) || "数据异常！");
        }
      });
  }

  intervalTimeFn = () => {
    this.intervalTime = setInterval(() => {
      this.setState({
        showTime: this.state.showTime - 1
      }, () => {
        if (this.state.showTime <= 0) {
          this.getWalletCode();
          clearInterval(this.intervalTime);
        }
      })
    }, 1000);
  }

  goBack = () => {
    if (this.needClose) {
      window.JXNative.postMessage(
        JSON.stringify({
          action: "closeWeb",
        })
      );
    } else {
      this.props.history.go(-1);
    }
  }

  render() {
    const { backIcon, pay, showTime } = this.state;

    return (
      <div className="mine-collect mine-wallet-details">
        <header>
          <div className="head">
            <img src={backIcon} alt="" onClick={() => this.goBack()} />
            <span>我的钱包</span>
            <em></em>
          </div>
        </header>
        <div className="wallet-code">
          <div className="wallet-code-top">
            <div className="wallet-code-title">向商家付款</div>
            <p><svg id="payCode"></svg></p>
            <p className="wallet-code-qrcode"><QRCode value={pay.pay_code} style={{ width: "4.5rem", height: "4.5rem" }} /></p>
            <p className="wallet-code-time">{showTime}秒过期</p>
          </div>
          <div className="wallet-code-bottom">
            <div className="wallet-code-title">会员卡号</div>
            <p><svg id="mobileCode"></svg></p>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(WalletDetails);
