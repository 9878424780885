import "./order.scss";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Image, Swiper, Toast, SpinLoading } from "antd-mobile";
import $https from "../../httpdata";
import getParam from "../../utils/getParam";
class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backIcon: require("../common/img/back-active.png"),
      addBtn: require("./img/addBtn.png"),
      cartIcon: require("../productDetail/img/mall.png"),
      okIcon: require("./img/ok.png"),
      openImg: require("./img/open.png"),
      seatUrl: "",
      station_id: null,
      categories: [],
      activeKey: "", //类别
      foodLists: [],
      seatInfo: {},
      summary: null,
      parme_station_id: null,
      cartProdurcts: [],
    };
  }
  componentDidMount() {
    let that = this;
    var str = getParam(this.props.location.search, "seatUrl");

    this.setState(
      {
        seatUrl: str,
        parme_station_id: getParam(str, "StationID"),
      },
      () => {
        this.getSeat();
      }
    );
    window.onAddToCart = async function (res) {
      that.setState(
        { summary: res.data.summary, cartProdurcts: res.data.products },
        () => {
          that.getFoodList();
        }
      );
      Toast.show("已加入");
    };
    window.onReVisible = () => {
      that.setState(
        {
          seatUrl: str,
          parme_station_id: getParam(str, "StationID"),
        },
        () => {
          that.getSeat();
        }
      );
    };
  }

  getSeat = () => {
    const { parme_station_id, seatUrl } = this.state;
    let that = this;
    $https
      .get("/api/v1/food/seat", {
        station_id: parme_station_id,
        seat_id: seatUrl,
      })
      .then((res) => {
        if (res.data) {
          this.setState(
            {
              seatInfo: res.data && res.data.seat,
              storeDetail: res.data && res.data.station,
            },
            () => {
              this.getCategories();
              this.getProductCartList();
            }
          );
        } else {
          Toast.show({
            content: res.msg,
            duration: 2000,
            afterClose: () => that.goBack(),
          });
        }
      });
  };
  getCategories = () => {
    const { storeDetail } = this.state;
    $https
      .get("/api/v1/food/categories", {
        station_id: storeDetail.id,
      })
      .then((res) => {
        this.setState(
          {
            categories: res.data.data,
            activeKey: res.data.data.length ? res.data.data[0].id : null,
            activeName: res.data.data.length ? res.data.data[0].name : null,
          },
          () => {
            this.getFoodList();
          }
        );
      });
  };
  getFoodList = () => {
    const { storeDetail, activeKey } = this.state;

    $https
      .get("/api/v1/food/search", {
        station_id: storeDetail.id,
        category_id: activeKey,
      })
      .then((res) => {
        this.setState({
          foodLists: res.data && res.data.products.data,
          summary: res.data && res.data.summary,
        });
      });
  };

  bannerSwiper = (vals) => {
    var nodes = vals.map((item, index) => {
      return (
        <Swiper.Item key={index}>
          <div
            className="swiper-content"
            style={{
              backgroundImage: `url(${
                item.url
                  ? item.url
                  : "https://jx-storage.oss-cn-beijing.aliyuncs.com/ae6fffd2e9f4a96bed9c139c12ba5dff.png"
              })`,
            }}
          ></div>
        </Swiper.Item>
      );
    });
    return nodes;
  };
  changeList = (item) => {
    this.setState(
      {
        activeKey: item.id,
        activeName: item.name,
      },
      () => {
        this.setState({
          foodLists: [],
        });
        this.getFoodList();
      }
    );
  };
  addCart = (item) => {
    const { seatInfo, storeDetail } = this.state;
    window.JXNative &&
      window.JXNative.postMessage(
        JSON.stringify({
          action: "addToCart",
          productId: item.product_id,
          selfPickUp: false,
          addressId: 0,
          station: storeDetail,
          activityId: storeDetail && storeDetail.activity_id,
          type: "food",
          desk: `${seatInfo.type}-${seatInfo.name}`,
        })
      );
  };
  goBack = () => {
    var navBar = getParam(this.props.location.search, "navBar");
    if (navBar !== null) {
      window.JXNative.postMessage(
        JSON.stringify({
          action: "closeWeb",
        })
      );
    } else {
      this.props.history.goBack();
    }
  };
  toOrderConfrim = () => {
    const { cartProdurcts, storeDetail } = this.state;
    if (cartProdurcts.length) {
      window.JXNative &&
        window.JXNative.postMessage &&
        window.JXNative.postMessage(
          JSON.stringify({
            action: "pushPage",
            page: "/orderConfirm",
            goodsOrderParams: {
              self_pick_up: false,
              address_id: 0,
              station_id: storeDetail.id,
              bucket: "food",
              products: cartProdurcts,
              not_use_coupon: false,
            },
          })
        );
    } else {
      Toast.show("请点餐");
    }
  };
  toCartPage = () => {
    const { storeDetail } = this.state;
    window.JXNative &&
      window.JXNative.postMessage &&
      window.JXNative.postMessage(
        JSON.stringify({
          action: "pushPage",
          page: "/cart",
          stationId: storeDetail.id,
          type: "food",
        })
      );
  };
  getProductCartList = () => {
    const { storeDetail } = this.state;
    $https
      .get("/api/v1/cart/product", {
        station_id: storeDetail.id,
        bucket: "food",
      })
      .then((res) => {
        this.setState({ cartProdurcts: res.data.products });
      });
  };
  render() {
    const {
      backIcon,
      addBtn,
      cartIcon,
      categories,
      activeKey,
      foodLists,
      activeName,
      openImg,
      storeDetail,
      seatInfo,
      summary,
    } = this.state;

    return (
      <div className="order">
        <div className="header">
          <div className="head">
            <img src={backIcon} alt="" onClick={() => this.goBack()} />
            <span>句象点餐</span>
            <em></em>
          </div>
          {storeDetail &&
          storeDetail.victuals &&
          storeDetail.victuals.length ? (
            <Swiper autoplay loop autoplayInterval="2000">
              {this.bannerSwiper(storeDetail.victuals)}
            </Swiper>
          ) : null}
        </div>

        <div className="content">
          <div className="openTime">
            <Image src={openImg} />
            <div className="timeInfo">
              <p>
                <span>桌号：</span>
                {seatInfo?.type}-{seatInfo?.name}
              </p>
              <p>
                <span>营业时间：</span>
                <span>{storeDetail?.opening_time}</span>
              </p>
            </div>
          </div>
          <div className="menu">
            <div className="left">
              <ul>
                {categories && categories.length
                  ? categories.map((item) => {
                      return (
                        <li
                          key={item.id}
                          className={activeKey === item.id ? "active" : null}
                          onClick={() => this.changeList(item)}
                        >
                          {item.name}
                        </li>
                      );
                    })
                  : null}
              </ul>
            </div>
            <div className="right">
              <h1>{activeName}</h1>
              {}
              <div className="list">
                {foodLists && foodLists.length ? (
                  foodLists.map((item) => {
                    return (
                      <div className="list-item" key={item.product_id}>
                        <Image
                          src={item.product.title_page_url}
                          lazy
                          fit="cover"
                        />
                        <div className="shop-info">
                          <div>
                            <h1 className="title">{item.product.name}</h1>
                            <p className="salesVolume">
                              销量：{item.product.sales_amount}
                            </p>
                          </div>

                          <div className="count">
                            <div className="price">
                              <span className="now-price">
                                <em>¥</em>
                                {item.product.discount_price}
                              </span>
                              <span className="original">
                                ¥{item.product.price}
                              </span>
                            </div>
                            {item.product.stock_amount > 0 ? (
                              <div
                                className="addBtn"
                                onClick={() => this.addCart(item)}
                              >
                                <Image src={addBtn} />
                                {item.product_num > 0 ? (
                                  <span className="num">
                                    {item.product_num}
                                  </span>
                                ) : null}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <span className="loading">
                    <SpinLoading color="#65814d" style={{ "--size": "50px" }} />
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="cart">
          <div className="cart-info">
            <div className="cart-count" onClick={() => this.toCartPage()}>
              <Image src={cartIcon} />
              {summary && summary.check_amount ? (
                <div className="num">{summary?.check_amount}</div>
              ) : null}
            </div>
            <div className="info">
              <p className="price">
                <em>¥</em>
                {summary?.final_money}
              </p>
              <p className="price-des">
                优惠金额<span>¥{summary?.reduce_money}</span>
              </p>
            </div>
          </div>
          <div className="choose" onClick={() => this.toOrderConfrim()}>
            选好了
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Order);
