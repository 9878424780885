import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { Mask } from "antd-mobile";
import "./dialog.scss";
class Dialog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  sure = () => {
    this.props.sure();
  };
  render() {
    const { visible, setCheckVisible, title, cont } = this.props;
    var title1 = title || "确认核验";
    var cont1 = cont || "请仔细核验，确认要提交吗？";
    return (
      <Mask
        visible={visible}
        onMaskClick={() => setCheckVisible(false)}
        className="dd-dialog"
      >
        <div className="common-dialog">
          <h1>{title1}</h1>
          <p>{cont1}</p>
          <div className="btns">
            <span onClick={() => setCheckVisible(false)}>取消</span>
            <span onClick={() => this.sure()}>确认</span>
          </div>
        </div>
      </Mask>
    );
  }
}
export default withRouter(Dialog);
