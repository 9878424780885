import "./index.scss";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class Intr extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
  }
  render() {
    return (
      <div className="index">
        <div className="warp">
          <img src="https://jx-storage.oss-cn-beijing.aliyuncs.com/29b388d3791a1ed8ca5274bc0e60acec.png" />
          {/* <div className="nav"><strong>首页</strong><a>下载中心</a></div> */}
        </div>
        <div className="bottom">
          <div className="middle-inner">
            <p><a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2023000019号-1</a></p>
            <p><span>&copy;&nbsp;北京句象文化有限公司</span><span>地址：北京市朝阳区酒仙桥将台路14号34</span><span>电话：010-64278899</span></p>
            <p><a href="https://www.juxiangbook.com/policy/userProtocal.html" target="_blank">用户协议</a><span>|</span><a href="https://www.juxiangbook.com/policy/privacy.html" target="_blank">隐私协议</a></p>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Intr);
