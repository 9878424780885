import "./browsingHistory.scss";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import $https from "../../../httpdata";
import ProductItem from "../../../components/ProductItem/ProductItem";
class BrowsingHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backIcon: require("../../common/img/back-active.png"),
      activeType: 0,
      page: 1,
      page_size: 6,
      favoriteList: [],
      loadingFlag: false,
      last_page: null,
      balance: 0,
    };
  }
  componentDidMount() {
    this.searchFavotites();
    let that = this;
    window.onscroll = function () {
      var pageNum = that.state.page;
      const { last_page } = that.state;
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollTop + windowHeight >= scrollHeight) {
        if (!last_page) {
          that.setState({
            loadingFlag: true,
          });
          return;
        }
        if (pageNum < last_page) {
          pageNum = pageNum + 1;

          that.setState(
            {
              page: pageNum,
              loadingFlag: true,
            },
            () => {
              that.searchFavotites();
            }
          );
        } else {
          that.setState({
            loadingFlag: false,
          });
        }

        //考虑到滚动的位置一般可能会大于一点可滚动的高度，所以这里不能用等于
      }
    };
  }
  componentWillUnmount() {}
  searchFavotites = () => {
    const { page_size, page } = this.state;
    $https
      .get("/api/v1/user/browse/history", {
        page_size: page_size,
        page,
      })
      .then((res) => {
        var arr = this.state.favoriteList ? this.state.favoriteList : [];
        var newArr = [...arr, ...res.data.data];
        this.setState({
          last_page: res.data.last_page,
          favoriteList: newArr,
          loadingFlag: false,
        });
      });
  };

  goBack = () => {
    window.JXNative.postMessage(
      JSON.stringify({
        action: "closeWeb",
      })
    );
  };
  render() {
    const {
      backIcon,

      favoriteList,
      loadingFlag,
    } = this.state;
    return (
      <div className="browsing-history">
        <header>
          <div className="head">
            <img src={backIcon} alt="" onClick={() => this.goBack()} />
            <span>浏览记录</span>
            <em></em>
          </div>
        </header>
        <div className="list">
          <div className="collect-box">
            <ul>
              {favoriteList.map((item, idx) => {
                return (
                  <ProductItem
                    item={item}
                    key={idx}
                    collect={false}
                  ></ProductItem>
                );
              })}
              {!loadingFlag ? (
                <li className="loading">
                  <span>没有更多内容啦～</span>
                </li>
              ) : (
                <li className="loading">
                  <span>正在加载中～</span>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(BrowsingHistory);
