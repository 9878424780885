import React, { Component } from "react";
import "./incomeAndRank.scss";
import { withRouter } from "react-router-dom";
import {
  Image,
  Tabs,
  Mask,
  DatePickerView,
  PickerView,
  Toast,
} from "antd-mobile";
import $https from "../../../httpdata";
import getParam from "../../../utils/getParam";
class IncomeAndRank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backIcon: require("../../common/img/back-active.png"),
      changeIcon: require("./img/change.png"),
      down: require("./img/down.png"),
      downActive: require("./img/downActive.png"),
      closeIcon: require("../../common/img/close.png"),
      stationId: null,

      footerList: [
        {
          title: "收 入",
          icon: require("./img/income.png"),
          activeIcon: require("./img/incomActive.png"),
        },
        {
          title: "排 行",
          icon: require("./img/rank.png"),
          activeIcon: require("./img/rankActive.png"),
        },
      ],
      activePage: 0,
      dateRangeVisible: false,
      storeChangeVisible: false, //切换门店
      dateVisible: false,
      dateRangelist: [
        {
          day: 7,
        },
        {
          day: 30,
        },
        {
          day: 90,
        },
        {
          day: 120,
        },
      ],
      dateRangelist2: [
        { day: 0 },
        {
          day: 1,
        },
        {
          day: 7,
        },
        {
          day: 30,
        },
        {
          day: 90,
        },
        {
          day: "全部",
        },
      ],

      rangeValue1: new Date(),
      rangeValue2: new Date(),
      dateValue1: new Date(),
      dateValue2: new Date(),
      shopRankValue1: new Date(),
      shopRankValue2: new Date(),

      date_start: new Date(),
      date_end: new Date(),
      date_start2: new Date(),
      date_end2: new Date(),
      date_start3: new Date(),
      date_end3: new Date(),
      activeDate1: 0,
      aaa1: 0,
      aaa2: 0,
      aaa3: 0,
      activeDate2: 0,
      activeDate3: 0,
      storeColumns: [],
      // checkedStore: {},
      storeValue: "",
      station_value: "",
      storeName: "",

      detailData1: null, //小票统计数据
      detailData2: null,
      detailData3: null,
      detailData4: null,
      detailData5: null,
      stationList: [],
    };
  }
  componentDidMount() {
    this.getDate(7, 0);
    this.getDate1(0, 0);
    this.getDate2(0, 0);
    this.setState(
      {
        stationId: getParam(this.props.location.search, "station_id"),
      },
      () => {
        this.getStationList();
      }
    );

    setTimeout(() => {
      this.getRangeValue();
      this.getShopRankValue();
      this.getDateValue();
    }, 1000);
  }
  changePage = (idx) => {
    this.setState({
      activePage: idx,
    });
  };

  //小票统计
  getDetail1 = () => {
    const { date_start, date_end, station_value } = this.state;
    $https
      .post("/api/staff/v1/tickets_stat", {
        date_start: `${date_start.getFullYear()}-${
          date_start.getMonth() + 1 < 10
            ? "0" + (date_start.getMonth() + 1)
            : date_start.getMonth() + 1
        }-${
          date_start.getDate() < 10
            ? "0" + date_start.getDate()
            : date_start.getDate()
        }`, // 开始日期
        date_end: `${date_end.getFullYear()}-${
          date_end.getMonth() + 1 < 10
            ? "0" + (date_end.getMonth() + 1)
            : date_end.getMonth() + 1
        }-${
          date_end.getDate() < 10
            ? "0" + date_end.getDate()
            : date_end.getDate()
        }`, // 结束日期
        station_id: station_value[0], // 门店id
        current_page: 1,
        page_size: 100,
      })
      .then((res) => {
        this.setState({ detailData1: res.data });
      });
    $https
      .post("/api/staff/v1/profit_stat", {
        date_start: `${date_start.getFullYear()}-${
          date_start.getMonth() + 1 < 10
            ? "0" + (date_start.getMonth() + 1)
            : date_start.getMonth() + 1
        }-${
          date_start.getDate() < 10
            ? "0" + date_start.getDate()
            : date_start.getDate()
        }`, // 开始日期
        date_end: `${date_end.getFullYear()}-${
          date_end.getMonth() + 1 < 10
            ? "0" + (date_end.getMonth() + 1)
            : date_end.getMonth() + 1
        }-${
          date_end.getDate() < 10
            ? "0" + date_end.getDate()
            : date_end.getDate()
        }`, // 结束日期
        station_id: station_value[0], // 门店id
        current_page: 1,
        page_size: 100,
      })
      .then((res) => {
        this.setState({ detailData2: res.data });
      });
    $https
      .post("/api/staff/v1/income_stat", {
        date_start: `${date_start.getFullYear()}-${
          date_start.getMonth() + 1 < 10
            ? "0" + (date_start.getMonth() + 1)
            : date_start.getMonth() + 1
        }-${
          date_start.getDate() < 10
            ? "0" + date_start.getDate()
            : date_start.getDate()
        }`, // 开始日期
        date_end: `${date_end.getFullYear()}-${
          date_end.getMonth() + 1 < 10
            ? "0" + (date_end.getMonth() + 1)
            : date_end.getMonth() + 1
        }-${
          date_end.getDate() < 10
            ? "0" + date_end.getDate()
            : date_end.getDate()
        }`, // 结束日期
        station_id: station_value[0], // 门店id
        current_page: 1,
        page_size: 100,
      })
      .then((res) => {
        this.setState({ detailData3: res.data });
      });
  };
  getDetail2 = () => {
    const { date_start2, date_end2, station_value } = this.state;
    var parmas;

    parmas = {
      date_start: `${date_start2.getFullYear()}-${
        date_start2.getMonth() + 1 < 10
          ? "0" + (date_start2.getMonth() + 1)
          : date_start2.getMonth() + 1
      }-${
        date_start2.getDate() < 10
          ? "0" + date_start2.getDate()
          : date_start2.getDate()
      }`, // 开始日期
      date_end: `${date_end2.getFullYear()}-${
        date_end2.getMonth() + 1 < 10
          ? "0" + (date_end2.getMonth() + 1)
          : date_end2.getMonth() + 1
      }-${
        date_end2.getDate() < 10
          ? "0" + date_end2.getDate()
          : date_end2.getDate()
      }`, // 结束日期
      station_id: station_value[0], // 门店id
      current_page: 1,
      page_size: 100,
    };

    $https.post("/api/staff/v1/borrow_rank", parmas).then((res) => {
      this.setState({
        detailData4: res.data,
      });
    });
  };
  getDetail3 = () => {
    const { date_start3, date_end3, station_value } = this.state;
    var parmas;

    parmas = {
      date_start: `${date_start3.getFullYear()}-${
        date_start3.getMonth() + 1 < 10
          ? "0" + (date_start3.getMonth() + 1)
          : date_start3.getMonth() + 1
      }-${
        date_start3.getDate() < 10
          ? "0" + date_start3.getDate()
          : date_start3.getDate()
      }`, // 开始日期
      date_end: `${date_end3.getFullYear()}-${
        date_end3.getMonth() + 1 < 10
          ? "0" + (date_end3.getMonth() + 1)
          : date_end3.getMonth() + 1
      }-${
        date_end3.getDate() < 10
          ? "0" + date_end3.getDate()
          : date_end3.getDate()
      }`, // 结束日期
      station_id: station_value[0], // 门店id
      current_page: 1,
      page_size: 100,
    };

    $https.post("/api/staff/v1/mall_rank", parmas).then((res) => {
      this.setState({
        detailData5: res.data,
      });
    });
  };

  setDateRangeVisible = (val) => {
    const { date_start, date_end, aaa1 } = this.state;
    this.setState({
      dateRangeVisible: val,
    });
    if (!val) {
      this.setState({
        rangeValue1: date_start,
        rangeValue2: date_end,
        activeDate1: aaa1,
      });
    }
  };
  setStoreChangeVisible = (val) => {
    const { station_value } = this.state;
    this.setState({
      storeChangeVisible: val,
      storeValue: station_value,
    });
  };
  setDateVisible = (val) => {
    const { date_start2, date_end2, aaa2 } = this.state;
    this.setState({
      dateVisible: val,
    });
    if (!val) {
      this.setState({
        dateValue1: date_start2,
        dateValue2: date_end2,
        activeDate2: aaa2,
      });
    }
  };
  setDateVisible2 = (val) => {
    const { date_start3, date_end3, aaa3 } = this.state;
    this.setState({
      dateVisible2: val,
    });
    if (!val) {
      this.setState({
        shopRankValue1: date_start3,
        shopRankValue2: date_end3,
        activeDate3: aaa3,
      });
    }
  };

  getRangeValue = () => {
    const { rangeValue1, rangeValue2, activeDate1 } = this.state;

    this.setState(
      {
        date_start: rangeValue1,
        date_end: rangeValue2,
        aaa1: activeDate1,
      },
      () => {
        this.setDateRangeVisible(false);
        this.getDetail1();
      }
    );
  };
  getDateValue = () => {
    const { dateValue1, dateValue2, activeDate2 } = this.state;

    this.setState(
      {
        date_start2: dateValue1,
        date_end2: dateValue2,
        aaa2: activeDate2,
      },
      () => {
        this.setDateVisible(false);
        this.getDetail2();
      }
    );
  };
  getShopRankValue = () => {
    const { shopRankValue1, shopRankValue2, activeDate3 } = this.state;

    this.setState(
      {
        date_start3: shopRankValue1,
        date_end3: shopRankValue2,
        aaa3: activeDate3,
      },
      () => {
        this.setDateVisible2(false);
        this.getDetail3();
      }
    );
  };
  getDate = (n, idx) => {
    var n = n;
    var d = new Date();
    var year = d.getFullYear();
    var mon = d.getMonth() + 1;
    var day = d.getDate();
    if (day <= n) {
      if (mon > 1) {
        mon = mon - 1;
      } else {
        year = year - 1;
        mon = 12;
      }
    }
    d.setDate(d.getDate() - n);
    year = d.getFullYear();
    mon = d.getMonth() + 1;
    day = d.getDate();
    var s =
      year +
      "-" +
      (mon < 10 ? "0" + mon : mon) +
      "-" +
      (day < 10 ? "0" + day : day);
    this.setState(
      {
        rangeValue1: new Date(s),
        activeDate1: idx,
      }
      // () => {
      //   this.getDetail1();
      // }
    );
    // console.log(s);
    //  return s;
  };
  getDate1 = (n, idx) => {
    if (n == "全部") {
      this.setState({
        // dateValue1: new Date(1900, 1, 1),
        // dateValue2: new Date(2038, 12, 31),
        activeDate2: idx,
      });

      return;
    }
    var n = n;
    var d = new Date();
    var year = d.getFullYear();
    var mon = d.getMonth() + 1;
    var day = d.getDate();
    if (day <= n) {
      if (mon > 1) {
        mon = mon - 1;
      } else {
        year = year - 1;
        mon = 12;
      }
    }
    d.setDate(d.getDate() - n);
    year = d.getFullYear();
    mon = d.getMonth() + 1;
    day = d.getDate();
    var s =
      year +
      "-" +
      (mon < 10 ? "0" + mon : mon) +
      "-" +
      (day < 10 ? "0" + day : day);
    this.setState({
      dateValue1: new Date(s),
      date_start2: new Date(s),
      activeDate2: idx,
    });
    // console.log(s);
    //  return s;
  };
  getDate2 = (n, idx) => {
    if (n == "全部") {
      this.setState({
        activeDate3: idx,
      });
      return;
    }
    var n = n;
    var d = new Date();
    var year = d.getFullYear();
    var mon = d.getMonth() + 1;
    var day = d.getDate();
    if (day <= n) {
      if (mon > 1) {
        mon = mon - 1;
      } else {
        year = year - 1;
        mon = 12;
      }
    }
    d.setDate(d.getDate() - n);
    year = d.getFullYear();
    mon = d.getMonth() + 1;
    day = d.getDate();
    var s =
      year +
      "-" +
      (mon < 10 ? "0" + mon : mon) +
      "-" +
      (day < 10 ? "0" + day : day);
    this.setState({
      shopRankValue1: new Date(s),
      date_start3: new Date(s),
      activeDate3: idx,
    });
    // console.log(s);
    //  return s;
  };
  setActivDate = (val) => {
    this.setState({
      activeDate1: val,
    });
  };
  setActivDate1 = (val) => {
    // console.log(val);
    // debugger;

    this.setState({
      activeDate2: val,
    });
  };
  setActivDate2 = (val) => {
    this.setState({
      activeDate3: val,
    });
  };
  setStoreValue = (val) => {
    this.setState({
      storeValue: val,
    });
  };
  setRangeValue1 = (val) => {
    this.setState({
      rangeValue1: val,
      activeDate1: 5,
    });
  };
  setRangeValue2 = (val) => {
    this.setState({
      rangeValue2: val,
    });
  };
  setDateValue1 = (val) => {
    this.setState({
      dateValue1: val,
      activeDate2: 6,
    });
  };
  setDateValue2 = (val) => {
    this.setState({
      dateValue2: val,
    });
  };
  setShopRankValue1 = (val) => {
    this.setState({
      shopRankValue1: val,
      activeDate3: 6,
    });
  };
  setShopRankValue2 = (val) => {
    this.setState({
      shopRankValue2: val,
    });
  };

  goBack = () => {
    window.JXNative.postMessage(
      JSON.stringify({
        action: "closeWeb",
      })
    );
  };
  getStationList = () => {
    const { stationId } = this.state;
    $https.get("/api/staff/v1/station/browsable").then((res) => {
      var arr = res.data.map((item) => {
        return {
          label: item.station_name,
          value: item.station_id,
        };
      });

      var index = -1;
      arr.forEach((value, idx) => {
        if (value.value == stationId) {
          index = idx;
        }
      });
      if (index < 0) {
        index = 0;
      }

      this.setState({
        stationList: [arr],
        storeValue: [arr[index].value],
        storeName: arr[index].label,
        station_value: [arr[index].value],
      });
    });
  };
  changeStore = () => {
    const { storeValue } = this.state;
    var storeName = this.state.stationList[0].filter(
      (item) => item.value == storeValue[0]
    );
    this.setState(
      {
        station_value: storeValue,
        storeName: storeName[0].label,
      },
      () => {
        this.getDetail1();
        this.getDetail2();
        this.getDetail3();
      }
    );
    this.setStoreChangeVisible(false);
  };
  render() {
    const {
      backIcon,
      changeIcon,
      down,
      downActive,
      footerList,
      activePage,
      dateRangeVisible,
      dateVisible,
      closeIcon,
      dateRangelist,
      dateRangelist2,
      rangeValue1,
      rangeValue2,
      activeDate1,
      activeDate2,
      activeDate3,
      storeChangeVisible,
      storeValue,
      storeName,
      dateValue1,
      dateValue2,
      shopRankValue1,
      shopRankValue2,
      dateVisible2,
      detailData1,
      detailData2,
      detailData3,
      detailData4,
      detailData5,
      stationList,
      date_start,
      date_end,
    } = this.state;
    const labelRenderer = (type, data) => {
      switch (type) {
        case "month":
          return data < 10 ? "0" + data : data;
        case "day":
          return data < 10 ? "0" + data : data;

        default:
          return data;
      }
    };
    return (
      <div className="incomeAndRank">
        <header>
          <Image
            src={backIcon}
            onClick={() => this.goBack()}
            className="back"
          />
          <span>{storeName}</span>
          <div
            onClick={() => this.setStoreChangeVisible(true)}
            className="change"
          >
            切换
            <Image src={changeIcon} className="changeStore" />
          </div>
        </header>
        {activePage == 0 ? (
          <div className="income">
            <div
              className="date"
              onClick={() => this.setDateRangeVisible(true)}
            >
              {date_start.getFullYear()} /
              {date_start.getMonth() + 1 < 10
                ? "0" + (date_start.getMonth() + 1)
                : date_start.getMonth() + 1}
              /
              {date_start.getDate() < 10
                ? "0" + date_start.getDate()
                : date_start.getDate()}
              - {date_end.getFullYear()} /
              {date_end.getMonth() + 1 < 10
                ? "0" + (date_end.getMonth() + 1)
                : date_end.getMonth() + 1}
              /
              {date_end.getDate() < 10
                ? "0" + date_end.getDate()
                : date_end.getDate()}
              <Image src={down} />
            </div>
            <div className="cont1">
              <h1 className="title">
                <span></span>
                零售小票统计
              </h1>
              {detailData1 && detailData1.length ? (
                <div className="retail-table">
                  <div className="table">
                    <ul className="header">
                      <li></li>
                      <li>品种</li>
                      <li>消费次数</li>
                      <li>数量</li>
                      <li>均价</li>
                      <li>折扣</li>
                      <li>码洋</li>
                      <li>折扣额</li>
                      <li>实洋</li>
                      <li>费用金额</li>
                      <li>实际收款</li>
                      <li>客单价</li>

                      <li>品种百分比</li>
                      <li>累计品种百分比</li>
                      <li>数量百分比</li>
                      <li>累计数量百分比</li>
                      <li>实洋百分比</li>
                      <li>累计实洋百分比</li>
                      <li>码洋百分比</li>
                      <li>累计码洋百分比</li>
                    </ul>

                    {detailData1 &&
                      detailData1.map((item, idx) => {
                        return (
                          <ul key={idx}>
                            <li>{item.class_name}</li>
                            <li>{item.total_count}</li>
                            <li>{item.consume_times}</li>
                            <li>{item.quantity}</li>
                            <li>{item.avg_price}</li>
                            <li>{item.discount}</li>
                            <li>{item.total_money}</li>
                            <li>{item.discount_real}</li>
                            <li>{item.real_money}</li>
                            <li>{item.charge}</li>
                            <li>{item.sk_money}</li>
                            <li>{item.ls_price}</li>
                            <li>{item.category_percent}</li>
                            <li>{item.accumulate_category_percent}</li>
                            <li>{item.quantity_percent}</li>
                            <li>{item.accumulate_quantity_percent}</li>
                            <li>{item.realmoney_percent}</li>
                            <li>{item.accumulate_realmoney_percent}</li>
                            <li>{item.totalmoney_percent}</li>
                            <li>{item.accumulate_totalmoney_percent}</li>
                          </ul>
                        );
                      })}
                  </div>
                </div>
              ) : null}

              <h1 className="title">
                <span></span>
                零售利润分析
              </h1>
              {detailData2 && detailData2.length ? (
                <div className="retail-table retail-table-lirun">
                  <div className="table">
                    <ul className="header ">
                      <li></li>
                      {/* <li>2分类</li> */}
                      <li>品种数</li>
                      <li>数量</li>
                      <li>码洋</li>
                      <li>实洋</li>
                      <li>成本</li>
                      <li>不含税成本</li>
                      <li>实际收款</li>
                      <li>毛利</li>
                      <li>毛利率</li>
                      <li>实际毛利</li>
                      <li>实际收款(减卡券优惠实际收入)</li>
                      <li>固定成本</li>
                      <li>折扣</li>
                      <li>折扣金额</li>
                      <li>费用金额</li>
                      <li>成本折扣</li>
                      <li>纯利</li>
                      <li>数量百分比</li>
                      <li>实洋百分比</li>
                    </ul>
                    {detailData2 &&
                      detailData2.map((item, idx) => {
                        return (
                          <ul key={idx}>
                            <li>{item.class_name}</li>

                            <li>{item.total_count}</li>
                            <li>{item.total_amount}</li>
                            <li>{item.total_money}</li>
                            <li>{item.real_money}</li>
                            <li>{item.total_cost}</li>
                            <li>{item.total_cost_tax}</li>
                            <li>{item.sk_money}</li>

                            <li>{item.income}</li>
                            <li>{item.income_rate}</li>
                            <li>{item.income_real}</li>
                            <li>{item.item_real_income}</li>
                            <li>{item.h_input_price}</li>
                            <li>{item.discount}</li>
                            <li>{item.discount_real}</li>
                            <li>{item.charge}</li>
                            <li>{item.total_cot_dicount}</li>
                            <li>{item.net_profit}</li>
                            <li>{item.quantity_percent}</li>
                            <li>{item.realmoney_percent}</li>
                          </ul>
                        );
                      })}
                  </div>
                </div>
              ) : null}

              <h1 className="title">
                <span></span>
                零售收款统计
              </h1>
              {detailData3 && detailData3.length ? (
                <div className="retail-table">
                  <div className="table">
                    <ul className="header">
                      {/* <li>1</li> */}
                      <li></li>
                      <li>收款金额</li>
                      <li>码洋</li>
                      <li>实洋</li>
                      <li>成本</li>
                      <li>实际收入</li>
                      <li>交易笔数</li>
                      <li>品种</li>
                      <li>数量</li>
                      <li>费用金额</li>
                      <li>折扣金额</li>
                      <li>销售折扣</li>
                      <li>每笔金额</li>
                      <li>利润</li>
                    </ul>
                    {detailData3 &&
                      detailData3.map((item, idx) => {
                        return (
                          <ul key={idx}>
                            <li>{item.class_name}</li>
                            <li>{item.sk_money}</li>
                            <li>{item.total_money}</li>
                            <li>{item.real_money}</li>

                            <li>{item.total_cost}</li>
                            <li>{item.item_real_income}</li>

                            <li>{item.total_id}</li>

                            <li>{item.pz_id}</li>

                            <li>{item.total_amount}</li>
                            <li>{item.cost_money}</li>

                            <li>{item.discount_real}</li>

                            <li>{item.discount}</li>

                            <li>{item.each_amount}</li>
                            <li>{item.profit}</li>
                          </ul>
                        );
                      })}
                  </div>
                </div>
              ) : null}

              <Mask
                visible={dateRangeVisible}
                onMaskClick={() => this.setDateRangeVisible(false)}
              >
                <div className="income-dialog">
                  <h1 className="title">
                    <span></span>
                    <span>选择时间范围</span>
                    <Image
                      src={closeIcon}
                      onClick={() => this.setDateRangeVisible(false)}
                    />
                  </h1>
                  <div className="choose">
                    <ul className="days">
                      {dateRangelist.map((item, idx) => {
                        return (
                          <li
                            key={idx}
                            onClick={() => this.getDate(item.day, idx)}
                            className={activeDate1 == idx ? "active" : null}
                          >
                            近{item.day}天
                          </li>
                        );
                      })}
                    </ul>

                    <div className="values">
                      <span
                        onClick={() => this.setActivDate(5)}
                        className={
                          activeDate1 == 5 || activeDate1 !== 6
                            ? "active"
                            : null
                        }
                      >
                        {rangeValue1.getFullYear()} -
                        {rangeValue1.getMonth() + 1 < 10
                          ? "0" + (rangeValue1.getMonth() + 1)
                          : rangeValue1.getMonth() + 1}
                        -
                        {rangeValue1.getDate() < 10
                          ? "0" + rangeValue1.getDate()
                          : rangeValue1.getDate()}
                      </span>
                      <em></em>
                      <span
                        onClick={() => this.setActivDate(6)}
                        className={activeDate1 == 6 ? "active" : null}
                      >
                        {rangeValue2.getFullYear()} -
                        {rangeValue2.getMonth() + 1 < 10
                          ? "0" + (rangeValue2.getMonth() + 1)
                          : rangeValue2.getMonth() + 1}
                        -
                        {rangeValue2.getDate() < 10
                          ? "0" + rangeValue2.getDate()
                          : rangeValue2.getDate()}
                      </span>
                    </div>

                    {activeDate1 == 5 || activeDate1 !== 6 ? (
                      <DatePickerView
                        style={{
                          "--height": "5rem",
                          "--item-height": "1rem",
                        }}
                        value={rangeValue1}
                        max={rangeValue2}
                        onChange={(val) => this.setRangeValue1(val)}
                        renderLabel={labelRenderer}
                      />
                    ) : (
                      <DatePickerView
                        style={{
                          "--height": "5rem",
                          "--item-height": "1rem",
                        }}
                        value={rangeValue2}
                        min={rangeValue1}
                        onChange={(val) => this.setRangeValue2(val)}
                        renderLabel={labelRenderer}
                      />
                    )}

                    <div className="btn" onClick={() => this.getRangeValue()}>
                      确定
                    </div>
                  </div>
                </div>
              </Mask>
            </div>
          </div>
        ) : (
          <div className="rank">
            <Tabs className="classify">
              <Tabs.Tab title="图书借阅" key="fruits">
                <div className="date" onClick={() => this.setDateVisible(true)}>
                  {dateValue1.getFullYear()} /
                  {dateValue1.getMonth() + 1 < 10
                    ? "0" + (dateValue1.getMonth() + 1)
                    : dateValue1.getMonth() + 1}
                  /
                  {dateValue1.getDate() < 10
                    ? "0" + dateValue1.getDate()
                    : dateValue1.getDate()}
                  - {dateValue2.getFullYear()} /
                  {dateValue2.getMonth() + 1 < 10
                    ? "0" + (dateValue2.getMonth() + 1)
                    : dateValue2.getMonth() + 1}
                  /
                  {dateValue2.getDate() < 10
                    ? "0" + dateValue2.getDate()
                    : dateValue2.getDate()}
                  <Image src={downActive} />
                </div>
                <div className="cont1">
                  {/* <h1 className="title">
                    <span></span>
                    零售小票统计
                  </h1> */}
                  <div className="retail-table">
                    <div className="table">
                      <ul className="header rank-header">
                        <li>排行</li>
                        {detailData4 &&
                          detailData4.map((item, idx) => {
                            return <li key={idx}>{idx + 1}</li>;
                          })}
                      </ul>
                      <ul className="books">
                        <li>书籍名称</li>
                        {detailData4 &&
                          detailData4.map((item, idx) => {
                            return <li key={idx}>{item.h_name}</li>;
                          })}
                      </ul>
                      <ul>
                        <li>借阅数量</li>
                        {detailData4 &&
                          detailData4.map((item, idx) => {
                            return <li key={idx}>{item.h_amount}</li>;
                          })}
                      </ul>
                      <ul>
                        <li>库存数量</li>
                        {detailData4 &&
                          detailData4.map((item, idx) => {
                            return <li key={idx}>{item.kc_amount}</li>;
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
                <Mask
                  visible={dateVisible}
                  onMaskClick={() => this.setDateVisible(false)}
                >
                  <div className="income-dialog">
                    <h1 className="title">
                      <span></span>
                      <span>选择时间范围</span>
                      <Image
                        src={closeIcon}
                        onClick={() => this.setDateVisible(false)}
                      />
                    </h1>
                    <div className="choose">
                      <ul className="days">
                        {dateRangelist2.map((item, idx) => {
                          return (
                            <li
                              key={idx}
                              onClick={() => this.getDate1(item.day, idx)}
                              className={activeDate2 == idx ? "active" : null}
                            >
                              {item.day == 0
                                ? "今天"
                                : item.day == 1
                                ? "昨天"
                                : item.day == "全部"
                                ? "全部"
                                : `近${item.day}天`}
                            </li>
                          );
                        })}
                      </ul>

                      <div className="values">
                        <span
                          onClick={() => this.setActivDate1(6)}
                          className={
                            activeDate2 == 6 || activeDate2 !== 7
                              ? "active"
                              : null
                          }
                        >
                          {dateValue1.getFullYear()} -
                          {dateValue1.getMonth() + 1 < 10
                            ? "0" + (dateValue1.getMonth() + 1)
                            : dateValue1.getMonth() + 1}
                          -
                          {dateValue1.getDate() < 10
                            ? "0" + dateValue1.getDate()
                            : dateValue1.getDate()}
                        </span>
                        <em></em>
                        <span
                          onClick={() => this.setActivDate1(7)}
                          className={activeDate2 == 7 ? "active" : null}
                        >
                          {dateValue2.getFullYear()} -
                          {dateValue2.getMonth() + 1 < 10
                            ? "0" + (dateValue2.getMonth() + 1)
                            : dateValue2.getMonth() + 1}
                          -
                          {dateValue2.getDate() < 10
                            ? "0" + dateValue2.getDate()
                            : dateValue2.getDate()}
                        </span>
                      </div>

                      {activeDate2 == 6 || activeDate2 !== 7 ? (
                        <DatePickerView
                          style={{
                            "--height": "5rem",
                            "--item-height": "1rem",
                          }}
                          value={dateValue1}
                          max={dateValue2}
                          onChange={(val) => this.setDateValue1(val)}
                          renderLabel={labelRenderer}
                        />
                      ) : (
                        <DatePickerView
                          style={{
                            "--height": "5rem",
                            "--item-height": "1rem",
                          }}
                          value={dateValue2}
                          min={dateValue1}
                          onChange={(val) => this.setDateValue2(val)}
                          renderLabel={labelRenderer}
                        />
                      )}

                      <div className="btn" onClick={() => this.getDateValue()}>
                        确定
                      </div>
                    </div>
                  </div>
                </Mask>
              </Tabs.Tab>

              <Tabs.Tab title="商城销售" key="vegetables">
                <div
                  className="date"
                  onClick={() => this.setDateVisible2(true)}
                >
                  {shopRankValue1.getFullYear()} /
                  {shopRankValue1.getMonth() + 1 < 10
                    ? "0" + (shopRankValue1.getMonth() + 1)
                    : shopRankValue1.getMonth() + 1}
                  /
                  {shopRankValue1.getDate() < 10
                    ? "0" + shopRankValue1.getDate()
                    : shopRankValue1.getDate()}
                  - {shopRankValue2.getFullYear()} /
                  {shopRankValue2.getMonth() + 1 < 10
                    ? "0" + (shopRankValue2.getMonth() + 1)
                    : shopRankValue2.getMonth() + 1}
                  /
                  {shopRankValue2.getDate() < 10
                    ? "0" + shopRankValue2.getDate()
                    : shopRankValue2.getDate()}
                  <Image src={downActive} />
                </div>
                <div className="cont1">
                  {/* <h1 className="title">
                    <span></span>
                    零售小票统计
                  </h1> */}
                  <div className="retail-table">
                    <div className="table">
                      <ul className="header rank-header">
                        <li>排行</li>
                        {detailData5 &&
                          detailData5.map((item, idx) => {
                            return <li key={idx}>{idx + 1}</li>;
                          })}
                      </ul>
                      <ul className="books">
                        <li>商品名称</li>
                        {detailData5 &&
                          detailData5.map((item, idx) => {
                            return <li key={idx}>{item.h_name}</li>;
                          })}
                      </ul>
                      <ul>
                        <li>商品编码</li>
                        {detailData5 &&
                          detailData5.map((item, idx) => {
                            return <li key={idx}>{item.h_isbn}</li>;
                          })}
                      </ul>
                      <ul>
                        <li>售价</li>
                        {detailData5 &&
                          detailData5.map((item, idx) => {
                            return <li key={idx}>{item.real_money}</li>;
                          })}
                      </ul>
                      <ul>
                        <li>销售金额</li>
                        {detailData5 &&
                          detailData5.map((item, idx) => {
                            return <li key={idx}>{item.total_money}</li>;
                          })}
                      </ul>
                      <ul>
                        <li>销量</li>
                        {detailData5 &&
                          detailData5.map((item, idx) => {
                            return <li key={idx}>{item.total_amount}</li>;
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
                <Mask
                  visible={dateVisible2}
                  onMaskClick={() => this.setDateVisible2(false)}
                >
                  <div className="income-dialog">
                    <h1 className="title">
                      <span></span>
                      <span>选择时间范围</span>
                      <Image
                        src={closeIcon}
                        onClick={() => this.setDateVisible2(false)}
                      />
                    </h1>
                    <div className="choose">
                      <ul className="days">
                        {dateRangelist2.map((item, idx) => {
                          return (
                            <li
                              key={idx}
                              onClick={() => this.getDate2(item.day, idx)}
                              className={activeDate3 == idx ? "active" : null}
                            >
                              {item.day == 0
                                ? "今天"
                                : item.day == 1
                                ? "昨天"
                                : item.day == "全部"
                                ? "全部"
                                : `近${item.day}天`}
                            </li>
                          );
                        })}
                      </ul>

                      <div className="values">
                        <span
                          onClick={() => this.setActivDate2(6)}
                          className={
                            activeDate3 == 6 || activeDate3 !== 7
                              ? "active"
                              : null
                          }
                        >
                          {shopRankValue1.getFullYear()} -
                          {shopRankValue1.getMonth() + 1 < 10
                            ? "0" + (shopRankValue1.getMonth() + 1)
                            : shopRankValue1.getMonth() + 1}
                          -
                          {shopRankValue1.getDate() < 10
                            ? "0" + shopRankValue1.getDate()
                            : shopRankValue1.getDate()}
                        </span>
                        <em></em>
                        <span
                          onClick={() => this.setActivDate2(7)}
                          className={activeDate3 == 7 ? "active" : null}
                        >
                          {shopRankValue2.getFullYear()} -
                          {shopRankValue2.getMonth() + 1 < 10
                            ? "0" + (shopRankValue2.getMonth() + 1)
                            : shopRankValue2.getMonth() + 1}
                          -
                          {shopRankValue2.getDate() < 10
                            ? "0" + shopRankValue2.getDate()
                            : shopRankValue2.getDate()}
                        </span>
                      </div>

                      {activeDate3 == 6 || activeDate3 !== 7 ? (
                        <DatePickerView
                          style={{
                            "--height": "5rem",
                            "--item-height": "1rem",
                          }}
                          value={shopRankValue1}
                          max={shopRankValue2}
                          onChange={(val) => this.setShopRankValue1(val)}
                          renderLabel={labelRenderer}
                        />
                      ) : (
                        <DatePickerView
                          style={{
                            "--height": "5rem",
                            "--item-height": "1rem",
                          }}
                          value={shopRankValue2}
                          min={shopRankValue1}
                          onChange={(val) => this.setShopRankValue2(val)}
                          renderLabel={labelRenderer}
                        />
                      )}

                      <div
                        className="btn"
                        onClick={() => this.getShopRankValue()}
                      >
                        确定
                      </div>
                    </div>
                  </div>
                </Mask>
              </Tabs.Tab>
            </Tabs>

            {/* <Mask
              visible={dateVisible}
              onMaskClick={() => this.setDateVisible(false)}
            >
              <div className="changeStoreBox">
                <h1 className="title">
                  <span></span>
                  <span>选择日期</span>
                  <Image
                    src={closeIcon}
                    onClick={() => this.setDateVisible(false)}
                  />
                </h1>
                <DatePickerView
                  style={{
                   
                   
                  }}
                  value={dateValue}
                
                  onChange={(val) => this.setDateValue(val)}
                  renderLabel={labelRenderer}
                />
                <div className="btn">确定</div>
              </div>
            </Mask> */}
          </div>
        )}
        <Mask
          visible={storeChangeVisible}
          onMaskClick={() => this.setStoreChangeVisible(false)}
        >
          <div className="changeStoreBox">
            <h1 className="title">
              <span>选择门店</span>
              <Image
                src={closeIcon}
                onClick={() => this.setStoreChangeVisible(false)}
              />
            </h1>
            <p>未开通权限的门店不显示，请先联系管理员开通权限</p>
            {stationList && stationList.length ? (
              <PickerView
                columns={stationList}
                // style={{
                //   "--height": "300px",
                // }}
                style={{
                  // "--height": "5rem",
                  "--item-height": "1rem",
                }}
                value={storeValue}
                defaultValue={storeValue}
                onChange={(val) => this.setStoreValue(val)}
              />
            ) : null}

            <div className="btn" onClick={() => this.changeStore()}>
              确定
            </div>
          </div>
        </Mask>
        <div className="footer">
          <ul>
            {footerList.map((item, idx) => {
              return (
                <li
                  key={idx}
                  className={activePage == idx ? "active" : null}
                  onClick={() => this.changePage(idx)}
                >
                  <Image
                    src={activePage == idx ? item.activeIcon : item.icon}
                  />
                  <span>{item.title}</span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

export default withRouter(IncomeAndRank);
