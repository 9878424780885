import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Toast } from "antd-mobile";
import $https from "../../../httpdata";
import getParam from "../../../utils/getParam";
import SmsAreasCode from "../../../components/SmsAreasCode";
import "./index.scss";

class Wallet extends Component {
  constructor(props) {
    super(props);
    const { location } = props;
    this.needClose = getParam(location.search, "needClose");
    this.hasPassword = getParam(location.search, "has_pay_password");
    this.locking = false;
    this.intervalTime = null;
    this.state = {
      backIcon: require("../../common/img/back-active.png"),
      tips: "",
      nation_code: "86",
      mobile: "",
      auth_code: "",
      password: "",
      checkPassword: "",
      codeIntervalTime: 0,
      showPassword: false,
      isModalShow: false,
    };
  }

  componentDidMount() {
    this.getUserInfo();
  }

  componentWillUnmount() {
    clearInterval(this.intervalTime);
  }

  getUserInfo = () => {
    $https
      .get("/api/v1/user/profile")
      .then((res) => {
        if (res && res.data && res.data.user) {
          this.setState({
            mobile: res.data.user.mobile,
            nation_code: res.data.user.nation_code
          });
        }
      });
  }

  changePhoneNum = (e) => {
    this.setState({
      mobile: e.target.value,
      tips: ""
    });
  }

  changeAuthCode = (e) => {
    this.setState({
      auth_code: e.target.value,
      tips: ""
    });
  }

  changePassword = (e) => {
    this.setState({
      password: e.target.value.replace(/\D/g, ""),
      tips: ""
    });
  }

  changeCheckPassword = (e) => {
    this.setState({
      checkPassword: e.target.value.replace(/\D/g, ""),
      tips: ""
    });
  }

  getPhoneCode = () => {
    const { mobile, nation_code } = this.state;
    if (mobile.length < 7) {
      Toast.show("请输入正确的手机号");
      return;
    }
    $https
      .post("/api/v1/sms", {
        mobile,
        nation_code
      })
      .then((res) => {
        if (res && res.code === 0) {
          this.setState({
            codeIntervalTime: 60,
          }, () => {
            this.intervalTimeFn();
          });
        } else {
          // Toast.show((res && res.msg) || "数据异常！");
          this.setState({
            tips: (res && res.msg) || "获取失败，请重试！"
          })
        }
      });
  }

  checkPasswordStatus = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  intervalTimeFn = () => {
    this.intervalTime = setInterval(() => {
      this.setState({
        codeIntervalTime: this.state.codeIntervalTime - 1
      }, () => {
        if (this.state.codeIntervalTime <= 0) clearInterval(this.intervalTime);
      })
    }, 1000);
  }

  goToPage = (url) => {
    this.props.history.push({
      pathname: url
    });
  }

  submit = () => {
    const { mobile, nation_code, auth_code, password, checkPassword } = this.state;
    if (password !== checkPassword) {
      this.setState({
        tips: "两次密码输入不一致"
      });
      return;
    }
    if (!mobile || !auth_code || !password || !checkPassword) {
      this.setState({
        tips: "请完善信息"
      });
      return;
    }
    if (this.locking) return;
    this.locking = true;
    $https
      .post("/api/v1/wallet/password/code", {
        nation_code,
        mobile,
        auth_code,
        password
      })
      .then((res) => {
        if (res && res.code === 0) {
          this.setState({
            auth_code: "",
            password: "",
            checkPassword: "",
          }, () => {
            this.locking = false;
            this.goToPage("/wallet");
          });
        } else {
          this.setState({
            tips: (res && res.msg) || "获取失败，请重试！"
          }, () => {
            this.locking = false;
          });
        }
      });
  }

  handleCloseSmsModal = () => {
    const { isModalShow } = this.state;
    this.setState({
      isModalShow: !isModalShow
    })
  }

  handleSelectSmsCode = (nation_code) => {
    this.setState({
      nation_code
    }, () => {
      this.handleCloseSmsModal();
    });
  }

  goBack = () => {
    if (this.needClose) {
      window.JXNative.postMessage(
        JSON.stringify({
          action: "closeWeb",
        })
      );
    } else {
      this.props.history.go(-1);
    }
  }

  render() {
    const { backIcon, tips, nation_code, mobile, auth_code, password, checkPassword, codeIntervalTime, showPassword, isModalShow } = this.state;

    return (
      <div className="mine-collect mine-wallet-password">
        <header>
          <div className="head">
            <img src={backIcon} alt="" onClick={() => this.goBack()} />
            <span>{+this.hasPassword ? "修改" : "设置"}支付密码</span>
            <em></em>
          </div>
        </header>
        <div className="password-item">
          <ul>
            <li>
              <span className="wallet-icon-phone"></span>
              <em className="country-code" onClick={mobile ? null : () => { this.handleCloseSmsModal() }}>+ {nation_code}</em>
              <input className="phone-input" placeholder="请输入手机号" onInput={mobile ? null : (e) => { this.changePhoneNum(e) }} disabled={mobile ? true : false} value={mobile} />
              {codeIntervalTime > 0 ?
                <em className="interval-time">{codeIntervalTime} s</em>
                :
                <em className="get-code" onClick={() => { this.getPhoneCode() }}>获取验证码</em>
              }
            </li>
            <li>
              <span className="wallet-icon-code"></span>
              <input name="auth_code" type="number" placeholder="请输入验证码" maxLength="6" onInput={(e) => { this.changeAuthCode(e) }} value={auth_code} />
            </li>
            <li>
              <span className="wallet-icon-password"></span>
              <input
                type={showPassword ? "number" : "password"}
                name="password"
                maxLength="6"
                placeholder="请输入新密码 (6位数字)"
                onInput={(e) => { this.changePassword(e) }}
                value={password}
              />
              <span className={showPassword ? "wallet-icon-show" : "wallet-icon-hide"} onClick={() => { this.checkPasswordStatus() }}></span>
            </li>
            <li>
              <span className="wallet-icon-password"></span>
              <input
                type={showPassword ? "number" : "password"}
                name="check_password"
                maxLength="6"
                placeholder="请再次输入密码"
                onInput={(e) => { this.changeCheckPassword(e) }}
                value={checkPassword}
              />
            </li>
          </ul>
          <div className="password-tips">{tips}</div>
          <div className={`password-botton ${!mobile || !nation_code || !password || !checkPassword ? "disable" : ""}`} onClick={() => { this.submit() }}>{+this.hasPassword ? "确认修改" : "保存"}</div>
        </div>

        {isModalShow ?
          <SmsAreasCode
            onClose={this.handleCloseSmsModal}
            onSelect={this.handleSelectSmsCode}
          />
          : null}

      </div>
    );
  }
}
export default withRouter(Wallet);
