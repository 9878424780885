import "./memberPoints.scss";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import $https from "../../../httpdata";
import { Image } from "antd-mobile";
class MemberPoints extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backIcon: require("../../common/img/back.png"),
      pointsActive: require("./img/points-active.png"),
      points: require("./img/points.png"),
      navList: [
        {
          type: 0,
          title: "所有",
        },
        {
          type: 1,
          title: "获得",
        },
        {
          type: 2,
          title: "消耗",
        },
      ],
      activeType: 0,
      page: 1,
      page_size: 6,
      pointsList: [],
      loadingFlag: false,
      last_page: null,
      balance: 0,
    };
  }
  componentDidMount() {
    this.searchPoints();
    let that = this;
    window.onscroll = function () {
      var pageNum = that.state.page;
      const { last_page } = that.state;
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollTop + windowHeight >= scrollHeight) {
        if (!last_page) {
          that.setState({
            loadingFlag: true,
          });
          return;
        }
        if (pageNum < last_page) {
          pageNum = pageNum + 1;

          that.setState(
            {
              page: pageNum,
              loadingFlag: true,
            },
            () => {
              that.searchPoints();
            }
          );
        } else {
          that.setState({
            loadingFlag: false,
          });
        }

        //考虑到滚动的位置一般可能会大于一点可滚动的高度，所以这里不能用等于
      }
    };
  }
  componentWillUnmount() {}
  searchPoints = () => {
    const { page_size, page, activeType } = this.state;
    $https
      .get("/api/v1/user/credit/search", {
        page_size: page_size,
        type: activeType,
        page,
      })
      .then((res) => {
        console.log(res);
        var arr = this.state.pointsList ? this.state.pointsList : [];
        var newArr = [...arr, ...res.data.paginator.data];
        this.setState({
          last_page: res.data.paginator.last_page,
          pointsList: newArr,
          loadingFlag: false,
          balance: res.data.balance,
        });
      });
  };
  changeType = (type) => {
    this.setState(
      {
        activeType: type,
        page: 1,
        pointsList: [],
        loadingFlag: true,
      },
      () => {
        this.searchPoints();
      }
    );
  };
  goBack = () => {
    window.JXNative.postMessage(
      JSON.stringify({
        action: "closeWeb",
      })
    );
  };
  render() {
    const {
      backIcon,
      navList,
      activeType,
      pointsActive,
      points,
      pointsList,
      loadingFlag,
      balance,
    } = this.state;
    return (
      <div className="member-points">
        <header>
          <div className="head">
            <img src={backIcon} alt="" onClick={() => this.goBack()} />
            <span>我的积分</span>
            <em></em>
          </div>
          <div className="points">{balance}</div>
        </header>
        <div className="list">
          <ul className="nav">
            {navList.map((item, idx) => {
              return (
                <li
                  key={idx}
                  className={activeType == item.type ? "active" : null}
                  onClick={() => this.changeType(item.type)}
                >
                  {item.title}
                </li>
              );
            })}
          </ul>
          <div className="points-box">
            <ul>
              {pointsList.map((item, idx) => {
                return (
                  <li key={idx}>
                    <div className="points-left">
                      <Image
                        lazy
                        src={item.income ? pointsActive : points}
                        alt=""
                        className="icon-points"
                      />
                      <div>
                        <p className="title">{item.source}</p>
                        <em className="time">{item.time}</em>
                      </div>
                    </div>
                    <div className={item.income ? "income" : "expend"}>
                      {item.income ? "+" : ""}
                      {item.amount}
                    </div>
                  </li>
                );
              })}
              {!loadingFlag ? (
                <li className="loading">
                  <span>没有更多内容啦～</span>
                </li>
              ) : (
                <li className="loading">
                  <span>正在加载中～</span>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(MemberPoints);
