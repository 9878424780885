import "./storeDetail.scss";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Image, Swiper, Toast, ImageViewer } from "antd-mobile";
import $https from "../../httpdata";
import getParam from "../../utils/getParam";
class StoreDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backIcon: require("./img/back-white.png"),
      searchIcon: require("./img/search.png"),
      trumpet: require("../common/img/trumpet.png"),
      informRight: require("./img/inform-right.png"),
      solidStar: require("../common/img/solid-star.png"),
      hollowStar: require("../common/img/hollow-star.png"),
      halfStar: require("../common/img/half-star.png"),
      collectActive: require("../common/img/collect-active.png"),
      collect: require("../common/img/collect.png"),
      openTime: require("./img/time.png"),
      telIcon: require("./img/tel.png"),
      shopIcon: require("./img/shop.png"),
      location: require("./img/location.png"),
      shopInfoRight: require("../common/img/shop-info-right.png"),
      shopInfoDown: require("../common/img/shop-info-down.png"),
      shopInfoUp: require("../common/img/shop-info-up.png"),
      showAll: false, //书店简介展开
      activeClass: "search",
      shopFunctions: [],
      detailData: {},
      comments: {},
      // productsList: {},
      locationEnable: null,
      imgVisible: false,
      imgUrl: "",
      latitude: "",
    };
  }
  componentDidMount() {
    let that = this;
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    window.addEventListener("scroll", () => {
      let activeClass = "searchActive";
      let backIcon = require("./img/back-white.png");
      if (window.scrollY >= 560) {
        backIcon = require("../common/img/back-active.png");
        activeClass = "search searchActive";
      } else {
        backIcon = require("./img/back-white.png");
        activeClass = "search";
      }
      this.setState({ activeClass, backIcon });
    });

    window.JXNative &&
      window.JXNative.postMessage &&
      window.JXNative.postMessage(
        JSON.stringify({
          action: "locationEnable",
        })
      );

    window.onLocationEnable = async function (res) {
      that.setState({
        locationEnable: res,
      });
    };
    window.JXNative &&
      window.JXNative.postMessage &&
      window.JXNative.postMessage(
        JSON.stringify({
          action: "gpsLocation",
        })
      );
    window.onGpsLocation = async function (res) {
      that.setState(
        {
          latitude: res.latitude,
          longitude: res.longitude,
        },
        () => {
          that.getDetail();
        }
      );
    };
    this.getDetail();
    this.getComments();
    // this.getProductsList();
  }
  getDetail = () => {
    let that = this;
    var param = getParam(this.props.location.search, "id");

    const { latitude, longitude } = this.state;
    $https
      .get("/api/v1/station", {
        station_id: param,
        latitude,
        longitude,
      })
      .then((res) => {
        that.setState({ detailData: res.data });
      });
  };
  getComments = () => {
    let that = this;
    var param = getParam(this.props.location.search, "id");
    $https
      .get("/api/v1/station/comments", {
        station_id: param,
        page_size: 6,
      })
      .then((res) => {
        that.setState({
          comments: res.data,
        });
      });
  };
  // getProductsList = () => {
  //   let that = this;
  //   var param = getParam(this.props.location.search, "id");
  //   $https
  //     .get("/api/v1/rank/second_kill", {
  //       station_id: param,
  //       page_size: 6,
  //     })
  //     .then((res) => {
  //       that.setState({ productsList: res.data });
  //     });
  // };
  bannerSwiper = (vals) => {
    var nodes = vals.map((item, index) => {
      return (
        <Swiper.Item key={index}>
          <div
            className="swiper-content"
            style={{
              backgroundImage: `url(${
                item.url
                  ? item.url
                  : "https://jx-storage.oss-cn-beijing.aliyuncs.com/ae6fffd2e9f4a96bed9c139c12ba5dff.png"
              })`,
            }}
            onClick={() => {
              if (item.target_url) {
                window.JXNative.postMessage(
                  JSON.stringify({
                    action: "openWeb",
                    url: item.target_url,
                  })
                );
              }
            }}
          ></div>
        </Swiper.Item>
      );
    });
    return nodes;
  };
  stars = (num) => {
    var newNum = num / 10;
    var intNum = Math.trunc(newNum);
    var littleNum = newNum - intNum;

    const { solidStar, hollowStar, halfStar } = this.state;
    var str = [];
    for (let i = 0; i < 5; i++) {
      if (i < intNum) {
        str.push(<img src={solidStar} alt="" />);
      } else if (i == intNum && littleNum > 0) {
        str.push(<img src={halfStar} alt="" />);
      } else {
        str.push(<img src={hollowStar} alt="" />);
      }
    }
    return <div className="stars"> {str} </div>;
  };

  getShopFunctions = (arr) => {
    const colors = ["#AC9685", "#bcffbd", "#e4fabd", "#ffcfac", "#fff09b"];

    return (
      <div className="shop-functions">
        {arr.map((val, idx) => {
          return (
            <span style={{ background: colors[idx] }} key={idx}>
              {val.label}
            </span>
          );
        })}
      </div>
    );
  };
  showAll = () => {
    const { showAll } = this.state;
    this.setState({
      showAll: !showAll,
    });
  };
  toCommentDetail = () => {
    var param = getParam(this.props.location.search, "id");
    this.props.history.push({
      pathname: `/storeCommentDetail`,
      search: `station_id=${param}`,
    });
  };
  //通知详情
  toNoticeDetail = (url) => {
    window.JXNative.postMessage(
      JSON.stringify({
        action: "openWeb",
        url: url,
      })
    );
  };
  closeBack = () => {
    var navBar = getParam(this.props.location.search, "navBar");
    if (navBar !== null) {
      window.JXNative.postMessage(
        JSON.stringify({
          action: "closeWeb",
        })
      );
    } else {
      this.props.history.goBack();
    }
  };
  toProductDetail = (goods_id) => {
    var station_id = getParam(this.props.location.search, "id");
    this.props.history.push({
      pathname: `/productDetail`,
      search: `station_id=${station_id}&goods_id=${goods_id}`,
    });
  };
  toSearch = () => {
    // Toast.show("11");
    var station_id = getParam(this.props.location.search, "id");
    const { detailData } = this.state;
    window.JXNative.postMessage(
      JSON.stringify({
        action: "pushPage",
        page: `/search`,
        stationId: station_id,
        station: detailData,
        from: "h5",
      })
    );
  };
  // toAllProducts = () => {
  //   var station_id = getParam(this.props.location.search, "id");
  //   const { detailData } = this.state;
  //   window.JXNative.postMessage(
  //     JSON.stringify({
  //       action: "pushPage",
  //       page: `/shop`,
  //       station_id: station_id,
  //       station_name: detailData.name,
  //       allow_other: false,
  //     })
  //   );
  // };

  toMapDetail = () => {
    const { detailData, locationEnable } = this.state;
    // this.props.history.push({
    //   pathname: `/mapContainer`,
    //   search: `lat=${detailData.latitude}&lng=${detailData.longitude}&name=${detailData.name}`,
    // });
    if (locationEnable) {
      this.props.history.push({
        pathname: `/mapContainer`,
        search: `lat=${detailData.latitude}&lng=${detailData.longitude}&name=${detailData.name}`,
      });
    } else {
      Toast.show("请打开定位权限");
    }
  };
  setImgVisible = (val) => {
    this.setState({
      imgVisible: val,
    });
  };
  getImgVal = (val) => {
    this.setState(
      {
        imgUrl: val,
      },
      () => {
        this.setState({
          imgVisible: true,
        });
      }
    );
  };
  render() {
    const {
      backIcon,
      searchIcon,
      trumpet,
      informRight,

      openTime,
      telIcon,
      shopIcon,
      location,
      shopInfoRight,
      shopInfoDown,
      showAll,
      shopInfoUp,
      activeClass,
      detailData,
      comments,
      productsList,
      locationEnable,
      imgVisible,
      imgUrl,
    } = this.state;

    return (
      <div className="store-detail">
        <div className="header">
          <Swiper autoplay loop autoplayInterval="2000">
            {detailData && detailData.banner && detailData.banner.length ? (
              this.bannerSwiper(detailData.banner)
            ) : (
              <Swiper.Item>
                <div
                  className="swiper-content"
                  style={{
                    backgroundImage: `url(
                 https://jx-storage.oss-cn-beijing.aliyuncs.com/ae6fffd2e9f4a96bed9c139c12ba5dff.png
                  )`,
                  }}
                ></div>
              </Swiper.Item>
            )}
          </Swiper>
          <div className={activeClass}>
            <Image
              src={backIcon}
              alt=""
              className="back"
              onClick={() => this.closeBack()}
            />

            <div className="search-box">
              <p onClick={() => this.toSearch()}>
                店内搜索：商品/书名/作者/书号/出版社
              </p>
              {/* <input
                type="text"
                placeholder="输入书名/作者/书号/出版社搜索"
                onFocus={() => this.toSearch()}
              /> */}
              <Image src={searchIcon} alt="" className="searchButton" />
            </div>
          </div>
        </div>
        <div className="inform">
          <Image src={trumpet} className="trumpet" />
          <Swiper
            className="notice"
            direction="vertical"
            autoplay
            autoplayInterval="2000"
            allowTouchMove={false}
            loop
            style={{ "--height": "200px" }}
          >
            {detailData && detailData.notice ? (
              detailData.notice.map((item, idx) => {
                return (
                  <Swiper.Item
                    key={idx}
                    onClick={() => this.toNoticeDetail(item.target_url)}
                  >
                    <p> {item.content}</p>

                    <img src={informRight} className="informRight" />
                  </Swiper.Item>
                );
              })
            ) : (
              <Swiper.Item></Swiper.Item>
            )}
          </Swiper>
        </div>
        <div className="store-content">
          <div className="shop-about">
            <div className="shop-top">
              <div className="title-info">
                <p>{detailData?.name}</p>
                <div className="evaluate">
                  {this.stars(detailData?.comment_avg_score)}
                  <span> {detailData?.comment_count}条评价</span>
                </div>
                {detailData && detailData.label ? (
                  this.getShopFunctions(detailData.label)
                ) : (
                  <div></div>
                )}
              </div>
              {/* <div className="collect">
                <img src={collect} alt="" />
                <span>收藏</span>
              </div> */}
            </div>
            <div className="shop-bottom">
              <div>
                <div className="label">
                  <img src={openTime} alt="" />
                  <span>开放时间:</span>
                </div>
                <p>{detailData?.opening_time}</p>
                <span></span>
              </div>
              {detailData?.tel ? (
                <div>
                  <div className="label">
                    <img src={telIcon} alt="" />
                    <span>联系电话:</span>
                  </div>

                  {/* <p> */}
                  <a href={`tel:${detailData?.tel}`} className="tel">
                    <p>{detailData?.tel} </p>
                    <img src={shopInfoRight} alt="" className="arrow-right" />
                  </a>
                  {/* </p> */}
                </div>
              ) : null}

              <div>
                <div className="label">
                  <img src={location} alt="" />
                  <span>书店地址:</span>
                </div>

                <p onClick={() => this.toMapDetail()}>
                  {detailData?.address}
                  {locationEnable ? `(距离您${detailData.distance})` : null}
                </p>
                <img src={shopInfoRight} alt="" className="arrow-right" />
              </div>
              <div className={showAll ? "shop-brief showAll" : "shop-brief"}>
                <div className="brief-cont">
                  <div className="label">
                    <img src={shopIcon} alt="" />
                    <span>书店简介:</span>
                  </div>
                  <p className={showAll ? null : "hiddenText"}>
                    {detailData?.introduction}
                  </p>
                </div>

                <div
                  className="shop-brief-arrow "
                  onClick={() => this.showAll()}
                >
                  {showAll ? <span>收起</span> : null}

                  <img
                    src={showAll ? shopInfoUp : shopInfoDown}
                    alt=""
                    // className="arrow-down"
                  />
                </div>
              </div>
            </div>
          </div>
          {detailData && detailData.activity && detailData.activity.length ? (
            <div className="book-club">
              <div className="title">书友会</div>
              <div className="book-swiper">
                <Swiper autoplay loop autoplayInterval="2000">
                  {this.bannerSwiper(detailData.activity)}
                </Swiper>
              </div>
            </div>
          ) : null}
          {comments && comments.list && comments.list.data.length ? (
            <div className="comment-box">
              <div className="title">
                <span className="title-left">
                  评价
                  <em>
                    (
                    {comments && comments.stat
                      ? comments.stat.total >= 10000
                        ? `${(comments.stat.total / 10000).toFixed(1)}万`
                        : comments.stat.total
                      : null}
                    评价)
                  </em>
                </span>
                <em
                  className="content-detail"
                  onClick={() => this.toCommentDetail()}
                >
                  好评率
                  {comments && comments.stat && comments.stat.total > 0
                    ? Math.ceil(
                        (comments.stat.good / comments.stat.total) * 100
                      )
                    : 0}
                  %
                  <img src={shopInfoRight} alt="" className="arrow-right" />
                </em>
              </div>
              <ImageViewer
                image={imgUrl}
                visible={imgVisible}
                onClose={() => this.setImgVisible(false)}
              />
              {}
              <div className="comments-content">
                {comments &&
                  comments.list?.data.slice(0, 2).map((item) => {
                    return (
                      <div key={item.id}>
                        <div className="head">
                          <Image
                            src={
                              item.avatar ||
                              "https://jx-storage.oss-cn-beijing.aliyuncs.com/ae6fffd2e9f4a96bed9c139c12ba5dff.png"
                            }
                            alt=""
                            fit="cover"
                            className="head-photo"
                          />
                          <div className="nickname">
                            <span>{item.nickname}</span>
                            {this.stars(item.score)}
                          </div>
                        </div>
                        <div className="content">{item.content}</div>

                        <div
                          className={
                            item.attachments && item.attachments.length > 2
                              ? "many"
                              : "images"
                          }
                        >
                          {item.attachments
                            ? item.attachments.map((itm, idx) => {
                                return (
                                  <Image
                                    lazy
                                    src={itm.url}
                                    alt=""
                                    fit="cover"
                                    className="head-photo"
                                    key={idx}
                                    onClick={() => this.getImgVal(itm.url)}
                                  />
                                );
                              })
                            : null}
                        </div>
                        <p>{item.createdAt}</p>
                      </div>
                    );
                  })}

                <div className="more">
                  <span onClick={() => this.toCommentDetail()}>
                    更多评价
                    <img src={shopInfoRight} alt="" className="arrow-right" />
                  </span>
                </div>
              </div>
            </div>
          ) : null}

          {/* <div className="products-box">
            <div className="title">
              <span className="title-left">店铺商品</span>
              <em
                className="content-detail"
                onClick={() => this.toAllProducts()}
              >
                更多商品
                <img src={shopInfoRight} alt="" className="arrow-right" />
              </em>
            </div>
            <div className="products">
              {productsList &&
                productsList.list &&
                productsList.list.data?.map((item) => {
                  return (
                    <div
                      className="pro-item"
                      key={item.id}
                      onClick={() => this.toProductDetail(item.id)}
                    >
                      <Image
                        lazy
                        src={item.title_page_url}
                        alt=""
                        className="book_img"
                        fit="cover"
                      />
                      <p className="des">{item.name}</p>
                      <p className="price">
                        <span>
                          <em>¥</em>
                          {item.price}
                        </span>
                        <b>¥{item.discount_price}</b>
                      </p>
                      <button onClick={() => this.toProductDetail(item.id)}>
                        立即抢购
                      </button>
                    </div>
                  );
                })}

              <div className="more">
                <span onClick={() => this.toAllProducts()}>
                  查看全部商品
                  <img src={shopInfoRight} alt="" className="arrow-right" />
                </span>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

export default withRouter(StoreDetail);
