import React, { Component } from "react";
import "./mapContainer.scss";
import { withRouter } from "react-router-dom";
import getParam from "../../utils/getParam";
const AMap = window.AMap;
class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backIcon: require("../common/img/back-active.png"),
      location: require("./img/location.png"),
    };
    this.map = null;
  }
  componentDidMount() {
    const { location } = this.state;
    var lat = getParam(this.props.location.search, "lat");
    var lng = getParam(this.props.location.search, "lng");
    var name = getParam(this.props.location.search, "name");
    var layer = new AMap.TileLayer({
      zooms: [3, 20], //可见级别
      visible: true, //是否可见
      opacity: 1, //透明度
      zIndex: 0, //叠加层级
    });
    this.map = new AMap.Map("container", {
      layers: [layer],
      zoom: 15, //级别
      resizeEnable: true,
      center: [lng, lat], //中心点坐标
      viewMode: "2D", //使用3D视图
      isHotspot: true,
      features: ["point", "road", "building"],
    });
    // var trafficLayer = new AMap.TileLayer.Traffic({
    //   zIndex: 10,
    // });
    // this.map.add(trafficLayer); //添加图层到地图
    var marker = new AMap.Marker({
      position: [lng, lat],
      offset: new AMap.Pixel(-13, -30),
      icon: location,
    });
    // marker.setTitle(`${name}`);
    marker.setLabel({
      offset: new AMap.Pixel(10, 20), //设置文本标注偏移量
      content: `${name}`, //设置文本标注内容
      direction: "right", //设置文本标注方位
    });
    this.map.add(marker);
  }
  componentWillUnmount() {
    this.map.destroy();
  }
  goBack = () => {
    this.props.history.goBack();
  };
  render() {
    const { backIcon } = this.state;

    return (
      <div className="mapContainer">
        <div className="head">
          <img src={backIcon} alt="" onClick={() => this.goBack()} />
          <span>地图</span>
          <em></em>
        </div>
        <div id="container"></div>
      </div>
    );
  }
}

export default withRouter(MapContainer);
