import "./selectStoreAddress.scss";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import getParam from "../../utils/getParam";
import $https from "../../httpdata";
import { Image, Radio, Space, Toast } from "antd-mobile";
class SelectStoreaddress extends Component {
  constructor(props) {
    super(props);
    var addressDefault_id = Number(
      getParam(this.props.location.search, "addressDefault_id") ||
        getParam(this.props.location.search, "addressId")
    );
    this.state = {
      backIcon: require("../common/img/back-active.png"),
      rightIcon: require("../common/img/shop-info-right.png"),
      address: require("./img/address.png"),
      checkedIcon: require("./img/checked.png"),
      visible: false,
      setVisible: false,
      storeDetailData: null,
      addressList: [],
      toStoreChecked: addressDefault_id > 0 ? false : true, //到店自取false
      addressChecked: addressDefault_id > 0 ? addressDefault_id : 0, //地址选择列表
      addressDefault_id: addressDefault_id > 0 ? addressDefault_id : 0,
      station_id: null,
      locationEnable: false,
    };
  }
  componentDidMount() {
    // var navBar = getParam(this.props.location.search, "navBar");
    // Toast.show(navBar);
    // this.getStoreDetail();
    // this.getAddressList();
    let that = this;
    window.onCommonData = async function (res) {
      that.setState(
        {
          station_id: res.stationId,
          // goods_id: getParam(that.props.location.search, "goods_id"),
        },
        () => {
          that.getStoreDetail();
          that.getAddressList();
        }
      );
      // if (getParam(that.props.location.search, "station_id")) {
      //   that.setState(
      //     {
      //       station_id: getParam(that.props.location.search, "station_id"),
      //       goods_id: getParam(that.props.location.search, "goods_id"),
      //     },
      //     () => {
      //       that.getStoreDetail();
      //       that.getAddressList();
      //     }
      //   );
      // } else {
      //   that.setState(
      //     {
      //       station_id: res.stationId,
      //       goods_id: getParam(that.props.location.search, "goods_id"),
      //     },
      //     () => {
      //       that.getStoreDetail();
      //       that.getAddressList();
      //     }
      //   );
      // }
    };

    window.JXNative &&
      window.JXNative.postMessage &&
      window.JXNative.postMessage(
        JSON.stringify({
          action: "commonData",
        })
      );
    window.JXNative &&
      window.JXNative.postMessage &&
      window.JXNative.postMessage(
        JSON.stringify({
          action: "locationEnable",
        })
      );

    window.onLocationEnable = async function (res) {
      that.setState({
        locationEnable: res,
      });
    };
    // this.getStoreDetail();
    // this.getAddressList();
  }
  closeBack = () => {
    var navBar = getParam(this.props.location.search, "navBar");
    if (navBar !== null) {
      window.JXNative.postMessage(
        JSON.stringify({
          action: "closeWeb",
        })
      );
    } else {
      // this.props.history.goBack();
      // var goods_id = getParam(this.props.location.search, "goods_id");
      // var station_id = getParam(this.props.location.search, "station_id");
      var addressDefault_id = this.state.addressChecked;
      window.localStorage.setItem("addressDefault_id", addressDefault_id);
      this.props.history.goBack();
      // this.props.history.replace({
      //   pathname: "/productDetail",
      //   search: `station_id=${station_id}&goods_id=${goods_id}&addressDefault_id=${addressDefault_id}`,
      // });
    }
    // var goods_id = getParam(this.props.location.search, "goods_id");
    // var station_id = getParam(this.props.location.search, "station_id");
    // var addressDefault_id = this.state.addressChecked;
    // this.props.history.push({
    //   pathname: "/productDetail",
    //   search: `station_id=${station_id}&goods_id=${goods_id}&addressDefault_id=${addressDefault_id}`,
    // });
  };
  getStoreDetail = () => {
    let that = this;
    const { station_id } = this.state;

    // var station_id = getParam(this.props.location.search, "station_id");
    $https
      .get("/api/v1/station", {
        station_id,
      })
      .then((res) => {
        that.setState({ storeDetailData: res.data });
      });
  };
  getAddressList = () => {
    $https
      .get("/api/v1/user/address/search", {
        page_size: null,
      })
      .then((res) => {
        this.setState({ addressList: res.data.data });
      });
  };
  toStore = () => {
    this.setState(
      {
        toStoreChecked: true,
        addressChecked: 0,
      },
      () => {
        Toast.show({
          content: "已切换收货地址",
        });

        const { storeDetailData } = this.state;
        if (getParam(this.props.location.search, "type") === "select") {
          window.JXNative.postMessage(
            JSON.stringify({
              action: "closeWeb",
              data: {
                self_pick_up: true,
                station: storeDetailData,
              },
            })
          );
        } else {
          this.closeBack();
        }
      }
    );
  };
  toMapDetail = (e) => {
    e.stopPropagation();
    const { storeDetailData, locationEnable } = this.state;

    if (locationEnable) {
      this.props.history.push({
        pathname: `/mapContainer`,
        search: `lat=${storeDetailData.latitude}&lng=${storeDetailData.longitude}&name=${storeDetailData.name}`,
      });
    } else {
      Toast.show("请打开定位权限");
    }
  };
  getCheckedAddress = (val) => {
    this.setState(
      {
        toStoreChecked: false,
        addressChecked: val,
      },
      () => {
        Toast.show("已切换收货地址");
        const { addressList } = this.state;
        var address = addressList.filter((item) => item.id == val);

        if (getParam(this.props.location.search, "type") === "select") {
          window.JXNative.postMessage(
            JSON.stringify({
              action: "closeWeb",
              data: {
                self_pick_up: false,
                address: address[0],
              },
            })
          );
        } else {
          this.closeBack();
        }
      }
    );
  };
  toAddNewAddress = () => {
    // const { station_id } = this.state;
    this.props.history.push({
      pathname: "/newShippingAddress",
    });
  };
  render() {
    const {
      backIcon,
      rightIcon,
      storeDetailData,
      address,
      addressList,
      checkedIcon,
      toStoreChecked,
      addressChecked,
      addressDefault_id,
    } = this.state;

    return (
      <div className="SelectStoreaddress">
        <header>
          <Image src={backIcon} onClick={() => this.closeBack()} />
          <p>选择收货地址</p>
          <span></span>
        </header>
        <div className="select">
          <div
            className={toStoreChecked ? "cont active" : "cont"}
            onClick={() => this.toStore()}
          >
            <h1>{storeDetailData?.name}</h1>
            <div className="position">
              <p>{storeDetailData?.address}</p>
              <div className="distance" onClick={(e) => this.toMapDetail(e)}>
                <Image src={address} className="location" />
                {storeDetailData?.distance}
                <Image src={rightIcon} className="right" />
              </div>
            </div>
            <div className="time">
              <span>取货时间：</span>
              <p>{storeDetailData?.pickup_time}</p>
            </div>
            <em className="flag">到店自取</em>
          </div>
        </div>
        <div className="address-list">
          <Radio.Group
            defaultValue={addressDefault_id}
            value={addressChecked}
            onChange={(val) => this.getCheckedAddress(val)}
          >
            <Space direction="vertical">
              {addressList && addressList.length
                ? addressList.map((item, idx) => {
                    return (
                      <Radio
                        value={item.id}
                        key={idx}
                        icon={(checked) =>
                          checked ? <Image src={checkedIcon} /> : <span></span>
                        }
                      >
                        <div>
                          <p className="address">
                            {item.city_name}
                            {item.country_name}
                            {item.detail}
                          </p>
                          <p className="info">
                            <span>{item.receiver_name}</span>
                            <span className="phone">{item.receiver_phone}</span>
                          </p>
                        </div>
                      </Radio>
                    );
                  })
                : null}
            </Space>
          </Radio.Group>
        </div>

        <div className="footer">
          <button onClick={() => this.toAddNewAddress()}>+ 添加新地址</button>
        </div>
      </div>
    );
  }
}
export default withRouter(SelectStoreaddress);
