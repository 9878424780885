import React, { Component } from "react";
// import axios from "../ajax";
import "./borrow.scss";
import { withRouter } from "react-router-dom";
import { Image, Swiper, Toast, ImageViewer } from "antd-mobile";
import $https from "../../../httpdata";
import getParam from "../../../utils/getParam";
import Dialog from "../../../components/dialog/dialog";
import DialogTip from "../../../components/dialogTip/dialogTip";
class Borrow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backIcon: require("../../common/img/back-active.png"),
      titleIcon: require("./img/titleIcon.png"),
      borrowIcon: require("./img/borrowIcon.png"),
      defaultAvatar: require("./img/defaultAvatar.png"),
      infoRight: require("../../common/img/shop-info-right.png"),
      borrowingState: require("./img/borrowing.png"),
      waitBorrow: require("./img/waitBorrow.png"),
      verified: false,
      order_sn: "",
      user_id: "",
      borrow_sn: "",
      user: null,
      checkVisible: false,
      imgShow: false,
      product: null,
      borrow_card: null,
      stationId: "",
      imgVisible: false,
      imgUrl: "",
      imgsResult: [],
      visibleTip: false,
      cont1: "",
      cont2: "",
      originalStoreData: null,
    };
  }
  componentDidMount() {
    // this.setState(
    //   {
    //     order_sn: getParam(this.props.location.search, "order_sn"),
    //     user_id: getParam(this.props.location.search, "user_id"),
    //     borrow_sn: getParam(this.props.location.search, "borrow_sn"),
    //   },
    //   () => {
    //     this.getDetail();
    //   }
    // );

    let that = this;

    window.onCommonData = async function (res) {
      that.setState(
        {
          stationId:
            getParam(that.props.location.search, "station_id") || res.stationId,
          order_sn: getParam(that.props.location.search, "order_sn"),
          user_id: getParam(that.props.location.search, "user_id"),
          borrow_sn: getParam(that.props.location.search, "borrow_sn"),
        },
        () => {
          that.getDetail();
          that.getStoreDetail();
        }
      );
    };

    window.JXNative &&
      window.JXNative.postMessage &&
      window.JXNative.postMessage(
        JSON.stringify({
          action: "commonData",
        })
      );
  }
  getDetail() {
    const { order_sn, user_id, borrow_sn } = this.state;
    $https
      .get("/api/staff/v1/order/borrow/product", {
        order_sn,
        user_id,
        borrow_sn,
      })
      .then((res) => {
        this.setState(
          {
            verified:
              res.data.product.status == 0
                ? false
                : res.data.product.status == 3
                ? true
                : null,
            user: res.data.user,
            product: res.data.product,
            borrow_card: res.data.borrow_card,
          },
          () => {
            const { product } = this.state;
            if (
              product &&
              product.borrow_images.length &&
              product.borrow_images
            ) {
              var result = [];
              for (var i = 0; i < product.borrow_images.length; i += 3) {
                result.push(product.borrow_images.slice(i, i + 3));
              }
              this.setState({
                imgsResult: result,
              });
            }
          }
        );
      });
  }
  borrowCheck = () => {
    const { order_sn, user_id, borrow_sn } = this.state;
    $https
      .post("/api/staff/v1/order/borrow/check", {
        order_sn,
        user_id,
        borrow_sn,
      })
      .then((res) => {
        this.getDetail();
        this.setCheckVisible(false);
        if (res.msg == "success") {
          Toast.show({
            content: "核验通过",
          });
        } else {
          Toast.show({
            content: res.msg,
          });
        }
      });
  };
  getStoreDetail = () => {
    const { stationId } = this.state;
    $https
      .get("/api/staff/v1/station", {
        station_id: stationId,
      })
      .then((res) => {
        this.setState({ originalStoreData: res.data });
      });
  };
  judgeDialog = () => {
    const { stationId, product, originalStoreData } = this.state;

    var id = product && product.borrow_station && product.borrow_station.id;

    if (stationId !== id) {
      this.setState(
        {
          cont2: `借阅门店：${
            product && product.borrow_station && product.borrow_station.name
          }`,
          cont1: `核验门店：${originalStoreData && originalStoreData.name}`,
        },
        () => {
          this.setState({
            visibleTip: true,
          });
        }
      );
    } else {
      this.setState({
        checkVisible: true,
      });
    }
  };
  goBack = () => {
    window.JXNative.postMessage(
      JSON.stringify({
        action: "closeWeb",
      })
    );
  };
  setCheckVisible = (val) => {
    this.setState({
      checkVisible: val,
    });
  };
  setCloseVisible = (val) => {
    this.setState({
      visibleTip: val,
    });
  };
  toBookDetail = () => {
    const { product } = this.state;
    this.props.history.push({
      pathname: `/productDetail`,
      search: `goods_id=${product.product.id}&from=staff`,
    });
  };

  setImgVisible = (val) => {
    this.setState({
      imgVisible: val,
    });
  };
  getImgVal = (val) => {
    this.setState(
      {
        imgUrl: val,
      },
      () => {
        this.setState({
          imgVisible: true,
        });
      }
    );
  };
  render() {
    const {
      backIcon,
      titleIcon,
      borrowIcon,
      defaultAvatar,
      infoRight,
      borrowingState,
      verified,
      waitBorrow,
      user,
      checkVisible,
      product,
      borrow_card,
      imgVisible,
      imgUrl,

      imgsResult,
      visibleTip,
      cont1,
      cont2,
    } = this.state;

    return (
      <div className="borrow">
        <header>
          <Image src={backIcon} onClick={() => this.goBack()} />
          <span>借书详情</span>
          <span></span>
        </header>
        {verified ? (
          <div className="verified-info">
            <h1>
              <Image src={titleIcon} />
              <span>核验信息</span>
            </h1>
            <div className="cont">
              <Image src={borrowIcon} />
              <div className="info">
                <p>核验时间：{product && product.deliver_time} </p>
                <p>借阅门店：{product && product.borrow_station.name}</p>
                <p>核验人员：{product && product.deliver_staff}</p>
              </div>
            </div>
          </div>
        ) : null}
        <div className="borrow-info">
          <h1>
            <Image src={titleIcon} />
            <span>借阅信息</span>
          </h1>
          <div className="cont">
            <div className="avatar">
              <Image src={user && user.avatar ? user.avatar : defaultAvatar} />
              {user?.mobile}
              <span>{borrow_card && borrow_card.name}</span>
            </div>
            <div className="card-info">
              <p>订单号：{product && product.order_sn}</p>

              <p>借阅时间：{product && product.create_time}</p>
            </div>
            <ul className="list">
              <li>
                <p>
                  <span>{borrow_card?.used_num + borrow_card?.return_num}</span>
                  本
                </p>
                <em>累计借阅</em>
              </li>
              <li>
                <p>
                  <span>{borrow_card?.used_num}</span>本
                </p>
                <em>借阅中</em>
              </li>
              <li>
                <p>
                  <span className="red">{borrow_card?.overdue_times}</span>次
                </p>
                <em>累计超期</em>
              </li>
              <li>
                <p>
                  <span>{borrow_card?.return_num}</span>本
                </p>
                <em>累计归还</em>
              </li>
            </ul>
          </div>
        </div>
        <div className="book-info">
          <h1>
            <Image src={titleIcon} />
            <span>书籍信息</span>
          </h1>
          <div className="cont">
            <div className="pic">
              <Image
                src={
                  product && product.product && product.product.title_page_url
                }
                fit="cover"
              />
            </div>
            <div className="book" onClick={() => this.toBookDetail()}>
              <h1 className="title">
                <span>
                  {product && product.product && product.product.name}
                </span>

                <Image src={verified ? borrowingState : waitBorrow} />
              </h1>
              <p className="price">
                定价：¥
                {product && product.product && product.product.output_price}
              </p>
              <div className="book-infos">
                <div className="detail">
                  <div className="p">
                    <span>
                      作者：
                      {product && product.product && product.product.writer}
                    </span>
                  </div>
                  <p>
                    出版社：
                    {product && product.product && product.product.pub_name}
                  </p>
                  <p>
                    ISBN：{product && product.product && product.product.isbn}
                  </p>
                  <p>
                    出版时间：
                    {product && product.product && product.product.publish_date}
                  </p>
                </div>
                <Image src={infoRight} className="todetail" />
              </div>
            </div>
          </div>
        </div>
        {product &&
        product.borrow_images &&
        product.borrow_images.length >= 1 ? (
          <div className="book-pics">
            <h1>
              <Image src={titleIcon} />
              <span>
                书籍照片（
                {product &&
                  product.borrow_images &&
                  product.borrow_images.length}
                张）
              </span>
            </h1>

            <ImageViewer
              image={imgUrl}
              visible={imgVisible}
              onClose={() => this.setImgVisible(false)}
            />
            <Swiper
              slideSize={215}
              trackOffset={0}
              stuckAtBoundary={false}
              style={{
                "--track-padding": " 0 0 40px",
              }}
            >
              {imgsResult.length &&
                imgsResult.map((item, index) => {
                  return (
                    <Swiper.Item key={index}>
                      {item.map((itm, idx) => {
                        return (
                          <Image
                            key={idx}
                            src={itm}
                            onClick={() => this.getImgVal(itm)}
                            fit="cover"
                          />
                        );
                      })}
                    </Swiper.Item>
                  );
                })}
            </Swiper>
          </div>
        ) : null}

        <div className="btn">
          {verified ? (
            <span onClick={() => this.goBack()}>下一本</span>
          ) : (
            <div className="unverifiedBtn">
              <span className="cancel" onClick={() => this.goBack()}>
                取消借阅
              </span>
              <span onClick={() => this.judgeDialog()}>借阅核验通过</span>
            </div>
          )}
        </div>
        <Dialog
          visible={checkVisible}
          sure={this.borrowCheck}
          setCheckVisible={this.setCheckVisible}
        ></Dialog>
        <DialogTip
          visibleTip={visibleTip}
          cont1={cont1}
          cont2={cont2}
          setCloseVisible={this.setCloseVisible}
        ></DialogTip>
      </div>
    );
  }
}

export default withRouter(Borrow);
