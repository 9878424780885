import "./productDetail.scss";
import React, { Component, createRef } from "react";
import { withRouter } from "react-router-dom";
import { Image, Toast, ImageViewer } from "antd-mobile";
import $https from "../../httpdata";
import getParam from "../../utils/getParam";
import Countdown from "../../components/Countdown";

class ProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countdown: require("./img/countdown.png"),
      collectActive: require("../common/img/collect-active.png"),
      collect: require("../common/img/collect.png"),
      shopInfoRight: require("../common/img/shop-info-right.png"),
      change: require("./img/change.png"),
      shop: require("./img/shop.png"),
      kefu: require("./img/kefu.png"),
      connect: require("./img/connect.png"),
      mall: require("./img/mall.png"),
      addCart: require("./img/addCart.png"),
      buy: require("./img/buy.png"),
      notlogRight: require("./img/notlog-right.png"),
      vip: require("./img/v.png"),
      discountRight: require("./img/discount-right.png"),
      warning: require("./img/warning.png"),
      changeShop: require("./img/change-shop.png"),
      backIcon: require("./img/back-white.png"),
      backIcon1: require("../common/img/back-active.png"),
      solidStar: require("../common/img/solid-star.png"),
      hollowStar: require("../common/img/hollow-star.png"),
      halfStar: require("../common/img/half-star.png"),
      detailData: {},
      storeDetailData: {},
      comments: {},
      addressDefault: {},
      activity: null,
      has_collect: null,
      access_token: "",
      station_id: null,
      goods_id: null,
      celingTopShow: false,

      celingArr: [
        {
          title: "商品",
          href: "#product",
        },
        {
          title: "店铺",
          href: "#store",
        },
        {
          title: "评价",
          href: "#comment",
        },
      ],
      celingActive: 0,
      productExtend: {},
      cartSummary: {},
      loading: "",
      hiddenBtn: false,
      max_discount: "",
      locationEnable: null, //是否有定位权限
      shopping_cart: false,
      shelf_ids: [],
      latitude: "",
      longitude: "",
      imgVisible: false,
      imgUrl: "",
      is_mall: null,
    };

    this.storeRef = createRef();
    this.commentRef = createRef();
    this.productRef = createRef();
  }
  componentDidMount() {
    let that = this;
    // console.log(getParam(that.props.location.search, "from"));
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    // that.setState(
    //   {
    //     // station_id: res.stationId,
    //     goods_id: getParam(that.props.location.search, "goods_id"),
    //     shopping_cart:
    //       getParam(that.props.location.search, "shoppingCart") == "true"
    //         ? true
    //         : false,
    //     hiddenBtn:
    //       getParam(that.props.location.search, "from") &&
    //       getParam(that.props.location.search, "from") !== "null"
    //         ? true
    //         : false,
    //     // access_token: window.localStorage.getItem("access_token"),
    //   },
    //   () => {
    //     that.getProductDetail();
    //     that.getStoreDetail();
    //     that.getComments();
    //     that.getDefaultAddress();
    //     that.getProductExtend();
    //     that.getCart();
    //   }
    // );
    window.JXNative &&
      window.JXNative.postMessage &&
      window.JXNative.postMessage(
        JSON.stringify({
          action: "gpsLocation",
        })
      );
    window.onGpsLocation = async function (result) {
      that.setState(
        {
          latitude: result.latitude,
          longitude: result.longitude,
        },
        () => {
          that.getStoreDetail();
        }
      );
    };
    window.onCommonData = async function (res) {
      that.setState(
        {
          station_id:
            getParam(that.props.location.search, "station_id") || res.stationId,
          goods_id: getParam(that.props.location.search, "goods_id"),
          shopping_cart:
            getParam(that.props.location.search, "shoppingCart") == "true"
              ? true
              : false,
          hiddenBtn:
            getParam(that.props.location.search, "from") &&
            getParam(that.props.location.search, "from") !== "null"
              ? true
              : false,
          access_token: res.token,
        },
        () => {
          that.getProductDetail();
          that.getComments();
          that.getStoreDetail();
          // that.getDefaultAddress();
          that.getProductExtend();
          that.getCart();
          that.getDefaultAdd();
        }
      );
      if (getParam(this.props.location.search, "shoppingCart")) {
        this.setState({
          celingArr: [
            {
              title: "商品",
              href: "#product",
            },
            {
              title: "评价",
              href: "#comment",
            },
          ],
        });
      } else {
        this.setState({
          celingArr: [
            {
              title: "商品",
              href: "#product",
            },
            {
              title: "店铺",
              href: "#store",
            },
            {
              title: "评价",
              href: "#comment",
            },
          ],
        });
      }
    };
    // document.addEventListener("visibilitychange", function () {
    //   /*ie10+  moz  webkit  默认*/
    //   if (!document["hidden"]) {
    //     that.getProductDetail();
    //     that.getStoreDetail();
    //     that.getComments();
    //     that.getDefaultAddress();
    //     that.getProductExtend();
    //     that.getCart();
    //   }
    // });

    window.onscroll = (e) => {
      this.handleScroll(e);
    };
    window.onReVisible = () => {
      let that = this;

      that.getProductDetail();
      that.getStoreDetail();
      that.getComments();
      // that.getDefaultAddress();
      that.getProductExtend();
      that.getCart();
      that.getDefaultAdd();
    };
    window.onChangeShop = (res) => {
      if (res.shopId && res.shopId != "null") {
        that.setState(
          {
            station_id: res.shopId,
          },
          () => {
            that.fn();
          }
        );
      } else {
        that.fn();
      }
    };
    window.onAddToCart = async function (res) {
      that.setState({ cartSummary: res.data.summary });
      Toast.show("已加入");
    };

    window.JXNative &&
      window.JXNative.postMessage &&
      window.JXNative.postMessage(
        JSON.stringify({
          action: "commonData",
        })
      );
    window.JXNative &&
      window.JXNative.postMessage &&
      window.JXNative.postMessage(
        JSON.stringify({
          action: "locationEnable",
        })
      );

    window.onLocationEnable = async function (res) {
      that.setState({
        locationEnable: res,
      });
    };
  }

  componentWillUnmount() {
    window.onscroll = null;
  }
  fn = () => {
    this.getProductDetail();
    this.getStoreDetail();
    this.getComments();
    // this.getDefaultAddress();
    this.getProductExtend();
    this.getCart();
    this.getDefaultAdd();
  };
  handleScroll = (e) => {
    let offetTop = this.storeRef.current.getBoundingClientRect().top - 140;
    this.setState({
      celingTopShow: offetTop <= 0,
    });

    if (
      this.storeRef.current.offsetTop <
        document.documentElement.scrollTop + 140 &&
      document.documentElement.scrollTop + 140 <
        this.commentRef.current.offsetTop
    ) {
      this.setState({
        celingActive: 1,
      });
    } else if (
      this.commentRef.current.offsetTop <
      document.documentElement.scrollTop + 140
    ) {
      this.setState({
        celingActive: 2,
      });
    } else if (
      document.documentElement.scrollTop + 140 >
      this.productRef.current.offsetTop
    ) {
      this.setState({
        celingActive: 0,
      });
    }
  };
  toCelingActive = (idx) => {
    this.setState({
      celingActive: idx,
    });
  };
  stars = (num) => {
    var newNum = num / 10;
    var intNum = Math.trunc(newNum);
    var littleNum = newNum - intNum;

    const { solidStar, hollowStar, halfStar } = this.state;
    var str = [];
    for (let i = 0; i < 5; i++) {
      if (i < intNum) {
        str.push(<img src={solidStar} alt="" />);
      } else if (i === intNum && littleNum > 0) {
        str.push(<img src={halfStar} alt="" />);
      } else {
        str.push(<img src={hollowStar} alt="" />);
      }
    }
    return <div className="stars"> {str} </div>;
  };

  closeBack = () => {
    // this.props.history.push({
    //   pathname: "/storeDetail",
    // });
    var navBar = getParam(this.props.location.search, "navBar");

    if (navBar !== null) {
      window.JXNative.postMessage(
        JSON.stringify({
          action: "closeWeb",
        })
      );
    } else {
      // this.props.history.goBack();
      this.props.history.go(-1);
    }
  };
  toCommentDetail = () => {
    const { access_token } = this.state;
    let url;
    if (getParam(this.props.location.search, "from")) {
      url = `goods_id=${this.state.goods_id}&from=staff`;
    } else {
      url = `goods_id=${this.state.goods_id}`;
    }
    if (access_token) {
      this.props.history.push({
        pathname: `/storeCommentDetail`,
        search: url,
      });
    } else {
      this.toLogin();
    }

    // var paramObj = parseQueryString(window.location.search);
    // paramObj.station_id = "123";
    // var searchStr = "";
    // for (var key in paramObj) {
    //   searchStr += `${key}=${paramObj[key]}&`;
    // }
    // var newStr = searchStr.substring(0, searchStr.length - 1);

    // this.props.history.push({
    //   pathname: this.props.location.pathname,
    //   search: newStr,
    // });
    // this.getProductDetail();
    // this.getStoreDetail();
    // this.getComments();
    // this.getDefaultAddress();
  };
  getProductDetail() {
    const { station_id, goods_id, hiddenBtn } = this.state;
    $https
      .get(`/api${hiddenBtn ? "/staff" : ""}/v1/goods/detail`, {
        station_id,
        goods_id,
      })
      .then((res) => {
        this.setState(
          {
            detailData: res.data && res.data.goods,
            activity: res.data && res.data.activity,
            has_collect: res.data && res.data.has_collect,
            max_discount: res.data && res.data.max_discount,
            is_mall: res.data && res.data.goods && res.data.goods.is_mall,
          },
          () => {
            const { detailData } = this.state;
            if (detailData && detailData.shelf_id) {
              if (detailData.shelf_id.indexOf(",") !== -1) {
                var shelf_ids = detailData.shelf_id.split(",");
                this.setState({
                  shelf_ids,
                });
              } else {
                this.setState({
                  shelf_ids: [detailData.shelf_id],
                });
              }
            }
          }
        );
      });
  }
  getProductExtend() {
    const { station_id, goods_id, hiddenBtn } = this.state;

    $https
      .get(`/api${hiddenBtn ? "/staff" : ""}/v1/goods/extend`, {
        station_id,
        goods_id,
      })
      .then((res) => {
        this.setState({
          productExtend: res.data,
        });
      });
  }
  getStoreDetail = () => {
    const { station_id, hiddenBtn, latitude, longitude } = this.state;
    $https
      .get(`/api${hiddenBtn ? "/staff" : ""}/v1/station`, {
        station_id,
        latitude,
        longitude,
      })
      .then((res) => {
        this.setState({ storeDetailData: res.data });
      });
  };
  getComments = () => {
    const { goods_id, hiddenBtn } = this.state;
    $https
      .get(`/api${hiddenBtn ? "/staff" : ""}/v1/goods/comments`, {
        goods_id,
        page_size: 6,
      })
      .then((res) => {
        this.setState({ comments: res.data });
      });
  };
  getCart = () => {
    const { station_id, hiddenBtn } = this.state;
    if (hiddenBtn) return;
    $https
      .get(`/api/v1/cart/summary`, {
        station_id,
        bucket: "",
      })
      .then((res) => {
        this.setState({ cartSummary: res.data && res.data.summary });
      });
  };
  changeStore = () => {
    const { access_token, goods_id } = this.state;
    if (access_token) {
      window.JXNative.postMessage(
        JSON.stringify({
          action: "changeShop",
          from: "select_temp",
          product_id: goods_id,
        })
      );
    } else {
      this.toLogin();
    }
  };
  getDefaultAdd = () => {
    $https.get(`/api/v1/user/address/default`).then((res) => {
      this.setState({ addressDefault: res.data });
    });
  };
  // getDefaultAddress = () => {
  //   const { hiddenBtn } = this.state;
  //   if (hiddenBtn) return;
  //   $https.get(`/api/v1/user/address/default`).then((res) => {
  //     this.setState({ addressDefault: res.data });
  //   });
  //   var addressDefault_id = window.localStorage.getItem("addressDefault_id");
  //   if (Number(addressDefault_id) === 0) {
  //     this.setState({ addressDefault: null });
  //     return;
  //   }

  //   if (addressDefault_id) {
  //     $https
  //       .get(`/api/v1/user/address/detail`, {
  //         id: Number(addressDefault_id),
  //       })
  //       .then((res) => {
  //         this.setState({ addressDefault: res.data });
  //       });
  //   } else {
  //     this.setState({ addressDefault: null });
  //   }
  // };
  // toSelectAddress = () => {
  //   const { station_id, goods_id, access_token, hiddenBtn } = this.state;
  //   if (hiddenBtn) return;
  //   if (access_token) {
  //     var addressDefault_id = getParam(
  //       this.props.location.search,
  //       "addressDefault_id"
  //     );
  //     if (addressDefault_id === 0) {
  //       this.props.history.push({
  //         pathname: "/selectStoreAddress",
  //         search: `station_id=${station_id}&goods_id=${goods_id}&addressDefault_id=0`,
  //       });
  //     } else {
  //       var id;
  //       if (this.state.addressDefault && this.state.addressDefault.id) {
  //         id = this.state.addressDefault.id;
  //       } else {
  //         id = null;
  //       }

  //       this.props.history.push({
  //         pathname: "/selectStoreAddress",
  //         search: `station_id=${station_id}&goods_id=${goods_id}&addressDefault_id=${id}`,
  //       });
  //     }
  //   } else {
  //     this.toLogin();
  //   }
  // };
  toCollect = (has_collect) => {
    const { goods_id, access_token } = this.state;
    if (access_token) {
      $https
        .post(`/api/v1/goods/collect`, {
          goods_id,
          cancel: has_collect,
        })
        .then((res) => {
          if (has_collect) {
            Toast.show("已取消收藏");
          } else {
            Toast.show("收藏成功");
          }
          this.setState({
            has_collect: !has_collect,
          });
        });
    } else {
      this.toLogin();
    }
  };
  toStoreDetail = () => {
    const { station_id } = this.state;
    this.props.history.push({
      pathname: `/storeDetail`,
      // search: `id=CL01`,
      search: `id=${station_id}`,
    });
  };
  addCartNative = () => {
    const {
      goods_id,
      addressDefault,
      storeDetailData,
      access_token,
      detailData,
    } = this.state;
    if (access_token) {
      window.JXNative.postMessage(
        JSON.stringify({
          action: "addToCart",
          productId: goods_id,
          selfPickUp: addressDefault && addressDefault.id ? false : true,
          addressId: (addressDefault && addressDefault.id) || 0,
          station: storeDetailData,
          activityId: detailData && detailData.activity_id,
        })
      );
    } else {
      this.toLogin();
    }
  };

  buyPreview = () => {
    const { goods_id, station_id, access_token, detailData } = this.state;
    if (access_token) {
      window.JXNative.postMessage(
        JSON.stringify({
          action: "pushPage",
          page: "/orderConfirm",
          goodsOrderParams: {
            self_pick_up: false,
            address_id: 0,
            station_id: station_id,
            bucket: "default",
            products: [
              {
                product_id: goods_id,
                product_num: 1,
                product_type: 1,
                activity_id: detailData && detailData.activity_id,
                rewards: null,
              },
            ],
            not_use_coupon: false,
          },
        })
      );
    } else {
      this.toLogin();
    }
  };
  toCartDetail = () => {
    const { access_token, station_id } = this.state;
    if (access_token) {
      window.JXNative.postMessage(
        JSON.stringify({
          action: "pushPage",
          page: "/cart",
          stationId: station_id,
        })
      );
    } else {
      this.toLogin();
    }
  };
  toAllProducts = (key) => {
    const { station_id, storeDetailData, access_token } = this.state;

    if (access_token) {
      window.JXNative.postMessage(
        JSON.stringify({
          action: "pushPage",
          page: `/search`,
          stationId: station_id,
          station: storeDetailData,
          key,
          from: "h5",
        })
      );
    } else {
      this.toLogin();
    }

    // stationId: station_id,
    // window.JXNative.postMessage(
    //   JSON.stringify({
    //     action: "pushPage",
    //     page: `/shop`,
    //     station_id: station_id,
    //     station_name: storeDetailData.name,
    //     allow_other: false,
    //   })
    // );
  };
  membershipCard = () => {
    const { access_token } = this.state;
    if (access_token) {
      this.props.history.push({
        pathname: `/membershipCard`,
      });
    } else {
      this.toLogin();
    }
  };
  toLogin = () => {
    Toast.show({
      content: "请登录",
      afterClose: () => {
        window.JXNative.postMessage(
          JSON.stringify({
            action: "pushPage",
            page: "/loginHelper",
          })
        );
      },
    });
  };
  toChat = () => {
    const { storeDetailData } = this.state;
    window.JXNative.postMessage(
      JSON.stringify({
        action: "openServiceChat",
        stationId: storeDetailData?.id,
      })
    );
  };
  setImgVisible = (val) => {
    this.setState({
      imgVisible: val,
    });
  };
  getImgVal = (val) => {
    this.setState(
      {
        imgUrl: val,
      },
      () => {
        this.setState({
          imgVisible: true,
        });
      }
    );
  };
  render() {
    const {
      countdown,
      collect,
      collectActive,
      shopInfoRight,
      change,
      shop,
      kefu,
      connect,
      mall,
      notlogRight,
      discountRight,
      warning,
      changeShop,
      backIcon,
      vip,
      detailData,
      storeDetailData,
      comments,
      addressDefault,
      activity,
      has_collect,
      access_token,
      celingArr,
      celingActive,
      celingTopShow,
      backIcon1,
      productExtend,
      cartSummary,
      hiddenBtn,
      max_discount,
      locationEnable,
      shopping_cart,
      shelf_ids,
      imgVisible,
      imgUrl,
      is_mall,
    } = this.state;

    var directory1 = "",
      content1 = "",
      writer_summary1 = "",
      market_desc1 = "";
    if (productExtend) {
      if (productExtend.directory) {
        directory1 = productExtend.directory.replace(/\r|\n/g, "<br/>");
      }
      if (productExtend.content) {
        content1 = productExtend.content.replace(/\r|\n/g, "<br/>");
      }
      if (productExtend.writer_summary) {
        writer_summary1 = productExtend.writer_summary.replace(
          /\r|\n/g,
          "<br/>"
        );
      }
      if (productExtend.market_desc) {
        market_desc1 = productExtend.market_desc.replace(/\r|\n/g, "<br/>");
      }
    }

    return (
      <div className="product-detail" id="product" ref={this.productRef}>
        <header>
          {/* <img
            src={
              detailData &&
              detailData.title_page_url &&
              detailData.title_page_url
            }
            alt=""
          /> */}
          <Image
            lazy
            src={
              detailData && detailData.title_page_url
                ? detailData && detailData.title_page_url
                : "https://jx-storage.oss-cn-beijing.aliyuncs.com/ae6fffd2e9f4a96bed9c139c12ba5dff.png"
            }
            alt=""
            className="head-photo"
            fit="cover"
          />
          {/* <Swiper autoplay>{items}</Swiper> */}
          {/* <div className="cover"></div> */}
          <div className="goback">
            <Image
              src={backIcon}
              alt=""
              className="back"
              onClick={() => this.closeBack()}
            />
          </div>
        </header>
        <div className="product-des">
          <div className="des">
            <div className="price">
              <div className="current-price">
                会员价 ：
                {access_token ? (
                  <span>
                    <em>¥</em>
                    {detailData?.discount_price}
                    {detailData?.discount == 1 ? null : (
                      <b>
                        [
                        {detailData && detailData.discount
                          ? (detailData.discount * 10).toFixed(1)
                          : ""}
                        折]
                      </b>
                    )}
                  </span>
                ) : (
                  <span>?</span>
                )}
              </div>
              <div className="original-price">
                价 格：¥{detailData?.output_price}
              </div>
            </div>
            {activity && access_token && activity.e_time && activity.s_time ? (
              <div className="endtime">
                <Image src={countdown} />

                <div className="time">
                  <span>距活动结束</span>
                  <Countdown
                    e_time={activity.e_time}
                    s_time={activity.s_time}
                  />
                </div>
              </div>
            ) : null}
            {access_token ? null : (
              <div className="notLog" onClick={() => this.toLogin()}>
                登录享会员优惠价 <Image src={notlogRight} />
              </div>
            )}
          </div>
          {access_token ? null : (
            <div className="discount" onClick={() => this.membershipCard()}>
              <Image src={vip} alt="" className="vip" />
              <p>
                开通会员最高享{(max_discount * 10).toFixed(1)}折，本单会员优惠价
                <span>
                  ¥{(detailData?.output_price * max_discount).toFixed(2)}
                </span>
              </p>
              <Image src={discountRight} alt="" className="discount-right" />
            </div>
          )}

          <div className="title">
            <div className="name">{detailData?.name}</div>

            {!(hiddenBtn || shopping_cart) ? (
              <div
                className="collect"
                onClick={() => this.toCollect(has_collect)}
              >
                <Image src={has_collect ? collectActive : collect} alt="" />
                <span>收藏</span>
              </div>
            ) : null}
          </div>
          {detailData?.is_book ? (
            <div className="product-bottom">
              <div>
                <p>ISBN：{detailData?.isbn}</p>
              </div>
              <div>
                <p>作者：{detailData?.writer}</p>
                {!hiddenBtn ? (
                  <div
                    onClick={() =>
                      this.toAllProducts(`作者:${detailData.writer}`)
                    }
                  >
                    <span>更多作品</span>
                    <Image src={shopInfoRight} alt="" className="arrow-right" />
                  </div>
                ) : null}
              </div>
              <div>
                <p> 出版社：{detailData?.pub_name}</p>
                {!hiddenBtn ? (
                  <div
                    onClick={() =>
                      this.toAllProducts(`出版社:${detailData.pub_name}`)
                    }
                  >
                    <span>更多作品</span>
                    <Image src={shopInfoRight} alt="" className="arrow-right" />
                  </div>
                ) : null}
              </div>
            </div>
          ) : (
            <div className="gift-pro">
              <div>
                <span>分类： </span>
                <p>{detailData?.type_name}</p>
              </div>
              <div>
                <span>规格：</span>
                <p>{detailData?.standard}</p>
              </div>
              <div>
                <span>材质：</span>
                <p>{detailData?.media}</p>
              </div>
              <div>
                <span> 重量：</span>
                <p>{detailData?.weight}</p>
              </div>
            </div>
          )}
        </div>

        <div className={celingTopShow ? "ceiling" : "ceiling ceil-hidden"}>
          <img
            src={backIcon1}
            alt=""
            className="back"
            onClick={() => this.closeBack()}
          />
          <ul>
            {celingArr.map((item, idx) => {
              return (
                <li
                  key={idx}
                  className={celingActive === idx ? "active" : null}
                  onClick={() => this.toCelingActive(idx)}
                >
                  <a href={item.href}>{item.title}</a>
                </li>
              );
            })}
          </ul>
        </div>

        {!shopping_cart ? (
          <div className="stores-distribution" id="store" ref={this.storeRef}>
            <div className="title">店铺及配送</div>
            {/* {!hiddenBtn ? (
              <div className="address">
                <span>送至</span>
                <div onClick={() => this.toSelectAddress()}>
                  {addressDefault && addressDefault?.city_name ? (
                    <p>
                      {addressDefault?.city_name}
                      {addressDefault?.country_name}
                      {addressDefault?.detail}
                    </p>
                  ) : (
                    <p>
                      {storeDetailData && storeDetailData.address
                        ? storeDetailData.address
                        : "点击添加收货地址"}
                    </p>
                  )}

                  <Image src={shopInfoRight} alt="" className="arrow-right" />
                </div>
              </div>
            ) : null} */}

            <div className="shop">
              <span className="label">店铺</span>
              <div className="shop-name">
                <div className="name-title">
                  {storeDetailData?.name}
                  {!hiddenBtn ? (
                    <Image
                      src={change}
                      alt=""
                      className="change"
                      onClick={() => this.changeStore()}
                    />
                  ) : null}
                </div>

                <div className="evaluate">
                  {this.stars(storeDetailData?.comment_avg_score)}
                  <span> {storeDetailData?.comment_count}条评价</span>
                </div>
              </div>
              {!hiddenBtn ? (
                <div className="shop-icon">
                  <div onClick={() => this.toStoreDetail()}>
                    <Image src={shop} alt="" />
                    <span>店铺</span>
                  </div>
                  <div onClick={() => this.toChat()}>
                    <Image src={kefu} alt="" />
                    <span>客服</span>
                  </div>
                </div>
              ) : null}
            </div>
            {!hiddenBtn && detailData.freight ? (
              <div className="freight">
                <span>运费</span>
                <p>{detailData?.freight}</p>
                <span></span>
              </div>
            ) : null}
            <div className="position">
              <span>位置</span>
              <p>
                {locationEnable ? <em>{storeDetailData?.distance}</em> : ""}
                {storeDetailData?.address}
              </p>
            </div>
            <div className="postcard">
              <Image
                lazy
                src={detailData?.title_page_url}
                alt=""
                className="book_img"
                fit="cover"
              />
              <div className="val">
                <div className="book_title">{detailData?.name}</div>
                {detailData?.stock_amount > 0 ? (
                  <div>
                    <div className="name">
                      <span>所在书架：</span>
                      <div>
                        {shelf_ids &&
                          shelf_ids.length &&
                          shelf_ids.map((item, idx) => {
                            return <p key={idx}>{item}</p>;
                          })}
                      </div>
                    </div>
                    <div>
                      <span>库存：</span>
                      {detailData?.stock_amount}
                    </div>
                  </div>
                ) : (
                  <div className="none-gift">
                    <div className="warning">
                      <Image src={warning} alt="" />
                      当前店铺无货
                    </div>
                    {!hiddenBtn ? (
                      <div className="change-shop">
                        <Image
                          src={changeShop}
                          alt=""
                          fit="cover"
                          onClick={() => this.changeStore()}
                        />
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : null}

        <div className="comment-box" id="comment" ref={this.commentRef}>
          <div className="title">
            <span className="title-left">
              评价
              <em>
                (
                {comments && comments.stat
                  ? comments.stat.total >= 10000
                    ? `${(comments.stat.total / 10000).toFixed(1)}万`
                    : comments.stat.total
                  : null}
                评价)
              </em>
            </span>
            <em
              className="content-detail"
              onClick={() => this.toCommentDetail()}
            >
              好评率
              {comments && comments.stat && comments.stat.total > 0
                ? Math.ceil((comments.stat.good / comments.stat.total) * 100)
                : 0}
              %
              <img src={shopInfoRight} alt="" className="arrow-right" />
            </em>
          </div>
          <ImageViewer
            image={imgUrl}
            visible={imgVisible}
            onClose={() => this.setImgVisible(false)}
          />
          <div className="comments-content">
            {comments &&
              comments.list?.data.slice(0, 2).map((item) => {
                return (
                  <div key={item.id}>
                    <div className="head">
                      <Image
                        src={
                          item.avatar ||
                          "https://jx-storage.oss-cn-beijing.aliyuncs.com/ae6fffd2e9f4a96bed9c139c12ba5dff.png"
                        }
                        alt=""
                        fit="cover"
                        className="head-photo"
                      />
                      <div className="nickname">
                        <span>{item.nickname}</span>
                        {this.stars(item.score)}
                      </div>
                    </div>
                    <div className="content">{item.content}</div>
                    <div
                      className={
                        item.attachments.length > 2 ? "many" : "images"
                      }
                    >
                      {item.attachments
                        ? item.attachments.map((itm, idx) => {
                            return (
                              <Image
                                lazy
                                src={itm.url}
                                alt=""
                                className="head-photo"
                                key={idx}
                                fit="cover"
                                onClick={() => this.getImgVal(itm.url)}
                              />
                            );
                          })
                        : null}
                    </div>
                    <p>{item.created_at}</p>
                  </div>
                );
              })}

            <div className="more">
              <span onClick={() => this.toCommentDetail()}>
                更多评价
                <img src={shopInfoRight} alt="" className="arrow-right" />
              </span>
            </div>
          </div>
        </div>
        <div className="goods-detail">
          <div className="title">商品详情</div>
          {content1 ? (
            <div>
              <h1>
                <span>内容提要</span>
              </h1>
              <p
                dangerouslySetInnerHTML={{
                  __html: content1,
                }}
              ></p>
            </div>
          ) : null}
          {writer_summary1 ? (
            <div>
              <h1>
                <span>作者简介</span>
              </h1>
              <p
                dangerouslySetInnerHTML={{
                  __html: writer_summary1,
                }}
              ></p>
            </div>
          ) : null}
          {market_desc1 ? (
            <div>
              <h1>
                <span>市场描述</span>
              </h1>
              <p
                dangerouslySetInnerHTML={{
                  __html: market_desc1,
                }}
              ></p>
            </div>
          ) : null}
          {directory1 ? (
            <div>
              <h1>
                <span>目录</span>
              </h1>
              <p
                dangerouslySetInnerHTML={{
                  __html: directory1,
                }}
              >
                {/* {productExtend.directory} */}
              </p>
            </div>
          ) : null}

          {/* <p>{productExtend.directory}</p> */}
          {productExtend && productExtend.wonderful_page ? (
            <div>
              <h1>
                <span>精彩页</span>
              </h1>
              <p
                dangerouslySetInnerHTML={{
                  __html: productExtend && productExtend.wonderful_page,
                }}
              ></p>
            </div>
          ) : null}
          {productExtend && productExtend.title_page_url ? (
            <div>
              <h1>
                <span>详情</span>
              </h1>
              <img src={productExtend && productExtend.title_page_url} alt="" />
            </div>
          ) : null}
        </div>
        {!(hiddenBtn || shopping_cart) && is_mall ? (
          <div className="shop-cart">
            <div className="cart-left">
              <div className="connect" onClick={() => this.toChat()}>
                <Image src={connect} />

                <span>客服</span>
              </div>
              <div className="mall" onClick={() => this.toCartDetail()}>
                <Image src={mall} />
                <span>购物车</span>
                {cartSummary && cartSummary.total_amount > 0 ? (
                  <em className="count">{cartSummary.total_amount}</em>
                ) : null}
              </div>
            </div>
            {detailData?.stock_amount > 0 ? (
              <div className="card-right">
                <span className="addCart" onClick={() => this.addCartNative()}>
                  加入购物车
                </span>
                <span className="buy" onClick={() => this.buyPreview()}>
                  我要购买
                </span>
              </div>
            ) : (
              <div className="card-right">
                <span className="stockout">缺货</span>
              </div>
            )}
          </div>
        ) : null}
      </div>
    );
  }
}
export default withRouter(ProductDetail);
