import React, { Component } from "react";
import $https from "../../httpdata";
import "./index.scss";

class SmsAreasCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sms_areas: [],
    };
  }

  componentDidMount() {
    this.getConfig();
  }

  getConfig = () => {
    $https
      .get("/api/v1/configs")
      .then((res) => {
        if (res && res.data && res.data.sms_areas) {
          this.setState({
            sms_areas: res.data.sms_areas,
          });
        }
      });
  }

  render() {
    const { onClose, onSelect } = this.props;
    const { sms_areas } = this.state;
    return (
      <div className="smsCode-wrap">
        <div className="smsCode-list">
          <div className="smsCode-title"><span className="smsCode-close" onClick={onClose}></span>选择国家及地区</div>
          <ul>
            {sms_areas.map((item, index) => {
              return (
                <li key={index} onClick={() => onSelect(item.code)}>
                  <span>{item.name}</span>
                  <strong>+{item.code}</strong>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    );
  }
}
export default SmsAreasCode;
