import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Toast } from "antd-mobile";
import $https from "../../../httpdata";
import "./index.scss";

class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backIcon: require("../../common/img/back-active.png"),
      page: 1,
      page_size: 6,
      last_page: null,
      invoiceList: null,
      invoiceDetail: null,
    };
  }

  componentDidMount() {
    const that = this;
    this.searchInvoice();

    window.onscroll = function () {
      var pageNum = that.state.page;
      const { last_page } = that.state;
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollTop + windowHeight >= scrollHeight) {
        if (!last_page) {
          that.setState({
            loadingFlag: true,
          });
          return;
        }
        if (pageNum < last_page) {
          pageNum = pageNum + 1;

          that.setState({
            page: pageNum,
            loadingFlag: true,
          }, () => {
            that.searchInvoice();
          });
        } else {
          that.setState({
            loadingFlag: false,
          });
        }

        //考虑到滚动的位置一般可能会大于一点可滚动的高度，所以这里不能用等于
      }
    };
  }

  componentWillUnmount() { }

  searchInvoice = () => {
    const { page_size, page } = this.state;
    $https
      .get("/api/v1/user/invoice/search", {
        page_size: page_size,
        page,
      })
      .then((res) => {
        if (res && res.data && res.data.data) {
          var arr = this.state.invoiceList ? this.state.invoiceList : [];
          var newArr = [...arr, ...res.data.data];
          this.setState({
            last_page: res.data.last_page,
            invoiceList: newArr,
            loadingFlag: false,
          });
        } else {
          Toast.show((res && res.msg) || "数据异常！");
        }
      });
  }

  goToDetail = (e, id) => {
    this.props.history.push({
      pathname: `/invoice/${id}`,
    });
  }

  goToProduct = (goods_id, station_id) => {
    this.props.history.push({
      pathname: "/productDetail",
      search: `?goods_id=${goods_id}&station_id=${station_id}`
    });
  }

  goAppPage = (page) => {
    window.JXNative.postMessage(
      JSON.stringify({
        action: "pushPage",
        page
      })
    );
  }

  goBack = () => {
    window.JXNative.postMessage(
      JSON.stringify({
        action: "closeWeb",
      })
    );
  }

  render() {
    const { backIcon, invoiceList } = this.state;

    return (
      <div className="mine-collect mine-invoice">
        <header>
          <div className="head">
            <img src={backIcon} alt="" onClick={() => this.goBack()} />
            <span>我的发票</span>
            <em onClick={() => { this.goAppPage("/invoiceTitleListPage") }}>发票抬头</em>
          </div>
        </header>
        {invoiceList ?
          <div className="list">
            <div className="invoice-tips">如需开票，请至【订单】中申请，已确认收货订单可开票</div>
            {invoiceList.length === 0 ?
              <div className="invoice-none">暂无发票！</div>
              :
              <ul className="invoice-list">
                {invoiceList.map((item, index) => {
                  return (<li key={index}>
                    <div className="invoice-order-info">
                      <div className="invoice-name">
                        <div><em></em><strong>{item.order.station && item.order.station.name ? item.order.station.name : ""}</strong></div>
                        <p>{item.invoice.status_text}</p>
                      </div>
                      <div className={`product-list product-list-${item.order.products.length > 1 ? "more" : "1"}`}>
                        {item.order.products.map((product, index1) => {
                          return (
                            <div key={index1} className="product-item" onClick={() => { this.goToProduct(product.product_id, item.order.station.id) }}>
                              <p className="product-img" style={{ "backgroundImage": `url(${product.product.title_page_url})` }}></p>
                              <p className="product-name">{product.product.name}</p>
                            </div>
                          )
                        })}
                        {item.order.products.length > 1 ? <div className="product-num">共{item.order.products.length}件</div> : null}
                      </div>
                    </div>
                    <div className="invoice-order-bottom" onClick={(e) => { this.goToDetail(e, item.invoice.id) }}>
                      <div>{item.invoice.created_at}<p>抬头：{item.invoice.title}</p></div>
                      <p>￥<strong>{item.invoice.amount}</strong><span></span></p>
                    </div>
                  </li>)
                })}
              </ul>
            }
          </div>
          : <div className="invoice-none">加载中...</div>}
      </div>
    );
  }
}
export default withRouter(Invoice);
