export default function (url) {
  // 编写代码实现
  // 1.查找?的下标
  var index = url.indexOf("?");
  // 2.截取?后面的字符串
  var str = url.slice(index + 1);
  // 3.根据&将字符串转成数组
  var arr = str.split("&");

  // 4. 声明对象用来存储信息
  var obj = {};
  for (var i = 0; i < arr.length; i++) {
    // arr[0] action=home
    var arr2 = arr[i].split("=");
    // arr2的第一个成员做属性, 第二个成员做值
    obj[arr2[0]] = arr2[1];
  }
  return obj;
}
