import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { Mask } from "antd-mobile";
import "./dialogTip.scss";
class Dialog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  sure = () => {
    this.props.sure();
  };
  render() {
    const { visibleTip, setCloseVisible, cont1, cont2 } = this.props;
    // var title1 = title || "确认核验";
    // var cont1 = cont || "请仔细核验，确认要提交吗？";
    return (
      <Mask
        visible={visibleTip}
        onMaskClick={() => setCloseVisible(false)}
        className="dialogTip"
      >
        <div className="common-dialog1">
          <h1>非本店订单</h1>
          <p>{cont1}</p>
          <p>{cont2}</p>
          <p>请引导用户前往正确的门店。</p>
          <div className="btns">
            <span onClick={() => setCloseVisible(false)}>关闭</span>
          </div>
        </div>
      </Mask>
    );
  }
}
export default withRouter(Dialog);
