import React, { Component } from "react";
import "./shop.scss";
import { withRouter } from "react-router-dom";
import { Image, Toast } from "antd-mobile";
import $https from "../../../httpdata";
import getParam from "../../../utils/getParam";
import Dialog from "../../../components/dialog/dialog";
import DialogTip from "../../../components/dialogTip/dialogTip";
class ReturnBook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backIcon: require("../../common/img/back-active.png"),
      titleIcon: require("./img/titleIcon.png"),
      borrowIcon: require("./img/borrowIcon.png"),
      defaultAvatar: require("./img/defaultAvatar.png"),
      infoRight: require("../../common/img/shop-info-right.png"),
      alreadyReturn: require("./img/alreadyReturn.png"),
      borrowing: require("./img/borrowing.png"),
      verified: null,
      crossStore: require("./img/crossStore.png"),
      order_sn: "",
      detailData: {},
      checkVisible: false,
      user_id: "",
      visibleTip: false,
      stationId: "",
      cont1: "",
      cont2: "",
      originalStoreData: null,
      btnClass: false,
    };
  }
  componentDidMount() {
    let that = this;

    window.onCommonData = async function (res) {
      that.setState(
        {
          stationId: res.stationId,
        },
        () => {
          that.getStoreDetail();
        }
      );
    };

    window.JXNative &&
      window.JXNative.postMessage &&
      window.JXNative.postMessage(
        JSON.stringify({
          action: "commonData",
        })
      );
    this.setState(
      {
        order_sn: getParam(this.props.location.search, "order_sn"),
        user_id: getParam(this.props.location.search, "user_id"),
      },
      () => {
        this.getDetail();
      }
    );
  }
  getDetail() {
    const { order_sn, user_id } = this.state;
    $https
      .get("/api/staff/v1/order/shop/detail", {
        order_sn,
        user_id,
      })
      .then((res) => {
        var verified;
        if (res.data.summary.order_status == "WAIT_BUYER_CONFIRM_GOODS") {
          verified = false;
        } else if (res.data.summary.order_status == "TRADE_FINISHED") {
          verified = true;
        }
        this.setState(
          {
            verified,
            detailData: res.data,
          },
          () => {
            console.log(
              document.body.getElementsByClassName("buyDetail")[0].offsetHeight,
              document.body.clientHeight
            );
            if (
              document.body.getElementsByClassName("buyDetail")[0]
                .offsetHeight < document.body.clientHeight
            ) {
              this.setState({
                btnClass: true,
              });
            }
          }
        );
      });
  }
  borrowCheck = () => {
    const { order_sn, user_id, stationId } = this.state;
    $https
      .post("/api/staff/v1/order/shop/check", {
        order_sn,
        user_id,
        station_id: stationId,
      })
      .then((res) => {
        this.getDetail();
        this.setCheckVisible(false);
        if (res.msg == "success") {
          Toast.show({
            content: "核验通过",
          });
        } else {
          Toast.show({
            content: res.msg,
          });
        }
      });
  };
  goBack = () => {
    window.JXNative.postMessage(
      JSON.stringify({
        action: "closeWeb",
      })
    );
  };
  setCheckVisible = (val) => {
    this.setState({
      checkVisible: val,
    });
  };
  setCloseVisible = (val) => {
    this.setState({
      visibleTip: val,
    });
  };
  toBookDetail = (id) => {
    this.props.history.push({
      pathname: `/productDetail`,
      search: `goods_id=${id}&from=staff`,
    });
  };
  judgeDialog = () => {
    const { stationId, detailData, originalStoreData } = this.state;

    var id = detailData && detailData.station && detailData.station.id;

    if (stationId !== id) {
      this.setState(
        {
          cont2: `购买门店：${
            detailData && detailData.station && detailData.station.name
          }`,
          cont1: `核验门店：${originalStoreData && originalStoreData.name}`,
        },
        () => {
          this.setState({
            visibleTip: true,
          });
        }
      );
    } else {
      this.setState({
        checkVisible: true,
      });
    }
  };
  getStoreDetail = () => {
    const { stationId } = this.state;
    $https
      .get("/api/staff/v1/station", {
        station_id: stationId,
      })
      .then((res) => {
        this.setState({ originalStoreData: res.data });
      });
  };
  render() {
    const {
      backIcon,
      titleIcon,
      borrowIcon,
      defaultAvatar,
      infoRight,
      verified,
      visibleTip,
      detailData,
      checkVisible,
      cont1,
      cont2,
      btnClass,
    } = this.state;

    return (
      <div className="shopBuyContainer">
        <div className="buyDetail">
          <header>
            <Image src={backIcon} onClick={() => this.goBack()} />
            <span>购物详情</span>
            <span></span>
          </header>
          {verified ? (
            <div className="verified-info">
              <h1>
                <Image src={titleIcon} />
                <span>核验信息</span>
              </h1>
              <div className="cont">
                <Image src={borrowIcon} fit="cover" />
                <div className="info">
                  <p>核验时间： {detailData && detailData.check_time} </p>
                  <p>
                    购买门店：
                    {detailData &&
                      detailData.station &&
                      detailData.station.name}
                  </p>
                  <p>核验人员： {detailData && detailData.check_staff} </p>
                </div>
              </div>
            </div>
          ) : null}
          <div className="borrow-info">
            <h1>
              <Image src={titleIcon} />
              <span>订单信息</span>
            </h1>
            <div className="cont">
              <div className="avatar">
                <Image src={defaultAvatar} fit="cover" />
                {detailData && detailData.mobile}
                <span>
                  {detailData &&
                    detailData.member_card &&
                    detailData.member_card.name.split("--")[0]}
                </span>
              </div>
              <div className="card-info">
                <p>
                  订单号：
                  {detailData &&
                    detailData.summary &&
                    detailData.summary.order_sn}
                </p>
                <p>
                  购买时间：
                  {detailData &&
                    detailData.summary &&
                    detailData.summary.create_time}
                </p>
              </div>
              <ul className="list">
                <li>
                  <p>
                    实付金额：
                    <span className="red">
                      ¥
                      {detailData &&
                        detailData.summary &&
                        detailData.summary.final_money}
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    商品售价：
                    <span className="red">
                      ¥
                      {detailData &&
                        detailData.summary &&
                        detailData.summary.total_money}
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    优惠合计：
                    <span>
                      ¥
                      {detailData &&
                        detailData.summary &&
                        detailData.summary.reduce_money}
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    会员优惠：
                    <span>
                      ¥
                      {detailData &&
                        detailData.summary &&
                        detailData.summary.member_reduce}
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    会员券抵扣：
                    <span>
                      ¥
                      {detailData &&
                        detailData.summary &&
                        detailData.summary.coupon_value}
                    </span>
                  </p>
                </li>

                <li>
                  <p>
                    支付方式：
                    <span>
                      {detailData &&
                      detailData.payments &&
                      detailData.payments.length
                        ? detailData.payments[0].method
                        : ""}
                    </span>
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="book-info">
            <h1>
              <Image src={titleIcon} fit="cover" />
              <span>商品信息</span>
            </h1>
            <div className="cont">
              {detailData && detailData.products && detailData.products.length
                ? detailData.products.map((item, idx) => {
                    return (
                      <div
                        className="item"
                        key={idx}
                        onClick={() => this.toBookDetail(item.product.id)}
                      >
                        <div className="pic">
                          <Image
                            src={item.product.title_page_url}
                            fit="cover"
                          />
                        </div>
                        <div className="book">
                          <h1 className="title">{item.product.name}</h1>
                          <p className="price">
                            <span>
                              <em>¥</em>
                              {item.product.discount_price}
                            </span>
                            <span>¥{item.product.output_price}</span>
                          </p>
                          <div className="book-infos">
                            <div className="detail">
                              <p>库 存：{item.product.stock_amount}个</p>
                              <p>货架号：{item.product.shelf_id}</p>
                            </div>
                            <Image src={infoRight} className="todetail" />
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>

          <div className={btnClass ? "btn fixed" : "btn"}>
            {btnClass}
            {verified ? (
              <span onClick={() => this.goBack()}>下一个</span>
            ) : (
              <div className="unverifiedBtn">
                <span className="cancel" onClick={() => this.goBack()}>
                  取消核验
                </span>
                <span onClick={() => this.judgeDialog()}>购买核验通过</span>
              </div>
            )}
          </div>
          <Dialog
            visible={checkVisible}
            sure={this.borrowCheck}
            setCheckVisible={this.setCheckVisible}
          ></Dialog>
          <DialogTip
            visibleTip={visibleTip}
            cont1={cont1}
            cont2={cont2}
            setCloseVisible={this.setCloseVisible}
          ></DialogTip>
        </div>
      </div>
    );
  }
}

export default withRouter(ReturnBook);
