import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Toast } from "antd-mobile";
import $https from "../../../httpdata";
import getParam from "../../../utils/getParam";
import "./index.scss";

class InvoiceDetail extends Component {
  constructor(props) {
    super(props);
    const { match, location } = props;
    this.invoiceId = match.params.id;
    this.needClose = getParam(location.search, "needClose");
    this.state = {
      backIcon: require("../../common/img/back.png"),
      invoiceDetail: null,
    };
  }

  componentDidMount() {
    this.getInvoiceDetail(this.invoiceId);
  }

  getInvoiceDetail = (id) => {
    $https
      .get("/api/v1/user/invoice/detail", {
        id
      })
      .then((res) => {
        if (res && res.data) {
          this.setState({
            invoiceDetail: res.data,
          });
        } else {
          Toast.show((res && res.msg) || "数据异常！");
        }
      });
  }

  doShare = () => {
    const { invoiceDetail } = this.state;
    window.JXNative.postMessage(
      JSON.stringify({
        action: "share",
        data: {
          shareType: 0,
          session: 0,
          link: invoiceDetail.invoice.pdf_url,
          title: invoiceDetail.invoice.title + "的发票",
          desc: "",
          imageUrl: "",
        }
      })
    );
  }

  doView = () => {
    const { invoiceDetail } = this.state;
    window.JXNative.postMessage(
      JSON.stringify({
        action: "openWeb",
        url: invoiceDetail.invoice.pdf_url
      })
    );
  }

  goToProduct = (goods_id, station_id) => {
    this.props.history.push({
      pathname: "/productDetail",
      search: `?goods_id=${goods_id}&station_id=${station_id}`
    });
  }

  goBack = () => {
    if (this.needClose) {
      window.JXNative.postMessage(
        JSON.stringify({
          action: "closeWeb",
        })
      );
    } else {
      this.props.history.go(-1);
    }
  }

  render() {
    const { backIcon, invoiceDetail } = this.state;

    return (
      <div className="mine-collect mine-invoice-detail">
        <header>
          <div className="head">
            <img src={backIcon} alt="" onClick={() => this.goBack()} />
            <span>发票详情</span>
            <em></em>
          </div>
        </header>
        {invoiceDetail ?
          <div className="list">
            <div className="invoice-status">
              <div className="invoice-status-left">
                <span className={invoiceDetail.invoice.status === 3 ? "invoice-ed" : "invoice-ing"}></span>
                <p>
                  <strong>{invoiceDetail.invoice.status_text}</strong><br />
                  {invoiceDetail.invoice.status === 3 ?
                    <>开票金额：￥<em>{invoiceDetail.invoice.amount}</em></>
                    : <>一般需3~7个工作日</>
                  }
                </p>
              </div>
              <div className={`invoice-status-right ${invoiceDetail.invoice.status === 3 ? "invoice-ed" : "invoice-ing"}`}></div>
            </div>
            <div className="invoice-info">
              <h3>普通电子发票</h3>
              <p>发票抬头：<em>{invoiceDetail.invoice.title}</em></p>
              {invoiceDetail.invoice.title_type === 2 ?
                <p>税　　号：<em>{invoiceDetail.invoice.tax_number}</em></p>
                : null
              }
              <p>发票金额：<em>￥{invoiceDetail.invoice.amount}</em></p>
              <p>发票内容：<em>明细</em></p>
              <p>申请时间：<em>{invoiceDetail.invoice.created_at}</em></p>
            </div>
            {invoiceDetail.invoice.status === 3 ?
              <>
                <div className="invoice-order">
                  <div className="invoice-order-title">{invoiceDetail.invoice.status_text}</div>
                  <div className="invoice-order-info">
                    <div className="invoice-name">
                      <div><em></em><strong>{invoiceDetail.order.station && invoiceDetail.order.station.name ? invoiceDetail.order.station.name : ""}</strong></div>
                    </div>
                    <div className={`product-list product-list-${invoiceDetail.order.products.length > 1 ? "more" : "1"}`}>
                      {invoiceDetail.order.products.map((product, index1) => {
                        return (
                          <div key={index1} className="product-item" onClick={() => { this.goToProduct(product.product_id, invoiceDetail.order.station.id) }}>
                            <p className="product-img" style={{ "backgroundImage": `url(${product.product.title_page_url})` }}></p>
                            <p className="product-name">{product.product.name}</p>
                          </div>
                        )
                      })}
                      {invoiceDetail.order.products.length > 1 ? <div className="product-num">共{invoiceDetail.order.products.length}件</div> : null}
                    </div>
                    <div className="invoice-time">下单时间　{invoiceDetail.order.summary.create_time}</div>
                  </div>
                </div>
                <div className="invoice-button">
                  {invoiceDetail.invoice.pdf_url ?
                    <>
                      <span onClick={() => { this.doShare() }}>微信分享</span>
                      <span className="green" onClick={() => { this.doView() }}>查看发票</span>
                    </>
                    : null}
                </div>
              </> : null}
          </div>
          : <div className="invoice-none">加载中...</div>}
      </div>
    );
  }
}
export default withRouter(InvoiceDetail);
