import StoreDetail from "./pages/storeDetail/storeDetail";
import StoreCommentDetail from "./pages/storeCommentDetail/storeCommentDetail";
import ProductDetail from "./pages/productDetail/productDetail";
import NewShippingAddress from "./pages/newShippingAddress/newShippingAddress";
import SelectStoreAddress from "./pages/selectStoreAddress/selectStoreAddress";
import MineAddressList from "./pages/mine/mineAddressList/mineAddressList";
import Intr from "./pages/intr";
import MemberPoints from "./pages/mine/memberPoints/memberPoints";
import Collect from "./pages/mine/collect/collect";
import BrowsingHistory from "./pages/mine/browsingHistory/browsingHistory";
import Evaluate from "./pages/mine/evaluate/evaluate";
import Deposit from "./pages/mine/deposit";
import Invoice from "./pages/mine/invoice";
import InvoiceDetail from "./pages/mine/invoice/detail";
import Wallet from "./pages/mine/wallet";
import WalletDetails from "./pages/mine/wallet/details";
import WalletPassword from "./pages/mine/wallet/password";
import WalletCode from "./pages/mine/wallet/code";
import WalletRecharge from "./pages/mine/wallet/recharge";
import MembershipCard from "./pages/mine/membershipCard/membershipCard";
import MembershipCardDetail from "./pages/mine/membershipCardDetail/membershipCardDetail";
import Borrow from "./pages/employeeSide/borrow/borrow";
import ReturnBook from "./pages/employeeSide/returnBook/returnBook";
import Shop from "./pages/employeeSide/shop/shop";
import NoAccess from "./pages/employeeSide/noAccess/noAccess";
import IncomeAndRank from "./pages/employeeSide/incomeAndRank/incomeAndRank";
import MapContainer from "./pages/mapContainer/mapContainer";
import Order from "./pages/order/order";
// import Index from "./index/index";
// import Search from "./search/search";
// import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import { createHashHistory } from "history";
// const history = createHashHistory();

// import React, { useState } from "react";
// import { render } from 'react-dom';
// import { ConfigProvider, DatePicker, message } from 'antd';
// 由于 antd 组件的默认文案是英文，所以需要修改为中文
// import zhCN from "antd/es/locale/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";
// import "antd/dist/antd.css";
import "./index.css";

moment.locale("zh-cn");
class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route path="/storeDetail" exact component={StoreDetail}></Route>
            <Route
              path="/storeCommentDetail"
              exact
              component={StoreCommentDetail}
            ></Route>
            <Route
              path="/productDetail"
              exact
              component={ProductDetail}
            ></Route>
            <Route
              path="/newShippingAddress"
              exact
              component={NewShippingAddress}
            ></Route>
            <Route
              path="/selectStoreAddress"
              exact
              component={SelectStoreAddress}
            ></Route>
            <Route path="/intr" exact component={Intr}></Route>
            <Route path="/memberPoints" exact component={MemberPoints}></Route>
            <Route path="/collect" exact component={Collect}></Route>
            <Route
              path="/browsingHistory"
              exact
              component={BrowsingHistory}
            ></Route>
            <Route path="/evaluate" exact component={Evaluate}></Route>
            <Route path="/deposit" exact component={Deposit}></Route>
            <Route path="/invoice" exact component={Invoice}></Route>
            <Route path="/invoice/:id" exact component={InvoiceDetail}></Route>
            <Route path="/wallet" exact component={Wallet}></Route>
            <Route
              path="/wallet/details"
              exact
              component={WalletDetails}
            ></Route>
            <Route
              path="/wallet/password"
              exact
              component={WalletPassword}
            ></Route>
            <Route path="/wallet/code" exact component={WalletCode}></Route>
            <Route
              path="/wallet/recharge/:id"
              exact
              component={WalletRecharge}
            ></Route>
            <Route
              path="/membershipCard"
              exact
              component={MembershipCard}
            ></Route>
            <Route
              path="/membershipCardDetail"
              exact
              component={MembershipCardDetail}
            ></Route>

            <Route path="/borrow" exact component={Borrow}></Route>
            <Route path="/returnBook" exact component={ReturnBook}></Route>
            <Route path="/shop" exact component={Shop}></Route>
            <Route path="/noAccess" exact component={NoAccess}></Route>
            <Route
              path="/incomeAndRank"
              exact
              component={IncomeAndRank}
            ></Route>
            <Route
              path="/mineAddressList"
              exact
              component={MineAddressList}
            ></Route>
            <Route path="/mapContainer" exact component={MapContainer}></Route>
            <Route path="/order" exact component={Order}></Route>
            {/* <Route path="/index" component={Index}></Route>
            <Route path="/search" component={Search}></Route> */}
            <Route path="/" component={Intr}></Route>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
