import React, { Component } from "react";
import "./noAccess.scss";
import { withRouter } from "react-router-dom";
import { Image, Mask } from "antd-mobile";

class NoAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailData: null,
      visible: true,
      close: require("../../common/img/close.png"),
      jsc: require("./img/jiashicang.png"),
    };
  }
  componentDidMount() {}
  setVisible = (val) => {
    this.setState({
      visible: val,
    });
  };
  //   toSearch = () => {
  //     var station_id = getParam(this.props.location.search, "id");
  //     const { detailData } = this.state;
  //     window.JXNative.postMessage(
  //       JSON.stringify({
  //         action: "pushPage",
  //         page: `/search`,
  //         stationId: station_id,
  //         station: detailData,
  //         from: "h5",
  //       })
  //     );
  //   };

  render() {
    const { visible, close, jsc } = this.state;

    return (
      <div className="noAccess">
        <Mask visible={visible} onMaskClick={() => this.setVisible(false)}>
          <div className="dialog">
            <div className="close">
              <Image
                lazy
                src={close}
                alt=""
                onClick={() => this.setVisible(false)}
                // key={idx}
              />
            </div>
            <div className="cont">
              <Image lazy src={jsc} alt="" />
              <p>抱歉，您暂时未开通该功能权限</p>
              <h3>请先联系管理员开通</h3>
            </div>
          </div>
        </Mask>
      </div>
    );
  }
}

export default withRouter(NoAccess);
