import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Toast } from "antd-mobile";
import $https from "../../../httpdata";
import getParam from "../../../utils/getParam";
import "./index.scss";

class Wallet extends Component {
  constructor(props) {
    super(props);
    const { match, location } = props;
    this.rechargeType = +match.params.id;
    this.needClose = getParam(location.search, "needClose");
    this.stationId = getParam(location.search, "station_id");
    this.locking = false;
    this.state = {
      backIcon: require("../../common/img/back-active.png"),
      cardPassword: "",
      rechargeMoney: 200,
    };
  }

  componentDidMount() {
    const that = this;
    window.onPaySuccess = function (res) {
      that.goToPage("/wallet");
    };
  }

  goToPage = (url) => {
    const { location } = this.props;
    let _search = location.search;
    this.props.history.push({
      pathname: url,
      search: _search,
    });
  };

  handleInputCardPassword = (e) => {
    const { cardPassword } = this.state;
    const value = e.target.value;
    if (value.length < cardPassword.length) {
      // 删除
      this.setState({
        cardPassword: value,
      });
    } else {
      this.setState({
        cardPassword: value.replace(/\s/g, "").replace(/(.{4})/g, "$1 "),
      });
    }
  };

  chargeCard = () => {
    const { cardPassword } = this.state;
    if (cardPassword) {
      if (this.locking) return;
      this.locking = true;
      $https
        .post("/api/v1/wallet/charge/card", {
          password: cardPassword.replace(/\s/g, ""),
        })
        .then((res) => {
          if (res && res.code === 0) {
            this.setState(
              {
                cardPassword: "",
              },
              () => {
                Toast.show("充值成功！");
                this.goToPage("/wallet");
                this.locking = false;
              }
            );
          } else {
            Toast.show((res && res.msg) || "充值失败！");
            this.locking = false;
          }
        });
    }
  };

  changeMoney = (e) => {
    const value = +e.target.value;
    this.selectMoney(value);
  };

  selectMoney = (money) => {
    this.setState({
      rechargeMoney: money,
    });
  };

  createOrder = () => {
    const { rechargeMoney } = this.state;
    if (rechargeMoney) {
      if (this.locking) return;
      this.locking = true;
      $https
        .post("/api/v1/wallet/charge/order", {
          amount: rechargeMoney,
          station_id: this.stationId || "-1",
        })
        .then((res) => {
          if (res && res.code === 0 && res.data) {
            window.JXNative.postMessage(
              JSON.stringify({
                action: "pushPage",
                page: "/orderToPay",
                data: res.data,
                stationId: "",
              })
            );
            this.locking = false;
          } else {
            Toast.show((res && res.msg) || "创建订单失败！");
            this.locking = false;
          }
        });
    }
  };

  goBack = () => {
    if (this.needClose) {
      window.JXNative.postMessage(
        JSON.stringify({
          action: "closeWeb",
        })
      );
    } else {
      this.props.history.go(-1);
    }
  };

  render() {
    const { backIcon, cardPassword, rechargeMoney } = this.state;

    return (
      <div className="mine-collect mine-wallet">
        <header>
          <div className="head">
            <img src={backIcon} alt="" onClick={() => this.goBack()} />
            <span>充值</span>
            <em></em>
          </div>
        </header>
        {this.rechargeType === 1 ? (
          <div className="wallet-recharge wallet-recharge-1">
            <div className="recharge-1-top">
              <h3>充值金额</h3>
              <ul>
                <li
                  className={rechargeMoney === 200 ? "current" : ""}
                  onClick={() => {
                    this.selectMoney(200);
                  }}
                >
                  ￥200
                </li>
                <li
                  className={rechargeMoney === 500 ? "current" : ""}
                  onClick={() => {
                    this.selectMoney(500);
                  }}
                >
                  ￥500
                </li>
                <li
                  className={rechargeMoney === 1000 ? "current" : ""}
                  onClick={() => {
                    this.selectMoney(1000);
                  }}
                >
                  ￥1000
                </li>
              </ul>
            </div>
            <div className="recharge-1-bottom">
              <input
                type="number"
                placeholder="请输入充值金额"
                onInput={(e) => {
                  this.changeMoney(e);
                }}
              />
              <div
                className={`recharge-button ${rechargeMoney ? "" : "disable"}`}
                onClick={() => {
                  this.createOrder();
                }}
              >
                立即充值
              </div>
            </div>
          </div>
        ) : this.rechargeType === 2 ? (
          <div className="wallet-recharge wallet-recharge-2">
            <h3>请输入密码</h3>
            <input
              type="text"
              value={cardPassword}
              onInput={(e) => {
                this.handleInputCardPassword(e);
              }}
            />
            <div className="rule">
              <h4>使用规则</h4>
              <p>
                1.本卡仅可充值一次，不记名、不挂失、遗失不补。使用过后自动作废。
              </p>
              <p>2.购买时请确保密码涂层完好，一经售出，概不退换。</p>
              <p>3.本卡最终解释权归该书店所有。</p>
              <h4>充值方式</h4>
              <p>1.微信扫描二维码关注书店微商城，注册成为会员。</p>
              <p>
                2.轻刮涂层获得密码，点击“个人中心”到“我的钱包”，点击“充值”，输入密码进行充值。
              </p>
            </div>
            <div
              className={`recharge-button ${cardPassword ? "" : "disable"}`}
              onClick={() => {
                this.chargeCard();
              }}
            >
              确定
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
export default withRouter(Wallet);
