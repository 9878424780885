import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Toast } from "antd-mobile";
import $https from "../../../httpdata";
import getParam from "../../../utils/getParam";
import "./index.scss";

class WalletDetails extends Component {
  constructor(props) {
    super(props);
    const { match, location } = props;
    this.invoiceId = match.params.id;
    this.needClose = getParam(location.search, "needClose");
    this.state = {
      backIcon: require("../../common/img/back.png"),
      walletDetails: {},
      billList: [],
      loadingFlag: false,
      page: 1,
      page_size: 10,
      last_page: null,
      type: 0,
    };
  }

  componentDidMount() {
    const that = this;
    this.getWalletDetails();

    window.onscroll = function () {
      var pageNum = that.state.page;
      const { last_page } = that.state;
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollTop + windowHeight >= scrollHeight) {
        if (!last_page) {
          that.setState({
            loadingFlag: true,
          });
          return;
        }
        if (pageNum < last_page) {
          pageNum = pageNum + 1;

          that.setState({
            page: pageNum,
            loadingFlag: true,
          }, () => {
            that.getWalletDetails();
          });
        } else {
          that.setState({
            loadingFlag: false,
          });
        }

        //考虑到滚动的位置一般可能会大于一点可滚动的高度，所以这里不能用等于
      }
    };
  }

  getWalletDetails = () => {
    $https
      .get("/api/v1/wallet/bill", {
        id: this.invoiceId,
        type: this.state.type
      })
      .then((res) => {
        if (res && res.data && res.data.bills && res.data.account) {
          const bills = res.data.bills || {};
          const billData = bills.data || [];
          const arr = this.state.billList ? this.state.billList : [];
          const newArr = [...arr, ...billData];
          this.setState({
            last_page: bills.last_page,
            loadingFlag: false,
            walletDetails: res.data.account,
            billList: newArr
          });
        } else {
          Toast.show((res && res.msg) || "数据异常！");
        }
      });
  }

  changeType = (e, type) => {
    this.setState({
      billList: [],
      loadingFlag: true,
      type
    }, () => {
      this.getWalletDetails();
    })
  }

  goBack = () => {
    if (this.needClose) {
      window.JXNative.postMessage(
        JSON.stringify({
          action: "closeWeb",
        })
      );
    } else {
      this.props.history.go(-1);
    }
  }

  render() {
    const { backIcon, walletDetails, billList, loadingFlag, type } = this.state;

    return (
      <div className="mine-collect mine-wallet-details">
        <header>
          <div className="head">
            <img src={backIcon} alt="" onClick={() => this.goBack()} />
            <span>钱包明细</span>
            <em></em>
          </div>
        </header>
        <div>
          <div className="wallet-details-info">
            <p className="wallet-balance">￥<strong>{walletDetails.balance || "0.00"}</strong></p>
            <p className="wallet-name">零钱余额</p>
          </div>
          <ul className="wallet-details-nav">
            <li onClick={(e) => { this.changeType(e, 0) }} className={type === 0 ? "current" : ""}>全部</li>
            <li onClick={(e) => { this.changeType(e, 1) }} className={type === 1 ? "current" : ""}>收入</li>
            <li onClick={(e) => { this.changeType(e, 2) }} className={type === 2 ? "current" : ""}>支出</li>
          </ul>
          <ul className="wallet-details-list">
            {billList.map((item, index) => {
              return (
                <li key={index} className={item.income ? "plus" : ""}>
                  <p>{item.remark}<span>{item.occur_time}</span></p>
                  <p className="balance">{item.income ? "+" : "-"}￥{item.amount}</p>
                </li>
              )
            })}
            {!loadingFlag ? (
              <li className="loading">
                {!billList.length ?
                  <div>没有明细记录哦~</div>
                  :
                  <span>没有更多内容啦～</span>
                }
              </li>
            ) : (
              <li className="loading">
                <span>正在加载中～</span>
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
}
export default withRouter(WalletDetails);
