import "./mineAddressList.scss";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import getParam from "../../../utils/getParam";
import $https from "../../../httpdata";
import { Image } from "antd-mobile";

class MineAddressList extends Component {
  constructor(props) {
    super(props);
    var addressDefault_id = Number(
      getParam(this.props.location.search, "addressDefault_id")
    );
    this.state = {
      backIcon: require("../../common/img/back-active.png"),
      rightIcon: require("../../common/img/shop-info-right.png"),
      address: require("./img/address1.png"),
      checkedIcon: require("./img/checked.png"),
      editIcon: require("./img/edit.png"),
      visible: false,
      setVisible: false,
      storeDetailData: null,
      addressList: [],
      toStoreChecked: addressDefault_id ? false : true, //到店自取false
      addressChecked: addressDefault_id, //地址选择列表
      addressDefault_id: addressDefault_id,
      station_id: null,
    };
  }
  componentDidMount() {
    this.getAddressList();
  }
  closeBack = () => {
    var navBar = getParam(this.props.location.search, "navBar");
    if (navBar !== null) {
      window.JXNative.postMessage(
        JSON.stringify({
          action: "closeWeb",
        })
      );
    } else {
      // this.props.history.goBack();
      // var goods_id = getParam(this.props.location.search, "goods_id");
      // var station_id = getParam(this.props.location.search, "station_id");
      var addressDefault_id = this.state.addressChecked;
      window.localStorage.setItem("addressDefault_id", addressDefault_id);
      this.props.history.goBack();
      // this.props.history.replace({
      //   pathname: "/productDetail",
      //   search: `station_id=${station_id}&goods_id=${goods_id}&addressDefault_id=${addressDefault_id}`,
      // });
    }
    // var goods_id = getParam(this.props.location.search, "goods_id");
    // var station_id = getParam(this.props.location.search, "station_id");
    // var addressDefault_id = this.state.addressChecked;
    // this.props.history.push({
    //   pathname: "/productDetail",
    //   search: `station_id=${station_id}&goods_id=${goods_id}&addressDefault_id=${addressDefault_id}`,
    // });
  };

  getAddressList = () => {
    $https
      .get("/api/v1/user/address/search", {
        page_size: null,
      })
      .then((res) => {
        this.setState({ addressList: res.data.data });
      });
  };

  toAddNewAddress = (id) => {
    const { station_id, goods_id } = this.state;
    if (id) {
      this.props.history.push({
        pathname: "/newShippingAddress",
        search: `station_id=${station_id}&goods_id=${goods_id}&id=${id}`,
      });
    } else {
      this.props.history.push({
        pathname: "/newShippingAddress",
        search: `station_id=${station_id}&goods_id=${goods_id}`,
      });
    }
  };
  render() {
    const { backIcon, address, addressList, editIcon } = this.state;

    return (
      <div className="mineAddressList">
        <header>
          <Image src={backIcon} onClick={() => this.closeBack()} />
          <p>设置收货地址</p>
          <span></span>
        </header>
        <div className="address-list">
          {addressList && addressList.length
            ? addressList.map((item, idx) => {
                return (
                  <div className="address-item" key={idx}>
                    <Image src={address} className="address" />
                    <div className="cont">
                      <p>
                        {item.is_default ? <span>默认</span> : null}
                        {item.city_name}
                        {item.country_name}
                        {item.detail}
                      </p>
                      <div>
                        <span>{item.receiver_name}</span>
                        <span>{item.receiver_phone}</span>
                      </div>
                    </div>
                    <Image
                      src={editIcon}
                      className="edit"
                      onClick={() => this.toAddNewAddress(item.id)}
                    />
                  </div>
                );
              })
            : null}
        </div>

        <div className="footer">
          <button onClick={() => this.toAddNewAddress()}>+ 添加新地址</button>
        </div>
      </div>
    );
  }
}
export default withRouter(MineAddressList);
