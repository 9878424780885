import { Toast } from "antd-mobile";
import axios from "axios";
// const downloadUrl = url => {
//         let iframe = document.createElement('iframe')
//         iframe.style.display = 'none'
//         iframe.src = url
//         iframe.onload = function() {
//             document.body.removeChild(iframe)
//         }
//         document.body.appendChild(iframe)
//     }
// 超时时间
// axios.defaults.timeout = 10000
// 允许axios携带cookie
axios.defaults.baseURL = process.env.REACT_APP_API_HOST;
axios.defaults.withCredentials = false;
// http请求拦截器
window.onCommonData = async function (res) {
  window.localStorage.setItem("access_token", res.token);
  window.localStorage.setItem("userId", res.userId);
  window.localStorage.setItem("statusBar", res.statusBar);
  window.localStorage.setItem("stationId", res.stationId);
};

window.JXNative &&
  window.JXNative.postMessage &&
  window.JXNative.postMessage(
    JSON.stringify({
      action: "commonData",
    })
  );
axios.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么
    // 随便写个值 绕过if判段
    config.data = JSON.stringify(config.data);
    config.headers = {
      // "Content-Type": "application/json",
      access_token: window.localStorage.getItem("access_token")
        ? window.localStorage.getItem("access_token")
        : "",
      staff_uid: window.localStorage.getItem("userId")
        ? window.localStorage.getItem("userId")
        : "",
      // stationId: "432",
      // origin: "local.putaoread.com",
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// http响应拦截器
axios.interceptors.response.use(
  (response) => {
    // if (response.headers && (response.headers['content-type'] === 'application/x-msdownload' || response.headers['content-type'] === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
    //     downloadUrl(response.request.responseURL)
    // }

    return response;
  },
  (error) => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = "请求错误";
          break;
        case 401:
          error.message = "未授权，请登录";
          if (error.request.responseURL.indexOf("staff") !== -1) {
            Toast.show({
              content: "请登录",
              afterClose: () => {
                window.JXNative.postMessage(
                  JSON.stringify({
                    action: "pushPage",
                    page: "/loginHelper",
                  })
                );
              },
            });
          }
          break;
        case 403:
          error.message = "拒绝访问";
          break;
        case 404:
          error.message = `请求地址出错: ${error.response.config.url}`;
          break;
        case 408:
          error.message = "请求超时";
          break;
        case 500:
          error.message = "服务器内部错误";
          break;
        case 501:
          error.message = "服务未实现";
          break;
        case 502:
          error.message = "网关错误";
          break;
        case 503:
          error.message = "服务不可用";
          break;
        case 504:
          error.message = "网关超时";
          break;
        case 505:
          error.message = "HTTP版本不受支持";
          break;
      }
    }
    return Promise.reject(error && error.response);
  }
);

export default {
  get: (url, params, headers) => {
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          params,
          headers: Object.assign({}, headers, {
            "Content-Type": "application/json",
          }),
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err, url);
        });
    });
  },
  post: (url, data, headers) => {
    return new Promise((resolve, reject) => {
      // if (url !== "/api/v1/login") {
      //   axios
      //     .post(url, data, {
      //       headers: Object.assign({}, headers, {
      //         access_token: window.localStorage.getItem("access_token")
      //           ? window.localStorage.getItem("access_token")
      //           : "",
      //       }),
      //     })
      //     .then(
      //       (response) => {
      //         resolve(response.data);
      //       },
      //       (err) => {
      //         reject(err);
      //       }
      //     );
      // } else {
      axios.post(url, data).then(
        (response) => {
          resolve(response.data);
        },
        (err) => {
          reject(err);
        }
      );
      // }
    });
  },
  delete: (url, params) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(url, {
          data: params,
          headers: {
            "Content-Type": "application/json",
            // access_token: window.localStorage.getItem("access_token")
            //   ? window.localStorage.getItem("access_token")
            //   : "",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  patch: (url, data) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(url, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            // access_token: window.localStorage.getItem("access_token")
            //   ? window.localStorage.getItem("access_token")
            //   : "",
          },
        })
        .then(
          (response) => {
            resolve(response.data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  },
  put: (url, data) => {
    return new Promise((resolve, reject) => {
      axios.put(url, data).then(
        (response) => {
          resolve(response.data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
};
