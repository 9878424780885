import "./membershipCard.scss";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import $https from "../../../httpdata";
import getParam from "../../../utils/getParam";
class MembershipCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backIcon: require("../../common/img/back.png"),
      vip: require("./img/vip.png"),
      //   pointsActive: require("./img/points-active.png"),
      //   points: require("./img/points.png"),

      //   activeType: 0,
      //   page: 1,
      //   page_size: 6,
      categories: [],
      mobile: null,
      loadingFlag: false,

      //   last_page: null,
      //   balance: 0,
    };
  }
  componentDidMount() {
    let that = this;
    this.getCardCategories();
    document.addEventListener("visibilitychange", function () {
      /*ie10+  moz  webkit  默认*/
      if (!document["hidden"]) {
        that.getCardCategories();
      }
    });
    // let that = this;
    // window.onscroll = function () {
    //   var pageNum = that.state.page;
    //   const { last_page } = that.state;
    //   var scrollTop =
    //     document.documentElement.scrollTop || document.body.scrollTop;
    //   var windowHeight =
    //     document.documentElement.clientHeight || document.body.clientHeight;
    //   var scrollHeight =
    //     document.documentElement.scrollHeight || document.body.scrollHeight;
    //   if (scrollTop + windowHeight >= scrollHeight) {
    //     if (!last_page) {
    //       that.setState({
    //         loadingFlag: true,
    //       });
    //       return;
    //     }
    //     if (pageNum < last_page) {
    //       pageNum = pageNum + 1;

    //       that.setState(
    //         {
    //           page: pageNum,
    //           loadingFlag: true,
    //         },
    //         () => {
    //           //   that.getCardCategories();
    //         }
    //       );
    //     } else {
    //       that.setState({
    //         loadingFlag: false,
    //       });
    //     }

    //     //考虑到滚动的位置一般可能会大于一点可滚动的高度，所以这里不能用等于
    //   }
    // };
  }
  componentWillUnmount() {}
  getCardCategories = () => {
    $https
      .get("/api/v1/card/categories", {
        station_id: window.localStorage.getItem("stationId"),
        type: getParam(this.props.location.search, "classType")
          ? getParam(this.props.location.search, "classType")
          : 0,
      })
      .then((res) => {
        // console.log(res);
        // var arr = this.state.pointsList ? this.state.pointsList : [];
        // var newArr = [...arr, ...res.data.paginator.data];
        this.setState({
          //   last_page: res.data.paginator.last_page,
          categories: res.data && res.data.categories,
          mobile: res.data && res.data.mobile,
          loadingFlag: false,
          //   balance: res.data.balance,
        });
      });
  };
  toDetail = (class_id) => {
    this.props.history.push({
      pathname: `/membershipCardDetail`,
      search: `class_id=${encodeURIComponent(class_id)}`,
    });
  };
  //   changeType = (type) => {
  //     this.setState(
  //       {
  //         activeType: type,
  //         page: 1,
  //         pointsList: [],
  //         loadingFlag: true,
  //       },
  //       () => {
  //         this.getCardCategories();
  //       }
  //     );
  //   };
  goBack = () => {
    window.JXNative.postMessage(
      JSON.stringify({
        action: "closeWeb",
      })
    );
  };
  render() {
    const { backIcon, vip, categories, mobile } = this.state;
    return (
      <div className="membership-card">
        <header>
          <div className="head">
            <img src={backIcon} alt="" onClick={() => this.goBack()} />
            <span>开通会员&借阅卡</span>
            <em></em>
          </div>

          {/* <div className="points">{balance}</div> */}
        </header>
        <div className="list">
          <h1>入会信息：{mobile}</h1>
          {/* <ul className="nav">
            {navList.map((item, idx) => {
              return (
                <li
                  key={idx}
                  className={activeType == item.type ? "active" : null}
                  onClick={() => this.changeType(item.type)}
                >
                  {item.title}
                </li>
              );
            })}
          </ul> */}
          <div className="card-box">
            <ul>
              {categories.map((item, idx) => {
                return (
                  <li key={idx} onClick={() => this.toDetail(item.class_id)}>
                    <div className="left">
                      <img src={vip} alt="" />
                      <p>{item.card_name}</p>
                      <span>{item.valid_months}个月有效期</span>
                    </div>
                    <div className="right">
                      <p>{item.class_name}</p>
                      <div className="right-b">
                        <div className="price">
                          <span>
                            价格<em>¥{item.price}</em>
                          </span>
                          {item.deposit ? (
                            <span>
                              押金<em>¥{item.deposit}</em>
                            </span>
                          ) : null}
                        </div>
                        <div className="btn">
                          <span>立即办理</span>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
              {/* {!loadingFlag ? (
                <li className="loading">
                  <span>没有更多内容啦～</span>
                </li>
              ) : (
                <li className="loading">
                  <span>正在加载中～</span>
                </li>
              )} */}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(MembershipCard);
