import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class Countdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      str: "",
    };
    this.fn();
  }
  fn = () => {
    let { e_time } = this.props;
    let s_time = new Date().getTime() / 1000;
    let that = this;
    setInterval(() => {
      var ts = e_time - s_time; //计算剩余的毫秒数
      var dd = parseInt(ts / 60 / 60 / 24, 10); //计算剩余的天数
      var hh = parseInt((ts / 60 / 60) % 24, 10); //计算剩余的小时数
      var mm = parseInt((ts / 60) % 60, 10); //计算剩余的分钟数
      var ss = parseInt(ts % 60, 10); //计算剩余的秒数
      dd = that.checkTime(dd);
      hh = that.checkTime(hh);
      mm = that.checkTime(mm);
      ss = that.checkTime(ss);
      if (ts > 0) {
        if (dd > 0) {
          that.setState({
            str: dd + "天" + hh + ":" + mm + ":" + ss,
          });
        } else {
          that.setState({
            str: hh + ":" + mm + ":" + ss,
          });
        }
        s_time++;
      } else if (ts < 0) {
        that.setState({
          str: "00:00:00",
        });
      }
    }, 1000);
  };
  checkTime = (i) => {
    if (i <= 10) {
      i = "0" + i;
    }
    return i;
  };
  render() {
    const { str } = this.state;
    return <p>{str}</p>;
  }
}
export default withRouter(Countdown);
