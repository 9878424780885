import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Toast } from "antd-mobile";
import $https from "../../../httpdata";
import "./index.scss";

class Deposit extends Component {
  constructor(props) {
    super(props);
    this.locking = false;
    this.state = {
      backIcon: require("../../common/img/back-active.png"),
      page: 1,
      page_size: 10,
      last_page: null,
      summary: {},
      borrowList: null,
      depositesList: [],
      depositSuccess: null,
      selectIndex: -1,
      loadingFlag: false,
      step: +window.location.hash.replace("#", "") || 1,
      showConfirm: false,
      showQRcode: false,
      noReturn: false,
    };
  }

  componentDidMount() {
    const that = this;
    const { step } = this.state;
    this.initData(step);

    const root = document.querySelector("#root");
    if (root) {
      root.classList.add("depisut-root");
    }

    window.addEventListener(
      "hashchange",
      (e) => {
        const _step = +window.location.hash.replace("#", "") || 1;
        that.setState(
          {
            step: _step,
          },
          () => {
            this.initData(_step);
          }
        );
      },
      false
    );
  }

  handleScroll = () => {
    const that = this;
    var pageNum = that.state.page;
    const { last_page } = that.state;
    var scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    var windowHeight =
      document.documentElement.clientHeight || document.body.clientHeight;
    var scrollHeight =
      document.documentElement.scrollHeight || document.body.scrollHeight;
    if (scrollTop + windowHeight >= scrollHeight) {
      if (!last_page) {
        that.setState({
          loadingFlag: true,
        });
        return;
      }
      if (pageNum < last_page) {
        pageNum = pageNum + 1;

        that.setState(
          {
            page: pageNum,
            loadingFlag: true,
          },
          () => {
            that.searchDeposites();
          }
        );
      } else {
        that.setState({
          loadingFlag: false,
        });
      }

      //考虑到滚动的位置一般可能会大于一点可滚动的高度，所以这里不能用等于
    }
  };

  getSummary = () => {
    $https.get("/api/v1/card/deposit/summary").then((res) => {
      if (res && res.data) {
        this.setState({
          summary: res.data,
        });
      }
    });
  };

  initData = (step) => {
    if (step === 1) {
      this.getSummary();
    } else if (step === 2) {
      this.getBorrowList();
    } else if (step === 4) {
      window.addEventListener("scroll", this.handleScroll, false);
      this.searchDeposites();
    }
  };

  getBorrowList = () => {
    $https.get("/api/v1/card/borrow?has_deposit=true").then((res) => {
      if (res && res.data) {
        this.setState({
          borrowList: res.data,
        });
      }
    });
  };

  searchDeposites = () => {
    const { page_size, page } = this.state;
    $https
      .get("/api/v1/card/deposits", {
        page_size: page_size,
        page,
      })
      .then((res) => {
        if (res && res.data && res.data.data) {
          var arr = this.state.depositesList ? this.state.depositesList : [];
          var newArr = [...arr, ...res.data.data];
          this.setState({
            last_page: res.data.last_page,
            depositesList: newArr,
            loadingFlag: false,
          });
        } else {
          Toast.show((res && res.msg) || "数据异常！");
        }
      });
  };

  resetDeposites = () => {
    this.setState({
      depositesList: [],
      page: 1,
      page_size: 10,
    });
  };

  changeStep = (step) => {
    this.setState({
      step,
    });
    window.location.hash = `#${step}`;
  };

  handleBorrowSelect = (e, selectIndex) => {
    this.setState({
      selectIndex,
    });
  };

  checkPopup = () => {
    const { borrowList, selectIndex } = this.state;
    const refund_origin = borrowList[selectIndex].refund_origin;
    console.log(borrowList[selectIndex]);
    if (borrowList[selectIndex].deposit_over_year) {
      this.setState({
        noReturn: true,
      });
    } else {
      this.setState({
        showConfirm: refund_origin,
        showQRcode: !refund_origin,
      });
    }
  };

  applyDeposite = () => {
    const { borrowList, selectIndex } = this.state;
    if (this.locking) return;
    this.locking = true;
    $https
      .post("/api/v1/refund/card/deposit", {
        card_no: borrowList[selectIndex].id,
      })
      .then((res) => {
        if (res && res.code === 0 && res.data) {
          this.setState(
            {
              depositSuccess: res.data,
            },
            () => {
              this.setState(
                {
                  selectIndex: -1,
                },
                () => {
                  this.changeStep(3);
                  this.locking = false;
                }
              );
            }
          );
        } else {
          Toast.show((res && res.msg) || "申请失败！");
          this.locking = false;
        }
        this.setState({
          showConfirm: false,
        });
      });
  };

  goBack = () => {
    const { step } = this.state;
    if (step === 1) {
      window.JXNative.postMessage(
        JSON.stringify({
          action: "closeWeb",
        })
      );
    } else if (step === 4) {
      window.removeEventListener("scroll", this.handleScroll, false);
      this.resetDeposites();
      this.props.history.go(-1);
    } else {
      this.props.history.go(-1);
    }
  };

  render() {
    const {
      backIcon,
      summary,
      borrowList,
      depositesList,
      selectIndex,
      loadingFlag,
      step,
      showConfirm,
      showQRcode,
      depositSuccess,
      noReturn,
    } = this.state;
    const step1 = (
      <div className="deposit-step1">
        <div className="step1-top">
          <p className="top-title">押金金额</p>
          <p className="top-money">
            <strong>{summary.deposit_balance || 0}</strong>元
          </p>
          {summary.refunding_deposit ? (
            <p className="top-get">提现中 {summary.refunding_deposit}元</p>
          ) : null}
          <p
            className="top-look"
            onClick={() => {
              this.changeStep(4);
            }}
          >
            查看明细<span></span>
          </p>
        </div>
        <div className="step1-bottom">
          <h3>退押金说明</h3>
          <p>申请退押金后，将不再享有借阅权益：</p>
          <p className="red">1.您将无法再借阅书籍。</p>
          <p>
            2.您已购买的借阅卡借阅权益将冻结，有效期内再次交纳押金后恢复。期间购物权益不受影响。
          </p>
          <h3>退款方式</h3>
          <p>默认按原支付路径原路退回；</p>
          <p>
            支付时间较早或因其他原因无法原路退回的顾客，请到店申请退押金。
          </p>
          <h3>到账时间</h3>
          <p>
            退款一般1~3个工作日到账，具体还依赖于支付渠道的处理时间，如遇特殊情况，以支付渠道通知为准。
          </p>
        </div>
      </div>
    );
    const step2 = (
      <div className="deposit-step2">
        <ul className="borrow-list">
          {borrowList ? (
            borrowList.length === 0 ? (
              <li className="none">没有押金可退哦~</li>
            ) : (
              <>
                {borrowList.map((item, idx) => {
                  return (
                    <li
                      className="borrow-item"
                      key={idx}
                      onClick={
                        !item.refund_able
                          ? null
                          : (e) => this.handleBorrowSelect(e, idx)
                      }
                    >
                      <div
                        className={`borrow-item-select ${
                          !item.refund_able
                            ? "select-disable"
                            : selectIndex === idx
                            ? "select-ed"
                            : ""
                        }`}
                      ></div>
                      <div className="borrow-item-left">
                        <p>
                          ￥<strong>{item.has_deposit}</strong>
                        </p>
                        借阅押金
                      </div>
                      <div className="borrow-item-right">
                        <div className="borrow-item-name">{item.name}</div>
                        <div className="borrow-item-time">
                          有效期至 {item.expire_time}
                        </div>
                        <div className="borrow-item-num">
                          已借 {item.used_num}/{item.user_hold_limit_num} 本{" "}
                          {!item.refund_able && item.used_num > 0 ? (
                            <em>归还图书后可退押金</em>
                          ) : null}
                        </div>
                      </div>
                    </li>
                  );
                })}
              </>
            )
          ) : (
            <li className="none">加载中...</li>
          )}
        </ul>
      </div>
    );
    const step3 = (
      <div className="deposit-step1">
        <div className="step1-top">
          <div className="top-icon"></div>
          <p className="top-money">申请退款成功</p>
          {summary.refunding_deposit ? (
            <p className="top-get">提现中 {summary.refunding_deposit}元</p>
          ) : null}
          <p className="top-look">
            退款一般1-3个工作日到账
            <br />
            具体依赖于支付渠道的处理时间
          </p>
        </div>
        {depositSuccess ? (
          <div className="step1-bottom">
            <p>
              押金金额：<strong>￥{depositSuccess.amount}</strong>
            </p>
            <p>
              退款方式：<strong>{depositSuccess.refund_method_text}</strong>
            </p>
            <p>
              申请时间：<strong>{depositSuccess.apply_time}</strong>
            </p>
          </div>
        ) : null}
      </div>
    );
    const step4 = (
      <div className="deposit-step1">
        <ul className="deposit-list">
          {depositesList.map((item, idx) => {
            return (
              <li key={idx}>
                {item.type === 1 ? (
                  <>
                    <div className="list-left">
                      {item.method_text}交纳押金{item.deposit}元
                      <span>{item.complete_at}</span>
                    </div>
                    <div className="list-right">
                      +￥{item.deposit}
                      <span>{item.status_text}</span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="list-left">
                      退押金{item.deposit}元至{item.method_text}
                      <span>{item.operate_at}</span>
                    </div>
                    <div className="list-right">
                      -￥{item.deposit}
                      <span
                        className={
                          item.type == 2 &&
                          (item.status === 1 || item.status === 2)
                            ? "red"
                            : ""
                        }
                      >
                        {item.status_text}
                      </span>
                    </div>
                  </>
                )}
              </li> //渠道名称+交纳押金99元  +渠道名称
            );
          })}
          {!loadingFlag ? (
            <li className="loading">
              {!depositesList.length ? (
                <div>没有押金记录哦~</div>
              ) : (
                <span>没有更多内容啦～</span>
              )}
            </li>
          ) : (
            <li className="loading">
              <span>正在加载中～</span>
            </li>
          )}
        </ul>
      </div>
    );

    return (
      <div className="mine-collect mine-deposit">
        <header>
          <div className="head">
            <img src={backIcon} alt="" onClick={() => this.goBack()} />
            <span>{step === 2 ? "退押金" : "借阅押金"}</span>
            <em></em>
          </div>
        </header>
        <div className="list">
          {step === 1 ? (
            <div>
              {step1}
              {summary.deposit_balance ? (
                <div className="step-button" onClick={() => this.changeStep(2)}>
                  申请退押金
                </div>
              ) : null}
            </div>
          ) : step === 2 ? (
            <div>
              {step2}
              {selectIndex > -1 ? (
                <div
                  className={`step-button`}
                  onClick={() => this.checkPopup()}
                >
                  确认退押金{" "}
                  {selectIndex > -1 ? (
                    <span>￥{borrowList[selectIndex].has_deposit}</span>
                  ) : null}
                </div>
              ) : null}
            </div>
          ) : step === 3 ? (
            <div>
              {step3}
              <div className="step-button white" onClick={() => this.goBack()}>
                返回
              </div>
            </div>
          ) : step === 4 ? (
            <div>
              {step4}
              <div className="step-button white" onClick={() => this.goBack()}>
                返回
              </div>
            </div>
          ) : null}
        </div>
        {showConfirm ? (
          <div className="confirm-modal-container">
            <div className="confirm-modal">
              <div className="confirm-modal-title">温馨提示</div>
              <div className="confirm-modal-content">
                退押金后您将无法再借阅书籍。已购买的借阅权限将冻结，有效期内再次缴纳押金后恢复。期间购物权益不受影响。
              </div>
              <div className="confirm-modal-button">
                <div
                  className="confirm-modal-button1"
                  onClick={() => {
                    this.applyDeposite();
                  }}
                >
                  继续退押金
                </div>
                <div
                  className="confirm-modal-button2"
                  onClick={() => {
                    this.setState({ showConfirm: false });
                  }}
                >
                  不退了
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {noReturn ? (
          <div className="confirm-modal-container">
            <div className="confirm-modal">
              <div className="confirm-modal-title">温馨提示</div>
              <div className="confirm-modal-content">
             由于您的押金支付时间较早，请到店申请退押金。
              </div>
              <div className="confirm-modal-button">
                {/* <div
                className="confirm-modal-button1"
                onClick={() => {
                  this.applyDeposite();
                }}
              >
                继续退押金
              </div> */}
                <div
                  className="confirm-modal-button2"
                  onClick={() => {
                    this.setState({ noReturn: false });
                  }}
                >
                  知道了
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {showQRcode ? (
          <div className="confirm-modal-container">
            <div className="confirm-modal">
              <div className="confirm-modal-content">
                <div className="confirm-modal-content-qrcode">
                  <p className="qrcode-title">请关注微信服务号，申请提现</p>
                  <p className="qrcode-img">
                    <img src="https://jx-storage.oss-cn-beijing.aliyuncs.com/cc9e8b31ddccd3c70fc404fbbc4cac37.jpg" />
                  </p>
                  <p className="qrcode-title">
                    <strong>句象图书</strong>
                  </p>
                  <p>
                    位置：底部菜单“<em>句象会员-申请退押金</em>”
                  </p>
                  <p>请使用您的注册手机号、验证码登录后即可申请提现。</p>
                </div>
              </div>
              <div className="confirm-modal-button">
                <div
                  className="confirm-modal-button1"
                  onClick={() => {
                    this.setState({ showQRcode: false });
                  }}
                >
                  知道了
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
export default withRouter(Deposit);
