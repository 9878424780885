import "./membershipCardDetail.scss";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import getParam from "../../../utils/getParam";
import $https from "../../../httpdata";
import { Image, Space, Mask, Radio, Toast } from "antd-mobile";
class MembershipCardDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backIcon: require("../../common/img/back-active.png"),
      vip: require("./img/vip.png"),
      checkedIcon: require("./img/checked.png"),
      uncheckedIcon: require("./img/unchecked.png"),
      checked: false,
      checked2: false,
      close: require("../../common/img/close.png"),
      typeChecked: require("./img/typeChecked.png"),
      hint: require("./img/hint.png"),
      categories: [],
      mobile: null,
      loadingFlag: false,
      class_id: null,
      visible: false,
      need_deposit: null,
      trade_sn: null,
      money1: "radio1",
      money2: "radio2",
      showFlag: true,
      station_id: null,
      flag: false,
    };
  }
  componentDidMount() {
    let that = this;
    window.onCommonData = function (res) {
      that.setState(
        {
          station_id: res.stationId,
          class_id: getParam(that.props.location.search, "class_id"),
        },
        () => {
          that.getCardCategories();
        }
      );
    };
    document.addEventListener("visibilitychange", function () {
      /*ie10+  moz  webkit  默认*/
      if (!document["hidden"]) {
        that.getCardCategories();
      }
    });
    window.JXNative &&
      window.JXNative.postMessage &&
      window.JXNative.postMessage(
        JSON.stringify({
          action: "commonData",
        })
      );
    window.onPaySuccess = function () {
      setTimeout(() => {
        window.JXNative &&
          window.JXNative.postMessage &&
          window.JXNative.postMessage(
            JSON.stringify({
              action: "closeWeb",
              success: true,
            })
          );
      }, 2000);
    };
  }
  componentWillUnmount() {}
  getCardCategories = () => {
    const { class_id, station_id } = this.state;
    $https
      .get("/api/v1/card/buy/preview", {
        class_id,
        station_id,
      })
      .then((res) => {
        this.setState({
          detailData: res.data.category,
          need_deposit: res.data.need_deposit,
          trade_sn: res.data.trade_sn,
        });
      });
  };
  showDialog = () => {
    const { trade_sn, need_deposit } = this.state;

    if (trade_sn) {
      this.setShowFlag(true);
    }
    if (!need_deposit) {
      this.toCommit(false);
    } else {
      this.setVisible(true);
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };
  setVisible = (val) => {
    this.setState({
      visible: val,
    });
  };
  setShowFlag = (val) => {
    this.setState({
      showFlag: val,
    });
  };

  getCardCommit = (val) => {
    this.setState({
      money1: val,
    });
  };

  toCommit = (val) => {
    const { flag } = this.state;
    if (!flag) {
      this.setState({
        flag: true,
      });

      const { class_id, detailData, trade_sn, station_id, money1 } = this.state;
      var parmas;
      if (trade_sn) {
        parmas = {
          station_id,
          class_id,
          product_id: detailData.product_id,
          pay_deposit: false,
          trade_sn,
        };
      } else {
        parmas = {
          station_id,
          class_id,
          product_id: detailData.product_id,
          pay_deposit: val ? (money1 == "radio1" ? true : false) : false,
        };
      }

      $https.post("/api/v1/card/buy/commit", parmas).then((res) => {
        window.JXNative &&
          window.JXNative.postMessage &&
          window.JXNative.postMessage(
            JSON.stringify({
              action: "pushPage",
              page: "/orderToPay",
              data: res.data,
              stationId: station_id,
            })
          );

        setTimeout(() => {
          this.setState({
            flag: false,
          });
        }, 5000);
      });
    } else {
      Toast.show("请稍后～");
    }
  };

  render() {
    const {
      backIcon,
      vip,
      detailData,
      uncheckedIcon,
      visible,
      close,
      typeChecked,
      need_deposit,
      trade_sn,
      money1,
      money2,
      hint,
      showFlag,
      flag,
    } = this.state;
    return (
      <div className="membershipCard-detail">
        <header>
          <div className="head">
            <img src={backIcon} alt="" onClick={() => this.goBack()} />
            <span>开通会员&借阅卡</span>
            <em></em>
          </div>

          {/* <div className="points">{balance}</div> */}
        </header>
        <div className="card-box">
          <ul>
            <li>
              <div className="left">
                <img src={vip} alt="" />
                <p>{detailData?.card_class}</p>
                <span>{detailData?.valid_months}个月有效期</span>
              </div>
              <div className="right">
                <p>{detailData?.class_name}</p>
                <div className="right-b">
                  <div className="price">
                    <span>
                      价格<em>¥{detailData?.price}</em>
                    </span>
                    {detailData?.deposit ? (
                      <span>
                        押金<em>¥{detailData?.deposit}</em>
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="card-detail">
          <div className="layout">
            <h1>{detailData?.class_name}</h1>
            <div className="title">
              <em>权益介绍</em>
              <span className="line"></span>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: detailData?.introduce,
              }}
            ></div>
          </div>
        </div>
        <footer>
          <div className="price">
            <div>
              <span>
                ¥
                <em>
                  {money1 == "radio1"
                    ? Number(detailData?.price) + Number(detailData?.deposit)
                    : detailData?.price}
                </em>
              </span>
              <p>
                押金<span>¥{detailData?.deposit}</span>可分开支付
              </p>
            </div>

            <div className="btn" onClick={() => this.showDialog()}>
              立即办理
            </div>
          </div>
        </footer>
        <Mask visible={visible} onMaskClick={() => this.setVisible(false)}>
          {trade_sn && showFlag ? (
            <div className="hint">
              <div className="layout">
                <h1>
                  <Image src={hint} />
                  温馨提示
                </h1>
                <p>
                  您有<span>¥{detailData?.deposit}</span>
                  未退的押金，可转移至新卡中继续使用，是否转移押金？
                </p>
                <div className="btn">
                  <span
                    className="cancel"
                    onClick={() => this.setShowFlag(false)}
                  >
                    取消
                  </span>
                  <span
                    className="transfer"
                    onClick={() => (!flag ? this.toCommit(need_deposit) : null)}
                  >
                    转移押金
                  </span>
                </div>
              </div>
            </div>
          ) : null}
          {!(trade_sn && showFlag) ? (
            <div className="card">
              <h1>
                <span>选择付款类型</span>
                <Image src={close} onClick={() => this.setVisible(false)} />
              </h1>
              <div className="list">
                {need_deposit ? (
                  <Radio.Group
                    defaultValue="radio1"
                    value={money1}
                    onChange={(val) => this.getCardCommit(val)}
                  >
                    <Space direction="vertical">
                      <Radio
                        //   value={item.id}
                        //   key={idx}
                        value="radio1"
                        icon={(checked2) =>
                          checked2 ? (
                            <Image src={typeChecked} />
                          ) : (
                            <Image src={uncheckedIcon} />
                          )
                        }
                      >
                        <div className="cont">
                          <div className="left">
                            <span>
                              ¥
                              <em>
                                {Number(detailData.price) +
                                  Number(detailData.deposit)}
                              </em>
                            </span>
                          </div>
                          <div className="right">
                            <h2>支付会员费+借阅押金</h2>
                            <p>Tips：图书归还后可随时退还押金</p>
                          </div>
                        </div>
                      </Radio>
                      <Radio
                        value="radio2"
                        icon={(checked2) =>
                          checked2 ? (
                            <Image src={typeChecked} />
                          ) : (
                            <Image src={uncheckedIcon} />
                          )
                        }
                      >
                        <div className="cont">
                          <div className="left">
                            <span>
                              ¥<em>{Number(detailData?.price)}</em>
                            </span>
                          </div>
                          <div className="right">
                            <h2>仅支付会员费</h2>
                            <p>
                              Tips：
                              {detailData?.card_class === "借阅卡"
                                ? "押金可在借书时单独支付"
                                : "会员卡为一人一卡，限本人使用"}
                            </p>
                          </div>
                        </div>
                      </Radio>
                    </Space>
                  </Radio.Group>
                ) : (
                  <Radio.Group
                    defaultValue="radio2"
                    value={money2}
                    onChange={(val) => this.getCardCommit(val)}
                  >
                    <Space direction="vertical">
                      <Radio
                        value="radio2"
                        icon={(checked2) =>
                          checked2 ? (
                            <Image src={typeChecked} />
                          ) : (
                            <Image src={uncheckedIcon} />
                          )
                        }
                      >
                        <div className="cont">
                          <div className="left">
                            <span>
                              ¥<em>{Number(detailData?.price)}</em>
                            </span>
                          </div>
                          <div className="right">
                            <h2>仅支付会员费</h2>
                            <p>
                              Tips：
                              {detailData?.card_class === "借阅卡"
                                ? "押金可在借书时单独·支付"
                                : "会员卡为一人一卡，限本人使用"}
                            </p>
                          </div>
                        </div>
                      </Radio>
                    </Space>
                  </Radio.Group>
                )}

                <div className="btn">
                  <span
                    onClick={() => (!flag ? this.toCommit(need_deposit) : null)}
                  >
                    确认支付 ¥
                    {money1 == "radio1" && need_deposit
                      ? Number(detailData?.price) + Number(detailData?.deposit)
                      : detailData?.price}
                  </span>
                </div>
              </div>
            </div>
          ) : null}
        </Mask>
      </div>
    );
  }
}
// ÷parseInt(oText.value) === parseFloat(oText.value)
export default withRouter(MembershipCardDetail);
