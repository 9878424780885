import "./newShippingAddress.scss";
import React, { Component, createRef } from "react";
import { withRouter } from "react-router-dom";
import $https from "../../httpdata";
import getParam from "../../utils/getParam";
import Dialog from "../../components/dialog/dialog";
import {
  Image,
  Space,
  Swiper,
  Toast,
  Input,
  Switch,
  Popup,
  TextArea,
  Tabs,
} from "antd-mobile";
class NewShippingAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backIcon: require("../common/img/back-active.png"),
      rightIcon: require("../common/img/shop-info-right.png"),
      visible: false,
      close: require("./img/close.png"),
      remove: require("./img/remove.png"),
      activeIndex: 0,
      receiver_name: "",
      receiver_phone: "",
      is_default: false,
      tabItems: [
        // { key: "provice", title: "河北" },
        // { key: "city", title: "廊坊" },
        // { key: "county", title: "请选择" },
      ],
      hot_area: [],
      provinceList: [],
      citiesList: [],
      countriesList: [],
      btnActive: false,
      id: null, //查看详情
      title: "",
      detail: "",
      checkVisible: false,
    };
    this.swiperRef = createRef();
  }
  componentDidMount() {
    if (getParam(this.props.location.search, "id")) {
      this.setState(
        {
          title: "修改收货地址",
          id: Number(getParam(this.props.location.search, "id")),
        },
        () => {
          this.getDetail();
        }
      );
    } else {
      this.setState({
        title: "新建收货地址",
      });
    }

    this.getAreas();
  }
  getDetail = () => {
    const { id } = this.state;
    $https
      .get("/api/v1/user/address/detail", {
        id,
      })
      .then((res) => {
        this.setState(
          {
            receiver_name: res.data.receiver_name,
            receiver_phone: res.data.receiver_phone,
            tabItems: [
              {
                id: res.data.province_code,
                title: res.data.province_name,
              },
              {
                id: res.data.city_code,
                title: res.data.city_name,
              },
              {
                id: res.data.country_code,
                title: res.data.country_name,
              },
            ],
            detail: res.data.detail,
            is_default: res.data.is_default,
          },
          () => {
            if (
              res.data.receiver_name &&
              res.data.receiver_phone &&
              this.state.tabItems &&
              this.state.tabItems[0] &&
              this.state.tabItems[0].id &&
              res.data.detail
            ) {
              this.setState({
                btnActive: true,
              });
            } else {
              this.setState({
                btnActive: false,
              });
            }
          }
        );
      });
  };
  setCheckVisible = (val) => {
    this.setState({
      checkVisible: val,
    });
  };
  toRemove = () => {
    const { id } = this.state;
    $https.post("/api/v1/user/address/delete", { id }).then((res) => {
      if (res.msg == "success") {
        Toast.show("删除成功");
      } else {
        Toast.show("删除失败");
      }
      setTimeout(() => {
        this.closeBack();
      }, 2000);
    });
  };
  closeRegions = () => {
    this.setState({
      visible: false,
    });
  };
  openRegions = () => {
    this.setState({
      visible: true,
    });
  };
  regionsChange = (key) => {
    const { tabItems } = this.state;
    const index = tabItems.findIndex((item) => item.key === key);
    this.setState({
      activeIndex: index,
    });
    this.swiperRef.current?.swipeTo(index);
  };
  changeActiveIndex = (index) => {
    this.setState({
      activeIndex: index,
    });
  };
  closeBack = () => {
    this.props.history.goBack();
  };
  getAreas = (type = 2, parent_id = null) => {
    $https
      .get("/api/v1/areas", {
        type: type,
        parent_id: parent_id,
      })
      .then((res) => {
        if (type === 2) {
          this.setState({
            hot_area: res.data.hot_area,
            provinceList: res.data.list,
          });
        } else if (type == 3) {
          this.setState({
            citiesList: res.data.list,
          });
        } else if (type == 4) {
          this.setState({
            countriesList: res.data.list,
          });
        }
      });
  };
  save = () => {
    const { receiver_name, receiver_phone, tabItems, detail, is_default, id } =
      this.state;
    if (receiver_name && receiver_phone && tabItems[0].id && detail) {
      if (/^1[3-9]\d{9}$/.test(receiver_phone)) {
        var param = {},
          url = "";
        if (id) {
          param = {
            id,
            receiver_code: "86",
            receiver_name,
            receiver_phone,
            province_code: tabItems[0].id,
            province_name: tabItems[0].title,
            city_code: tabItems[1].id,
            city_name: tabItems[1].title,
            country_code: tabItems[2].id,
            country_name: tabItems[2].title,
            detail,
            is_default,
          };
          url = "/api/v1/user/address/update";
        } else {
          param = {
            receiver_code: "86",
            receiver_name,
            receiver_phone,
            province_code: tabItems[0].id,
            province_name: tabItems[0].title,
            city_code: tabItems[1].id,
            city_name: tabItems[1].title,
            country_code: tabItems[2].id,
            country_name: tabItems[2].title,
            detail,
            is_default,
          };
          url = "/api/v1/user/address/create";
        }

        $https.post(url, param).then((res) => {
          if (res.msg == "success") {
            if (id) {
              Toast.show("修改成功");
            } else {
              Toast.show("新建成功");
            }
            setTimeout(() => {
              this.closeBack();
            }, 2000);
          } else {
            if (id) {
              Toast.show("修改失败");
            } else {
              Toast.show("新建失败");
            }
          }
        });
      } else {
        Toast.show("手机号格式输入不正确");
      }
    } else {
      Toast.show("请完善信息");
    }
  };
  getHotCity = (val) => {
    var arr = [
      {
        key: "provice",
        title: val.parent.name,
        id: val.parent.id,
        first_letter: val.parent.first_letter,
      },
      {
        key: "city",
        title: val.name,
        id: val.id,
        first_letter: val.first_letter,
      },
      {
        key: "county",
        title: "请选择",
        id: null,
      },
    ];
    this.setState({
      tabItems: arr,
      activeIndex: 1,
    });
    this.swiperRef.current?.swipeTo(2);
    this.getAreas(3, val.parent.id);
    this.getAreas(4, val.id);
  };
  getProvice = (val) => {
    var arr = [
      {
        key: "provice",
        title: val.name,
        id: val.id,
        first_letter: val.first_letter,
      },
      {
        key: "city",
        title: "请选择",
        id: null,
        first_letter: "",
      },
    ];
    this.setState({
      tabItems: arr,
    });
    this.swiperRef.current?.swipeTo(1);
    this.getAreas(3, val.id);
  };
  getCity = (val) => {
    const arr = [...this.state.tabItems];
    arr[1] = {
      key: "city",
      title: val.name,
      id: val.id,
      first_letter: val.first_letter,
    };
    arr[2] = {
      key: "county",
      title: "请选择",
      id: null,
      first_letter: "",
    };
    this.setState({
      tabItems: arr,
    });
    this.swiperRef.current?.swipeTo(2);
    this.getAreas(4, val.id);
  };
  getCounty = (val) => {
    const arr = [...this.state.tabItems];
    arr[2] = {
      key: "county",
      title: val.name,
      id: val.id,
      first_letter: val.first_letter,
    };
    this.setState({
      tabItems: arr,
      visible: false,
    });
  };
  getVal = (val, key) => {
    console.log(val, key);
    let that = this;
    this.setState(
      {
        [key]: val,
      },
      () => {
        const { receiver_name, receiver_phone, tabItems, detail } = this.state;
        console.log(receiver_name);
        if (
          receiver_name &&
          receiver_phone &&
          tabItems &&
          tabItems[0] &&
          tabItems[0].id &&
          detail
        ) {
          that.setState({
            btnActive: true,
          });
        } else {
          that.setState({
            btnActive: false,
          });
        }
      }
    );
  };
  getSwitch = (val, key) => {
    console.log(val, key);
    this.setState({
      [key]: val,
    });
  };
  render() {
    const {
      backIcon,
      visible,
      rightIcon,
      close,
      activeIndex,
      tabItems,
      hot_area,
      provinceList,
      citiesList,
      countriesList,
      btnActive,
      title,
      remove,
      id,
      receiver_name,
      receiver_phone,
      detail,
      is_default,
      checkVisible,
    } = this.state;
    let provinceActiveId = "";
    let citiesActiveId = "";
    let countiesActiveId = "";
    let first_letterActive1 = "";
    let first_letterActive2 = "";
    let first_letterActive3 = "";
    if (tabItems.length) {
      provinceActiveId = tabItems[0].id;
      first_letterActive1 = tabItems[0].first_letter;
    }
    if (tabItems.length && tabItems[1]) {
      citiesActiveId = tabItems[1].id;
      first_letterActive2 = tabItems[1].first_letter;
    }
    if (tabItems.length && tabItems[2]) {
      countiesActiveId = tabItems[2].id;
      first_letterActive3 = tabItems[2].first_letter;
    }

    return (
      <div className="newShippingAddress">
        <header>
          <Image src={backIcon} onClick={() => this.closeBack()} />
          <p>{title}</p>
          {id ? (
            <Image
              src={remove}
              className="remove"
              onClick={() => this.setCheckVisible(true)}
            />
          ) : (
            <span></span>
          )}
        </header>
        <div className="form">
          <div className="form-top">
            <div>
              <span>收货人</span>
              <Input
                placeholder="请填写收货人姓名"
                style={{ "--placeholder-color": "#9b9ba0" }}
                onChange={(val) => this.getVal(val, "receiver_name")}
                value={receiver_name}
              />
            </div>
            <div>
              <span>手机号码</span>
              <div className="phone">
                <span>+86</span>
                <Input
                  placeholder="请填写收货人号码"
                  type="tel"
                  maxLength="11"
                  onChange={(val) => this.getVal(val, "receiver_phone")}
                  value={receiver_phone}
                />
              </div>
            </div>
            <div>
              <span>所在地区</span>
              <div
                className="place"
                onClick={() => {
                  this.openRegions();
                  //   setVisible(true)
                }}
              >
                {/* <Input placeholder="省市区县、乡镇等" /> */}
                <span>
                  {tabItems.length ? (
                    tabItems.map((item) => {
                      if (item.id) {
                        return <em key={item.id}>{item.title}</em>;
                      }
                    })
                  ) : (
                    <em className="placeholder">省市区县、乡镇等</em>
                  )}
                </span>
                <Image src={rightIcon} />
              </div>
              <Space>
                <Popup
                  visible={visible}
                  onMaskClick={() => this.closeRegions()}
                  bodyStyle={{
                    height: "90vh",
                    borderTopLeftRadius: "30px",
                    borderTopRightRadius: "30px",
                  }}
                >
                  <div className="regions-box">
                    <div className="head">
                      <span></span>
                      <p>配送至</p>
                      <Image src={close} onClick={() => this.closeRegions()} />
                    </div>
                    <div className="cont">
                      <Tabs className="country">
                        <Tabs.Tab title="中国大陆" key="china">
                          <div className="defaultBox">
                            {tabItems && tabItems.length ? (
                              <Tabs
                                activeKey={tabItems[activeIndex].key}
                                onChange={(key) => this.regionsChange(key)}
                                className="selectedCity"
                                // onChange={(key) => {
                                //   const index = tabItems.findIndex(
                                //     (item) => item.key === key
                                //   );
                                //   setActiveIndex(index);
                                //   swiperRef.current?.swipeTo(index);
                                // }}
                              >
                                {tabItems.map((item) => (
                                  <Tabs.Tab title={item.title} key={item.key} />
                                ))}
                              </Tabs>
                            ) : (
                              <div className="hot-box">
                                <h1 className="hot">热门城市</h1>
                                <ul className="hot-city">
                                  {hot_area && hot_area.length
                                    ? hot_area.map((val) => {
                                        return (
                                          <li
                                            key={val.id}
                                            onClick={() => this.getHotCity(val)}
                                          >
                                            {val.name}
                                          </li>
                                        );
                                      })
                                    : null}
                                </ul>
                              </div>
                            )}

                            <Swiper
                              direction="horizontal"
                              indicator={() => null}
                              ref={this.swiperRef}
                              allowTouchMove={false}
                              defaultIndex={activeIndex}
                              onIndexChange={(index) =>
                                this.changeActiveIndex(index)
                              }
                              className="address"
                            >
                              <Swiper.Item key={"swiper1"}>
                                <div className="province">
                                  {provinceList && provinceList.length
                                    ? provinceList.map((item, idx) => {
                                        return (
                                          <ul key={idx} id={item.first_letter}>
                                            <h1>{item.first_letter}</h1>
                                            {item.areas.map((itm) => {
                                              return (
                                                <li
                                                  key={itm.id}
                                                  className={
                                                    itm.id == provinceActiveId
                                                      ? "provinceActive"
                                                      : null
                                                  }
                                                  onClick={() =>
                                                    this.getProvice(itm)
                                                  }
                                                >
                                                  {itm.name}
                                                </li>
                                              );
                                            })}
                                          </ul>
                                        );
                                      })
                                    : null}
                                  <div className="letter">
                                    <div>
                                      {provinceList && provinceList.length
                                        ? provinceList.map((item, idx) => {
                                            return (
                                              <a
                                                key={idx}
                                                href={`#${item.first_letter}`}
                                                className={
                                                  first_letterActive1 ==
                                                  item.first_letter
                                                    ? "provinceActive"
                                                    : ""
                                                }
                                              >
                                                {item.first_letter}
                                              </a>
                                            );
                                          })
                                        : null}
                                    </div>
                                  </div>
                                </div>
                              </Swiper.Item>
                              <Swiper.Item key={"swiper2"}>
                                <div className="citys">
                                  {citiesList && citiesList.length
                                    ? citiesList.map((item, idx) => {
                                        return (
                                          <ul
                                            key={idx}
                                            id={`1${item.first_letter}`}
                                          >
                                            <h1>{item.first_letter}</h1>
                                            {item.areas.map((itm) => {
                                              return (
                                                <li
                                                  key={itm.id}
                                                  className={
                                                    itm.id == citiesActiveId
                                                      ? "provinceActive"
                                                      : null
                                                  }
                                                  onClick={() =>
                                                    this.getCity(itm)
                                                  }
                                                >
                                                  {itm.name}
                                                </li>
                                              );
                                            })}
                                          </ul>
                                        );
                                      })
                                    : null}
                                  <div className="letter">
                                    <div>
                                      {citiesList && citiesList.length
                                        ? citiesList.map((item, idx) => {
                                            return (
                                              <a
                                                key={idx}
                                                href={`#1${item.first_letter}`}
                                                className={
                                                  first_letterActive2 ==
                                                  item.first_letter
                                                    ? "provinceActive"
                                                    : ""
                                                }
                                              >
                                                {item.first_letter}
                                              </a>
                                            );
                                          })
                                        : null}
                                    </div>
                                  </div>
                                </div>
                              </Swiper.Item>
                              <Swiper.Item key={"swiper3"}>
                                <div className="counties">
                                  {countriesList && countriesList.length
                                    ? countriesList.map((item, idx) => {
                                        return (
                                          <ul
                                            key={idx}
                                            id={`2${item.first_letter}`}
                                          >
                                            <h1>{item.first_letter}</h1>
                                            {item.areas.map((itm) => {
                                              return (
                                                <li
                                                  key={itm.id}
                                                  className={
                                                    itm.id == countiesActiveId
                                                      ? "provinceActive"
                                                      : null
                                                  }
                                                  onClick={() =>
                                                    this.getCounty(itm)
                                                  }
                                                >
                                                  {itm.name}
                                                </li>
                                              );
                                            })}
                                          </ul>
                                        );
                                      })
                                    : null}
                                  <div className="letter">
                                    <div>
                                      {countriesList && countriesList.length
                                        ? countriesList.map((item, idx) => {
                                            return (
                                              <a
                                                key={idx}
                                                href={`#2${item.first_letter}`}
                                                className={
                                                  first_letterActive3 ==
                                                  item.first_letter
                                                    ? "provinceActive"
                                                    : ""
                                                }
                                              >
                                                {item.first_letter}
                                              </a>
                                            );
                                          })
                                        : null}
                                    </div>
                                  </div>
                                </div>
                              </Swiper.Item>
                            </Swiper>
                          </div>
                        </Tabs.Tab>
                        {/* <Tabs.Tab title="港澳台及海外" key="foreign">
                          港澳台及海外
                        </Tabs.Tab> */}
                      </Tabs>
                    </div>
                  </div>
                </Popup>
              </Space>
            </div>
            <div className="detail-adress">
              <span>详细地址</span>
              <TextArea
                placeholder="街道、楼牌号等"
                style={{ "--placeholder-color": "#9b9ba0" }}
                rows={5}
                onChange={(val) => this.getVal(val, "detail")}
                maxLength="50"
                value={detail}
              />
            </div>
          </div>
          <div className="form-bot">
            <div className="titile">
              <h1>设置默认地址</h1>
              <p>下单时优先使用该地址</p>
            </div>

            <Switch
              style={{
                "--checked-color": "#FA5564",
              }}
              checked={is_default}
              onChange={(val) => this.getSwitch(val, "is_default")}
            />
          </div>
        </div>

        <div className="footer">
          <button
            onClick={() => this.save()}
            className={Number(btnActive) ? "active" : ""}
          >
            保存并使用
          </button>
        </div>
        <Dialog
          title="确认删除"
          cont="是否确认删除该地址"
          visible={checkVisible}
          setCheckVisible={this.setCheckVisible}
          sure={this.toRemove}
        ></Dialog>
      </div>
    );
  }
}
export default withRouter(NewShippingAddress);
