import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./ProductItem.scss";
import { Image } from "antd-mobile";
class ProductItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    // this.fn();
  }
  //   fn = () => {
  //     let { e_time, s_time } = this.props;
  //     let that = this;
  //     setInterval(() => {
  //       var ts = e_time - s_time; //计算剩余的毫秒数
  //       var dd = parseInt(ts / 60 / 60 / 24, 10); //计算剩余的天数
  //       var hh = parseInt((ts / 60 / 60) % 24, 10); //计算剩余的小时数
  //       var mm = parseInt((ts / 60) % 60, 10); //计算剩余的分钟数
  //       var ss = parseInt(ts % 60, 10); //计算剩余的秒数
  //       dd = that.checkTime(dd);
  //       hh = that.checkTime(hh);
  //       mm = that.checkTime(mm);
  //       ss = that.checkTime(ss);
  //       if (ts > 0) {
  //         if (dd > 0) {
  //           that.setState({
  //             str: dd + "天" + hh + ":" + mm + ":" + ss,
  //           });
  //         } else {
  //           that.setState({
  //             str: hh + ":" + mm + ":" + ss,
  //           });
  //         }
  //         s_time++;
  //       } else if (ts < 0) {
  //         that.setState({
  //           str: "00:00:00",
  //         });
  //       }
  //     }, 1000);
  //   };
  //   checkTime = (i) => {
  //     if (i <= 10) {
  //       i = "0" + i;
  //     }
  //     return i;
  //   };
  toProductDetail = (item) => {
    this.props.history.push({
      pathname: `/productDetail`,
      search: `goods_id=${item.id}`,
    });
  };
  render() {
    const { item, collect } = this.props;
    return (
      <li className="li-item" onClick={() => this.toProductDetail(item)}>
        <div className="img">
          <Image src={item.title_page_url} lazy fit="contain" />
          <div className="mask">
            {collect ? <span>{item.favorite_count}收藏</span> : null}
          </div>
        </div>
        <div className="cont">
          <div className="name">{item.name}</div>
          <div className="price">
            <span className="now">
              <em>¥</em>
              {item.discount_price}
            </span>
            <span className="original">定价:¥{item.output_price}</span>
          </div>
          <div className="des">
            <p>ISBN：{item.isbn}</p>
            <p>作者：{item.writer}</p>
            <p>出版社：{item.pub_name}</p>
          </div>
          <div className="comment">
            <span>
              {item.comment_count > 10000
                ? `${item.comment_count / 10000}万+`
                : item.comment_count}
              评价
            </span>
            <span>
              {item.comment_count > 0
                ? (
                    (item.good_comment_count / item.comment_count) *
                    100
                  ).toFixed(2)
                : 0}
              %好评率
            </span>
          </div>
        </div>
      </li>
    );
  }
}
export default withRouter(ProductItem);
